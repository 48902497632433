import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import ListContent from "./ListContent"
import AddApproved from "./AddApproved"
import LogContent from "./LogContent"
import classnames from "classnames"
import axios from "axios"

import ViewModal from "./ViewModel"

function ApproveMainModal({ approvedModal, toggleApproved, type }) {
  const [approvedModel, setApprovedModel] = useState([])
  const [APID, setAPID] = useState()
  const [change,setChange]=useState(false)

  const handleSubmit = () => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: 2,
      Type: type,
      ApprovedModels: approvedModel,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/AutoApprove.php", formData)
      .then(res => {
        console.log(res)
        toggleApproved()
      })
      .catch(err => console.log(err))
  }
  const [activeTab, setActiveTab] = useState("1")

  const [data, setData] = useState()
  const [addApprovedModal, setAddApprovedModal] = useState(false)
  const toggleAddApproved = () => setAddApprovedModal(!addApprovedModal)
  const [viewModal, setViewModal] = useState(false)
  const toggleViewApproved = () => setViewModal(!viewModal)
  return (
    <React.Fragment>
      <Modal
        isOpen={approvedModal}
        toggle={toggleApproved}
        className="modal-lg"
      >
        <ModalHeader toggle={toggleApproved}>Manged Approved</ModalHeader>
        <ModalBody>
          <div className="d-flex ms-auto justify-content-end mb-1">
            <Button onClick={() => (toggleAddApproved(), setData(""))}>
              + add
            </Button>
          </div>

          <Nav
            fill
            tabs
            className="rounded nav-tabs-custom col-4"
            role="tablist"
          >
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => setActiveTab((1).toString())}
              >
                List
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => setActiveTab((2).toString())}
              >
                Log
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId={(1).toString()}>
              <ListContent
                setApprovedModel={setApprovedModel}
                // toggleAddApproved={toggleAddApproved}
                setAPID={setAPID}
                setChange={setChange}
                type={type}
                toggleViewApproved={toggleViewApproved}
                change={change}
              />
            </TabPane>
            <TabPane tabId={(2).toString()}>
              <LogContent
                type={type}
                setApprovedModel={setApprovedModel}
                toggleViewApproved={toggleViewApproved}
                // toggleAddApproved={toggleAddApproved}
                setAPID={setAPID}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleApproved}>
            Cancel
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit}>
            save
          </Button>
        </ModalFooter>
      </Modal>
      {addApprovedModal && (
        <AddApproved
          toggleAddApproved={toggleAddApproved}
          addApprovedModal={addApprovedModal}
          APID={APID}
          type={type}
          change={change}
          setChange={setChange}
        />
      )}
      {viewModal && (
        <ViewModal
        toggleViewApproved={toggleViewApproved}
        viewModal={viewModal}
          APID={APID}
          type={type}
          change={change}
          setChange={setChange}
        />
      )}
    </React.Fragment>
  )
}

export default ApproveMainModal
