import axios from "axios"
import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const ClientResponse = ({
  clientResponseModal,
  toggleClientResponse,
  massege,
  selectedItem,
  setAuth,
  editing,
  setEditing,
}) => {
  const clientResponseFunc = () => {
const formData=new FormData
    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("RD3ID", selectedItem.RD3ID)
    formData.append("ClientResponse", true)

    formData.append("Action", "1")


    axios
      .post("https://rd0.cpvarabia.com/api/RD3/RD3Action.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        res.data.error === false && (toggleClientResponse(),
        setEditing(!editing))
      })
      .catch(err => console.log(err))
  }
  return (
    /**************delete pop up***************/
    <Modal isOpen={clientResponseModal} toggle={toggleClientResponse}>
      <ModalBody>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              onClick={toggleClientResponse}
              type="button"
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-warning bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-alert-outline color-warning font-size-20"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              {massege}
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                onClick={() => {
                  toggleClientResponse()
                }}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  clientResponseFunc()
                }}
              >
                Submit
              </button>
            </div>
          </ModalBody>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ClientResponse
