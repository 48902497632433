import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import { Formik } from "formik"
import { invalid } from "moment"

const RD7Summary = ({
  summaryToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  const history = useHistory()
  const summaryOptions = [
    { label: "Foundation Test Result", name: "foundation",symbol:"F" },
    { label: "Retaining Wall Test Result", name: "retaining",symbol:"R" },
    { label: "Columns Test Result", name: "columns",symbol:"C" },
    { label: "Slabs Test Result", name: "slabs",symbol:"A" },
  ]

  // *********************** Validate handler ******************************** //
  const validateHandler = values => {
    let errors = {}

    summaryOptions.map(item => {
      if (selectedItem[`${item.symbol}AVG`] !== "0") {
        if (values[`${item.name}AVG`] * 1 <= 0)
          errors[`${item.name}AVG`] = "invalid"
      }
      if (values[`${item.name}AVG`] === "")
        errors[`${item.name}AVG`] = "required"

      // Preparing results values
      if (
        values[`${item.name}AVG`] * 1 >=
        0.9 *
          (item.name === "foundation" || item.name === "retaining"
            ? selectedItem.FRN
            : selectedItem.CAN)
      ) {
        values[`${item.name}Result`] = "Pass"
      } else {
        values[`${item.name}Result`] = "Not Pass"
      }
    })

    // Preparing final result value
    if (
      values.foundationResult === "Pass" &&
      values.retainingResult === "Pass" &&
      values.columnsResult === "Pass" &&
      values.slabsResult === "Pass"
    ) {
      values.finalResult = "Approved"
    } else {
      values.finalResult = "Rejected"
    }

    console.log("values", values)
    console.log("errors", errors)
    return errors
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD7ID: selectedItem.RD7ID,
      Result: values.finalResult === "Approved" ? "1" : "0",

      FAVG: values.foundationAVG,
      CAVG: values.columnsAVG,
      RAVG: values.retainingAVG,
      AAVG: values.slabsAVG,

      FStatus: values.foundationResult === "Pass" ? "1" : "0",
      CStatus: values.columnsResult === "Pass" ? "1" : "0",
      RStatus: values.retainingResult === "Pass" ? "1" : "0",
      AStatus: values.slabsResult === "Pass" ? "1" : "0",
    }

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/EditTest.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        // setEditing(!editing)
        if (res.data.error === false) {
          history.push("/rd7-reports")
        }
      })
      .catch(err => console.log(err))
    // onClose()
  }

  return (
    <div>
      <h5>RD7 Summary</h5>
      <Container>
        <Formik
          initialValues={{
            foundationAVG: selectedItem.FAVG,
            retainingAVG: selectedItem.RAVG,
            columnsAVG: selectedItem.CAVG,
            slabsAVG: selectedItem.AAVG,

            foundationResult:
              selectedItem.Fstatus === "1" ? "Pass" : "Not Pass",
            retainingResult: selectedItem.Rstatus === "1" ? "Pass" : "Not Pass",
            columnsResult: selectedItem.Cstatus === "1" ? "Pass" : "Not Pass",
            slabsResult: selectedItem.Astatus === "1" ? "Pass" : "Not Pass",

            finalResult:
              selectedItem.Fstatus === "1" &&
              selectedItem.Rstatus === "1" &&
              selectedItem.Cstatus === "1" &&
              selectedItem.Astatus === "1"
                ? "Approved"
                : "Rejected",
          }}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>AVG</th>
                    <th>Concrete Resistence</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryOptions.map((item, i) => (
                    <tr key={i}>
                      <th>{item.label}</th>
                      <td>
                        <Input
                          type="number"
                          name={`${item.name}AVG`}
                          min={0}
                          value={
                            props.values[`${item.name}AVG`] === "0"
                              ? ""
                              : props.values[`${item.name}AVG`]
                          }
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          disabled={props.values[`${item.name}AVG`] === "0"}
                        />
                        {props.touched[`${item.name}AVG`] &&
                        props.errors[`${item.name}AVG`] ? (
                          <p className="error mx-2 d-flex justify-content-end">
                            {props.errors[`${item.name}AVG`]}
                          </p>
                        ) : null}
                      </td>
                      <td>
                        <Input
                          type="number"
                          readOnly
                          disabled
                          value={
                            props.values[`${item.name}AVG`] === "0"
                              ? ""
                              : item.name === "foundation" ||
                                item.name === "retaining"
                              ? selectedItem.FRN
                              : selectedItem.CAN
                          }
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          readOnly
                          disabled
                          name={`${item.name}Result`}
                          value={
                            props.values[`${item.name}AVG`] !== "0"
                              ? props.values[`${item.name}AVG`] * 1 >=
                                0.9 *
                                  (item.name === "foundation" ||
                                  item.name === "retaining"
                                    ? selectedItem.FRN
                                    : selectedItem.CAN)
                                ? "Pass"
                                : "Not Pass"
                              : ""
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row className="mt-5 ">
                <Col sm={2} className="d-flex align-items-center">
                  <Label>Result:</Label>
                </Col>
                <Col sm={4}>
                  <Input
                    type="text"
                    readOnly
                    disabled
                    name="finalResult"
                    value={
                      (props.values.foundationAVG === "0"
                        ? true
                        : props.values.foundationAVG * 1 >=
                          0.9 * selectedItem.FRN) &&
                      (props.values.retainingAVG === "0"
                        ? true
                        : props.values.retainingAVG * 1 >=
                          0.9 * selectedItem.FRN) &&
                      (props.values.columnsAVG === "0"
                        ? true
                        : props.values.columnsAVG * 1 >=
                          0.9 * selectedItem.CAN) &&
                      (props.values.slabsAVG === "0"
                        ? true
                        : props.values.slabsAVG * 1 >= 0.9 * selectedItem.CAN)
                        ? "Approved"
                        : "Rejected"
                    }
                  />
                </Col>
              </Row>

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                {/* <button
                type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Close
                </button>   */}
                <button
                  type="submit"
                  className="btn btn-danger"
                  disabled={selectedItem.Status === "12"}
                >
                  Submit
                </button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  )
}

export default RD7Summary
