import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import ReactSwitch from "react-switch"

const FilterTickets = ({
  filteredValues,
  setFilteredValues,
  setAuth,
  userPermissions,
}) => {
  // console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [users, setUsers] = useState([])
  const [stageList, setStageList] = useState([])

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }

  const formData = {
    PerToken: localStorage.getItem("token"),
    PerUserID: localStorage.getItem("id"),
    PerRoleID: localStorage.getItem("userRoleID"),
  }

  const [ticketsUsers, setTicktesUsers] = useState([])
  const [degreeOptions, setDegreeOptions] = useState([])
  // console.log("degreeList", degreeList)
  useEffect(() => {
    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/TransferToList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        array = array.map(item => ({
          value: item.ResourceID,
          label: item.Name,
        }))
        setTicktesUsers([
          { value: "200", label: "My Tickets" },
          ...array,
          { value: "201", label: "Inspection" },
        ])
      })
      .catch(err => console.log(err))

    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DegreeList.php", {
        ...formData,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        let options = [
          { label: "Design", options: [] },
          { label: "Inspection", options: [] },
          { label: " ", options: [{ value: "null", label: "Not Assigned" }] },
        ]
        let array = Object.values(res.data)
        array.splice(-1)
        array = array.map(item => {
          options[1].label == item.Category
            ? options[1].options.push({
                value: item.DegreeID,
                label: item.Name,
              })
            : options[0].options.push({
                value: item.DegreeID,
                label: item.Name,
              })
        })

        array.push({ value: "null", label: "Not assigned" })
        setDegreeOptions(options)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    axios
      .post("https://rd0.cpvarabia.com/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://rd0.cpvarabia.com/api/inspection/InsStageList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setStageList(
          array.map(item => ({ value: item.StageID, label: item.StageName }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  // 'Active','Inactive','ManagerApproval','ModeratorApproval','QCApproval'
  let RD5filter = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
    { value: "ManagerApproval", label: "Manager Approval" },
    { value: "ModeratorApproval", label: "Moderator Approval" },
    { value: "QCApproval", label: "QC Approval" },
    { value: "Solved", label: "Solved" },
  ]
  const [deletedTickets, setDeletedTickets] = useState(false)

  // ticket Type
  let ticketTypeOptions = [
    { value: "Inspection", label: "Inspection" },
    { value: "Design", label: "Design" },
  ]

  return (
    <Row>
      <Col md={3}>
        {/* *************************** Tickets User **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isClearable
              options={ticketsUsers}
              name="ticketsUsers"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.ticketsUsers}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, ticketsUsers: e })
              }}
              classNamePrefix="select"
              placeholder="Tickets Users Transfered..."
            />
          </div>
        </div>

        {/* *************************** Ticket Id **************************** */}
        <div className="search-box me-3 col-12">
          <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
            <i className="bx bx-search-alt search-icon" />
            <MultipleValueTextInput
              onItemAdded={(item, allItems) =>
                setFilteredValues({
                  ...filteredValues,
                  ticketID: allItems,
                })
              }
              onItemDeleted={(item, allItems) =>
                setFilteredValues({
                  ...filteredValues,
                  ticketID: allItems,
                })
              }
              type="number"
              name="ticketID"
              placeholder="Ticket ID ..."
              className="border-0 mt-1"
            />
          </div>
        </div>
        {/* *************************** Activity **************************** */}
        <div className="search-box me-3 col-12">
          <div className="position-relative mb-2 d-flex form-control border-dark ">
            <i className="bx bx-search-alt search-icon " />

            <DatePicker
              isClearable
              className="border-0"
              selectsRange={true}
              dateFormat="yyyy/MM/dd"
              startDate={startDate}
              endDate={endDate}
              onChange={update => {
                setDateRange(update)
                setFilteredValues({
                  ...filteredValues,
                  startDate: update[0] ? convertDate(update[0]) : null,
                  endDate: update[1] ? convertDate(update[1]) : null,
                })
              }}
              // withPortal
              placeholderText="Activity ..."
            />
          </div>
        </div>
      </Col>

      <Col md={3}>
        {/* *************************** Created By **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={users}
              name="creator"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.creator}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, creator: e })
              }}
              classNamePrefix="select"
              placeholder="Created By ..."
            />
          </div>
        </div>

        {/* *************************** Ticket Code **************************** */}
        <div className="search-box me-3 col-12">
          <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
            <i className="bx bx-search-alt search-icon" />
            <MultipleValueTextInput
              onItemAdded={(item, allItems) =>
                setFilteredValues({
                  ...filteredValues,
                  ticketCode: allItems,
                })
              }
              onItemDeleted={(item, allItems) =>
                setFilteredValues({
                  ...filteredValues,
                  ticketCode: allItems,
                })
              }
              type="text"
              name="ticketCode"
              placeholder="Ticket Code ..."
              className="border-0 mt-1"
            />
          </div>
        </div>
        {/* *************************** Ticket Type **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              options={ticketTypeOptions}
              name="ticketType"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.ticketType}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, ticketType: e.value })
              }}
              classNamePrefix="select"
              placeholder="Ticket type..."
            />
          </div>
        </div>
      </Col>

      <Col md={3}>
        {/* *************************** Updated By **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={users}
              name="updater"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.updater}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, updater: e })
              }}
              classNamePrefix="select"
              placeholder="Updated By ..."
            />
          </div>
        </div>

        {/* *************************** Reference No **************************** */}
        <div className="search-box me-3 col-12">
          {userPermissions?.R4?.P != 0 && userPermissions?.R2?.P == false ? (
            <div className="d-flex justify-content-start mb-2 mx-1">
              <div className="search-box me-4 col-12 ">
                <div className="position-relative mb-2 d-flex">
                  <Input
                    type={"number"}
                    name="referenceNo"
                    className="form-control border-dark"
                    placeholder={"Reference No ..."}
                    onChange={e => {
                      let updatedObject = {
                        referenceNo: e.target.value,
                      }

                      setFilteredValues({
                        ...filteredValues,
                        ...updatedObject,
                      })
                      console.log("filteredValues", filteredValues)
                    }}
                  />
                  <i className="bx bx-search-alt search-icon " />
                </div>
              </div>
            </div>
          ) : (
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          )}
        </div>

        {/* *************************** ins stage **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={stageList}
              name="stage"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.inspectionStage}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, inspectionStage: e })
              }}
              classNamePrefix="select"
              placeholder="Ins Stage ..."
            />
          </div>
        </div>
      </Col>

      <Col sm={3}>
        {/* *************************** Degree **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={degreeOptions}
              name="degree"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.degree}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, degree: e })
              }}
              classNamePrefix="select"
              placeholder="Degree ..."
            />
          </div>
        </div>
        {/* *************************** RD5 Actions **************************** */}
        <div className="search-box me-3 col-12 p-0">
          <div className="position-relative mb-3 form-control border-dark py-0">
            <i className="bx bx-search-alt search-icon my-auto" />
            <Select
              isMulti
              options={RD5filter}
              name="RD5filter"
              className="react-select-container"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "transparent" : "transparent",
                  borderColor: state.isSelected ? "transparent" : "transparent",
                  borderWidth: "0px",
                }),
              }}
              value={filteredValues.RD5filter}
              onChange={e => {
                // console.log(e)
                setFilteredValues({ ...filteredValues, RD5filter: e })
              }}
              classNamePrefix="select"
              placeholder="RD5 Actions ..."
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-2 mx-4">
          <Label
            htmlFor="material-switch"
            className="mt-1"
            style={{ fontSize: "14px" }}
          >
            Deleted-Tickets
          </Label>
          <ReactSwitch
            onChange={() => (
              setDeletedTickets(!deletedTickets),
              setFilteredValues({
                ...filteredValues,
                deletedTickets: !deletedTickets,
              })
            )}
            checked={deletedTickets}
            onColor="#24b571"
            // onColor="#4458b8"
            // uncheckedIcon={false}
            // checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            height={20}
            width={42}
            className="react-switch ms-2"
            id="material-switch"
          />
        </div>
      </Col>
    </Row>
  )
}

export default FilterTickets
