import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  Label,
  Input,
  Col,
  Form,
  ModalHeader,
  FormGroup,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import CheckboxATextGroup from "./CheckboxATextGroup"
import RadioOrCheckboxGroup from "./RadioOrCheckboxGroup"
import RadioInspection from "./RadioInspection"
import Icons from "./Icons"
import Observation from "./Observation"
import PlusAMinesIcon from "./PlusAMinesIcon"
import FileAnswer from "./FileAnswer"
import AddingNewTicket from "./AddingNewTicket"
import { onSubmitHandler, validationSchema } from "./InspectionQuestionHelper"
import { useHistory } from "react-router-dom"

const InspectionQuestionBuilder = ({
  questiontoggle,
  props,
  editedQuestion,
  answers,
  edit,
  setAuth,
}) => {
  const history = useHistory()

  const [degreeList, setDegreeValue] = useState([])
  const [transferList, setTransferValue] = useState([])
  let RDIssuer = { ResourceID: "200", Name: "RD-Issuer" }
  useEffect(() => {
    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DegreeList.php", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Category: "Inspection",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let resData = res.data
        let data = Object.entries(resData).filter(item => {
          return Number(item[0])
        })
        // console.log(data)
        setDegreeValue(data)
      })
    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/TransferToList.php",

        {
          PerToken: localStorage.getItem("token"),
          PerUserID: localStorage.getItem("id"),
          PerRoleID: localStorage.getItem("userRoleID"),
        }
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let resData = res.data
        resData[200] = RDIssuer
        let data = Object.entries(resData).filter(item => {
          return Number(item[0])
        })
        setTransferValue(data)
      })
  }, [])

  let editingDegreeList = degreeList.filter(item => {
    return (item.DegreeID != 8) & (item.DegreeID != 12)
  })
  let sectionId = props.match.params.SectionID

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    setmodal(!modal)
  }
  const onClose = () => {
    questiontoggle()
  }

  let leftType = [
    { title: "Ins-Checklist", value: "InsChecklist" },
    { title: "Observation", value: "Observation" },
    { title: "Radio", value: "Radio" },
    { title: "Checkbox", value: "CheckBox" },
    { title: "Adding-New-Ticket", value: "Ticket" },
    { title: "Dropdown", value: "Dropdown" },
  ]

  let rightType = [
    { title: "Text-Area", value: "TextArea" },
    { title: "Image", value: "Image" },
    { title: "Text", value: "Text" },
    { title: "File", value: "File" },
    { title: "User-select", value: "UserSelect" },
    // { title: "", value: "" },
  ]
  let allType = [
    { title: "Ins-Checklist", value: "InsChecklist" },
    { title: "Observation", value: "Observation" },
    { title: "Radio", value: "Radio" },
    { title: "Checkbox", value: "CheckBox" },
    { title: "Adding-New-Ticket", value: "Ticket" },
    { title: "Dropdown", value: "Dropdown" },
    { title: "Text-Area", value: "TextArea" },
    { title: "Image", value: "Image" },
    { title: "Text", value: "Text" },
    { title: "File", value: "File" },
    { title: "User-select", value: "UserSelect" },
  ]
  // ******* add & remove Answers ****************/
  const [answerIds, setAnswerIds] = useState([1]) //for creation answers ID

  //*********Input type to controll********/
  let [answerType, setInputtype] = useState("")
  const clickForm = e => {
    e.target.value === "InsChecklist" || e.target.value === "UserSelect"
      ? setAnswerIds([1, 2, 3, 4])
      : setAnswerIds([1])
    setInputtype(e.target.value)
    if (e.target.value === "InsChecklist" || e.target.value === "UserSelect") {
      setAnswerIds([1, 2, 3, 4])
    }
  }
  editedQuestion &&
    useEffect(() => {
      let numberofIds = []
      answers.map((answer, index) => numberofIds.push(index + 1))
      editedQuestion[0].Type && setInputtype(editedQuestion[0].Type),
        editedQuestion[0].Type === "InsChecklist" ||
        editedQuestion[0].Type === "UserSelect"
          ? setAnswerIds([1, 2, 3, 4])
          : setAnswerIds(numberofIds)
    }, [])
  let imageNumber = [0, 1, 2, 3, 4, 5]
  let dropdownValue = useRef()
  // editedQuestion && console.log("...editedQuestion[0]", editedQuestion[0])

  //************** Errors **********//
  const [error, setError] = useState({})
  let degreeAnswerTypeArray = [
    "InsChecklist",
    "Observation",
    "Ticket",
    "UserSelect",
  ]

  let firstAnswer
  let secondAnswer
  let fourCommentArAEnGroup = ["7", "8", "9"]
  let activeAnswer = {}
  if (answerType === "Observation") {
    for (let id = 0; id < 20; id++) {
      activeAnswer["activeAnswer" + id] = true
    }
  }
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-xl">
      <ModalHeader>
        {editedQuestion ? "Edit Question" : "Create Question"}
      </ModalHeader>
      <ModalBody className="d-flex row">
        <Formik
          enableReinitialize={true}
          initialValues={
            editedQuestion
              ? { ...editedQuestion[0] }
              : {
                  ...activeAnswer,
                  Type: "",
                  QuestionTitle: "",
                  Answer1: "",
                  Help: "",
                  Hint: "",
                  activeAnswer1: true,
                  questionActive: true,
                  questionRD6: false,
                }
          }
          validate={async values => {
            let errors = {}
            errors = await validationSchema(
              values,
              errors,
              setError,
              degreeAnswerTypeArray,
              answerType,
              fourCommentArAEnGroup,
              firstAnswer,
              answerIds,
              secondAnswer,
              editedQuestion
            )
            return errors
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(false)
            await onSubmitHandler(
              values,
              error,
              degreeAnswerTypeArray,
              sectionId,
              answerIds,
              onClose,
              editedQuestion,
              setAuth,
              history
            )
          }}
        >
          {props => (
            <Form className="ms-1" onSubmit={props.handleSubmit}>
              {/**************************************question section******************************************************/}

              {/**************** section-Question Title *************/}
              <div className="d-flex col-12">
                <Label for="Question" sm={2}>
                  Question Title :
                </Label>
                <Col className="d-flex" sm={6}>
                  <Input
                    id="Question"
                    name="QuestionTitle"
                    className="me-3 mb-1 form-control"
                    placeholder="Enter your question ?"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.QuestionTitle || ""}
                    type="Text"
                  />
                </Col>
                <RadioOrCheckboxGroup
                  props={props}
                  type={"checkbox"}
                  name={"questionActive"}
                  title={"Active"}
                  id={"questionActive"}
                  style={"col-2"}
                  value={props.values["questionActive"]}
                  defaultChecked={props.values["questionActive"]}
                />
                <RadioOrCheckboxGroup
                  props={props}
                  type={"checkbox"}
                  name={"questionRD6"}
                  title={"RD-6"}
                  style={"col-2"}
                  value={props.values["questionRD6"]}
                  defaultChecked={props.values["questionRD6"]}
                />
              </div>
              {props.touched.QuestionTitle && props.errors.QuestionTitle ? (
                <div
                  className="error mb-2"
                  style={{ marginInlineStart: "130px" }}
                >
                  {props.errors.QuestionTitle}
                </div>
              ) : null}
              {/********************** Question Hint ! **********************/}
              <CheckboxATextGroup type={"Text"} props={props} name={"Hint"} />
              {/********************** Question Help ! **********************/}
              <CheckboxATextGroup type={"Text"} props={props} name={"Help"} />
              {/*---------------------section----Type of Answer--------------------------- */}
              <FormGroup
                className="d-flex mb-0"
                onChange={e => {
                  clickForm(e)
                }}
                onClick={() => {
                  // props.resetForm()
                }}
              >
                <h6 className="col-2">Type :</h6>
                {props.values.Type ? (
                  <div className="d-flex">
                    <FormGroup className="col-12 d-flex justify-content-around flex-wrap">
                      {allType.map(
                        (type, i) =>
                          props.values.Type == type.value && (
                            <RadioOrCheckboxGroup
                              key={i}
                              props={props}
                              type={"radio"}
                              name={"Type"}
                              title={type.title}
                              value={type.value}
                              style={"col-12"}
                              defaultChecked={props.values.Type == type.value}
                            />
                          )
                      )}
                    </FormGroup>
                  </div>
                ) : (
                  <div className="d-flex">
                    <FormGroup className="col-6 d-flex justify-content-around flex-wrap">
                      {leftType.map((type, i) => (
                        <RadioOrCheckboxGroup
                          key={i}
                          props={props}
                          type={"radio"}
                          name={"Type"}
                          title={type.title}
                          value={type.value}
                          style={"col-4"}
                        />
                      ))}

                      {props.touched.Type && props.errors.Type ? (
                        <div className="me-5 error">{props.errors.Type}</div>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="col-5 d-flex justify-content-around flex-wrap">
                      {rightType.map((type, i) => (
                        <RadioOrCheckboxGroup
                          key={i}
                          props={props}
                          type={"radio"}
                          name={"Type"}
                          title={type.title}
                          value={type.value}
                          style={"col-4"}
                        />
                      ))}
                    </FormGroup>
                  </div>
                )}
              </FormGroup>

              {/*************************************** section-Answer ***************************************************/}
              {answerIds.map((id, index) =>
                answerType === "InsChecklist" ? ( //RadioInspection
                  <div key={index}>
                    <RadioInspection
                      props={props}
                      degreeList={degreeList}
                      id={id}
                    />
                  </div>
                ) : answerType === "Radio" ? ( //Radio
                  <div className="d-flex col-12 mb-2">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <div className="d-flex col-6">
                      <Input
                        id="Answer"
                        name={`Answer${id}`}
                        value={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-control me-3"
                        style={{ height: "40px" }}
                        placeholder="Enter your Answer"
                        type="Text"
                      />
                    </div>
                    <Icons
                      props={props}
                      setAnswerIds={setAnswerIds}
                      answerIds={answerIds}
                      inputType={answerType}
                      id={id}
                    />
                  </div>
                ) : answerType === "Observation" ? ( //observation
                  <div className="d-flex col-12">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <Col sm={9}>
                      <Observation
                        props={props}
                        answerId={id}
                        degreeList={editingDegreeList}
                        transferList={transferList}
                        active={true}
                        type={"Ins"}
                      />
                    </Col>
                    <PlusAMinesIcon
                      props={props}
                      setAnswerIds={setAnswerIds}
                      answerIds={answerIds}
                      answerType={answerType}
                      id={id}
                      className="col-2 ms-4"
                    />
                  </div>
                ) : answerType === "CheckBox" ? ( //checkbox
                  <div className="d-flex col-12 mb-2">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <div className="d-flex col-6">
                      <Input
                        id="Answer"
                        name={`Answer${id}`}
                        value={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-control me-3"
                        style={{ height: "40px" }}
                        placeholder="Enter your Answer"
                        type="Text"
                      />
                    </div>
                    <Icons
                      props={props}
                      setAnswerIds={setAnswerIds}
                      answerIds={answerIds}
                      answerType={answerType}
                      inputType={answerType}
                      id={id}
                    />
                  </div>
                ) : answerType === "TextArea" ? ( //textarea
                  <div className="d-flex col-12">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <Col sm={6}>
                      <Input
                        id="Answer"
                        name={`Answer${id}`}
                        value={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder="Enter your Answer"
                        className=" d-flex col-1 form-control"
                        type="textarea"
                      />
                    </Col>
                  </div>
                ) : answerType === "Image" ? ( //image
                  <div className="d-flex col-12">
                    <Label for="Answer" sm={2}>
                      Number of image :
                    </Label>
                    <Col sm={2}>
                      <Input
                        ref={dropdownValue}
                        type="select"
                        className="form-control Text-center"
                        id={"Answer1"}
                        name={"Answer1"}
                        placeholder={`number of images`}
                        defaultValue={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        style={{ minHeight: "30px" }}
                      >
                        {imageNumber.map((number, key) =>
                          key == 0 ? (
                            <option value={number.toString()} key={key}>
                              number
                            </option>
                          ) : (
                            <option value={number.toString()} key={key}>
                              {number}
                            </option>
                          )
                        )}
                      </Input>
                    </Col>
                  </div>
                ) : answerType === "File" ? ( //File
                  <div className="d-flex">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <FileAnswer props={props} id={id} />
                  </div>
                ) : answerType === "Text" ? ( //Text
                  <div className="d-flex col-12">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <Col sm={6}>
                      <Input
                        id="Answer"
                        name={`Answer${id}`}
                        value={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        placeholder="Enter your Answer"
                        className=" d-flex col-1 form-control"
                        type="Text"
                      />
                    </Col>
                  </div>
                ) : answerType === "Dropdown" ? ( //dropdown
                  <div className="d-flex col-12 mb-2">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <div className="d-flex col-6">
                      <Input
                        id="Answer"
                        name={`Answer${id}`}
                        value={props.values["Answer" + id]}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        className="form-control me-3"
                        style={{ height: "40px" }}
                        placeholder="Enter your Answer"
                        type="Text"
                      />
                    </div>
                    <Icons
                      props={props}
                      setAnswerIds={setAnswerIds}
                      answerIds={answerIds}
                      inputType={answerType}
                      id={id}
                    />
                  </div>
                ) : answerType === "Ticket" ? ( //AddingNewTicket
                  <div className="d-flex col-12 mb-2">
                    <Label for="Answer" sm={2}>
                      Answer{index + 1} :
                    </Label>
                    <AddingNewTicket
                      className="d-flex col-10 mb-2"
                      props={props}
                      degreeList={editingDegreeList}
                      transferList={transferList}
                    />
                  </div>
                ) : answerType === "UserSelect" ? ( //user select
                  <div>
                    <RadioInspection
                      props={props}
                      userSelect={true}
                      degreeList={degreeList}
                      id={id}
                    />
                  </div>
                ) : (
                  ""
                )
              )}
              {props.errors.answers ? (
                <div className="error" style={{ marginInlineStart: "255px" }}>
                  {props.errors.answers}
                </div>
              ) : null}
              {props.errors.Answer ? (
                <div className="error" style={{ marginInlineStart: "185px" }}>
                  {props.errors.Answer}
                </div>
              ) : null}

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <Button
                  onClick={() => {
                    onClose()
                  }}
                  className="bg-primary"
                >
                  close
                </Button>
                <Button type="submit" className="bg-primary">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default InspectionQuestionBuilder
