import { convertPermission } from "permissionUtils"
import React, { useState } from "react"
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap"

const ViewQuotation = ({
  viewQuotationToggle,
  sendQuotationToggle,
  selectedItem,
}) => {
  // console.log("selectedItem", selectedItem)
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewQuotationToggle()
  }

  const onClose = () => {
    viewQuotationToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View Quotation
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Table bordered>
            <thead>
              <tr>
                <th>Reference No</th>
                <td>{selectedItem.ReferenceNo}</td>
                <th>Payment Status</th>
                <td>{selectedItem.Paid === "1" ? "Paid" : "Not Paid"}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Owner</th>
                <td>{selectedItem.OwnerName}</td>
                <th>TIS fees status</th>
                <td>
                  {selectedItem.ProjectPaymentStatus === "1"
                    ? "Paid"
                    : "Not Paid"}
                </td>
              </tr>
              <tr>
                <th scope="row">Contractor</th>
                <td>{selectedItem.ConstructorName}</td>
                <th>RD7 Total Cost</th>
                <td>{selectedItem.RD7Total} SAR</td>
              </tr>
            </tbody>
          </Table>

          <FormGroup className="d-flex ms-auto col-5 mt-5 justify-content-around">
            <a
              href={`https://rd0.cpvarabia.com/uploads/reports/RD7_Quotation/quotation.php?RD7T=${selectedItem.Token}`}
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              View Quotation
            </a>
            <Button
              className="bg-primary"
              onClick={() => {
                viewQuotationToggle()
                sendQuotationToggle()
              }}
              disabled={
                userPermissions.R14.P !== "3" && userPermissions.R14.P !== "4"
              }
            >
              Send Quotation
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewQuotation
