import axios from "axios"
import { Formik } from "formik"
import React, { useState } from "react"
import { Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

const ClientDocument = ({
  clientDocumentModal,
  toggleClientDocument,
  massege,
  selectedItem,
  setAuth,
  editing,
  setEditing,
}) => {

  // const [file, setFile] = useState() 
    // *********************** Validate handler ******************************** //
    const validateHandler = values => {
      let errors = {}
      if (!values.providedDocument) {
        errors.providedDocument = "please upload file"
      }
      if (!values.Result) {
        errors.Result = "please select option"
      }
 
      console.log("values", values)
      console.log("errors", errors)
      return errors
    }
  
    // *********************** Submit handler ******************************** //
    const submitHandler = values => {
      const formData = new FormData()

      // for (let i = 0; i < file.length; i++) {
      //   formData.append("File[]", file[i])
      // }
  
      formData.append("PerToken", localStorage.getItem("token"))
      formData.append("PerUserID", localStorage.getItem("id"))
      formData.append("PerRoleID", localStorage.getItem("userRoleID"))
      formData.append("RD3ID", selectedItem.RD3ID)
      formData.append("File", values.providedDocument)
      formData.append("Result", values.Result)
      formData.append("Comment", values.comment)
      formData.append("Action", "2")
      axios
        .post("https://rd0.cpvarabia.com/api/RD3/RD3Action.php", formData)
        .then(res => {
          if (res.data.error === true && res.data.message === "Access denied!") {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }
          res.data.error === false &&(  toggleClientDocument(),
          setEditing(!editing))
       
        })
        .catch(err => console.log(err))
    }
  return (
    /**************delete pop up***************/
    <Modal isOpen={clientDocumentModal} toggle={toggleClientDocument}>
      <ModalHeader>Client Document</ModalHeader>
      <ModalBody>
      
          <ModalBody className="px-4 py-2 text-center">
           
         <Container>
        <Formik
          initialValues={{ Result: "", providedDocument: "" ,comment: ""}}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Row className="mt-2 ">
                <Col sm={5} className="d-flex align-items-center">
                  <Label>Provided Document  :</Label>
                </Col>
                <Col sm={7}>
                  <Input
                  // multiple  
                    type="file"
                    name={`providedDocument`}
                    id="providedDocument"
                    onChange={e =>{
                      // setFile(e.target.files)
                      props.setFieldValue(("providedDocument"), e.target.files[0])}
                    }
                  />

                  {props.errors[`providedDocument`] ? (
                    <p className="error mx-2 d-flex justify-content-end mt-1">
                      {props.errors[`providedDocument`]}
                    </p>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-2 ">
                <Col sm={5} className="d-flex align-items-center justify-content-center ">
                  <Label>Result  :</Label>
                </Col>
                <Col sm={7}>
                  <Input
                    name={`Result`}
                    type="select"
                    onChange={props.handleChange}
                  >
                    <option selected disabled>
                      -- Choose --
                    </option>
                    <option value={"Accept"}>Accept</option>
                    <option value={"Reject"}>Reject</option>
                  </Input>
                  {props.errors[`Result`] ? (
                    <p className="error mx-2 d-flex justify-content-end">
                      {props.errors[`Result`]}
                    </p>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-2 ">
                <Col sm={5} className="d-flex align-items-center justify-content-center ">
                  <Label>Text  :</Label>
                </Col>
                <Col sm={7}>
                  <Input
                    name={`text`}
                    type="textarea"
                    onChange={props.handleChange}
                 
                 /> 
                </Col>
              </Row>

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <button type="submit" className="btn btn-secondary me-2" onClick={()=>{toggleClientDocument()}}>
                  close
                </button>
                <button type="submit" className="btn btn-danger ms-1">
                  Submit
                </button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Container>
          </ModalBody>
       
      </ModalBody>
    </Modal>
  )
}

export default ClientDocument
