import React, { useState, useEffect } from "react"
import ReactDragListView from "react-drag-listview/lib/index.js"
import { useHistory, useLocation } from "react-router-dom"
import {
  Button,
  Table,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  Form,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
//meta title
document.title = "RD-0 | DomApp - React Admin & Dashboard Template"

const Viewstage = props => {
  const stageCategory =
    props.location.state?.category || localStorage.getItem("stageCategory")

  const stageName = props.location.state?.stageName

  // console.log("stageCategory===========", stageCategory)

  let history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ----drag & drop code----//
  // const tabledata = [
  //   {
  //     sectionName: " Title1",
  //   },
  //   {
  //     sectionName: "Title2",
  //   },
  // ]
  let StageID = props.match.params.StageID
  const [data, setData] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: StageID,
    }

    axios
      .post("https://rd0.cpvarabia.com/api/Sections/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        setData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  // console.log("data", data)
  // ----drag & drop code----//
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setData(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  //*****pop up***********//
  // ********view Section*****//
  function viewSection(item) {
    localStorage.setItem("sectionType", item.SectionType)

    let path = `/view-stage/${StageID}/${item.SectionID}`

    if (stageCategory === "Inspection") {
      path = `/view-stage/inspection/${StageID}/${item.SectionID}`
    }
    history.push(path, {
      stageCategory,
      sectionType: item.SectionType,
      sectionName: item.SectionName,
    })
  }
  //*************Add Section****/
  const [section, setSection] = useState({ name: "", type: "" })
  const [modal, setmodal] = useState(false)
  const toggle = () => {
    setmodal(!modal)
  }

  const Addsection = () => {
    toggle()
  }
  const handleAddSection = e => {
    e.preventDefault()
    if (
      (section.name && stageCategory !== "Inspection") ||
      (section.name && stageCategory === "Inspection")
    ) {
      let sectionData = {
        SectionName: section.name,
        SectionType: section.type,
        StageID: StageID,
      }
      console.log("sectionData", sectionData)
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        ...sectionData,
      }
      axios
        .post("https://rd0.cpvarabia.com/api/AddSection", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          toggle()
          window.location.reload(false)
        })
        .catch(err => console.log(err))
    }
  }
  //*******Edit**********/
  const [editModal, setEditModal] = useState(false)
  const [editedSection, setEditedSection] = useState()

  const [newName, setNewName] = useState("")

  const editToggle = () => {
    setEditModal(!editModal)
  }

  const edit = item => {
    setEditedSection(item)
    setNewName(item.SectionName)
    editToggle()
  }

  const editHandler = e => {
    e.preventDefault()
    if (newName) {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        SectionName: newName,
        SectionID: editedSection.SectionID,
      }
      console.log("formData", formData)
      axios
        .post("https://rd0.cpvarabia.com/api/EditSection", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          editToggle()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  //*******Delete**********/
  const [deleteModal, setDeleteModal] = useState(false)
  const [deletedSectionID, setDeletedSectionID] = useState()

  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  }
  const Delete = id => {
    deleteToggle()
    setDeletedSectionID(id)
  }
  const deleteHandler = e => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      SectionID: deletedSectionID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/DeleteSection", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        deleteToggle()
        window.location.reload()
      })
      .catch(err => {
        console.log(err)
      })
  }

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R1.P !== "1" &&
    userPermissions.R1.P !== "2" &&
    userPermissions.R1.P !== "3" &&
    userPermissions.R1.P !== "4" &&
    userPermissions.R1.G !== "1" &&
    userPermissions.R2.P !== "1" &&
    userPermissions.R2.P !== "2" &&
    userPermissions.R2.P !== "3" &&
    userPermissions.R2.P !== "4" &&
    userPermissions.R2.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Stages"
              breadcrumbItem={
                stageName ? `${stageName} Stage` : "Sections List"
              }
            />
            <Row>
              <Col md={12}>
                {((stageCategory === "Design" &&
                  (userPermissions.R1.P === "2" ||
                    userPermissions.R1.P === "3" ||
                    userPermissions.R1.P === "4")) ||
                  (stageCategory === "Inspection" &&
                    (userPermissions.R2.P === "2" ||
                      userPermissions.R2.P === "3" ||
                      userPermissions.R2.P === "4"))) && (
                  <button
                    onClick={() => {
                      Addsection()
                    }}
                    className="btn-primary btn col-2 mb-3"
                  >
                    Add Section
                  </button>
                )}
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <ReactDragListView {...dragProps}>
                        {/* ***** * table ******* */}
                        <Table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>order</th>
                              <th>Section Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data || []).map((item, index) => (
                              <tr className="shadow" key={index}>
                                <th scope="row" className="drag-pointer">
                                  {index + 1}
                                </th>
                                <td>{item[1].SectionName}</td>
                                <td>
                                  <div className="d-flex justify-content-center gap-3">
                                    <div
                                      // to="#"
                                      onClick={() => {
                                        viewSection(item[1])
                                      }}
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i
                                        className="mdi mdi-eye font-size-18"
                                        id="viewtooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="viewtooltip"
                                      >
                                        view
                                      </UncontrolledTooltip>
                                    </div>

                                    {((stageCategory === "Design" &&
                                      (userPermissions.R1.P === "3" ||
                                        userPermissions.R1.P === "4")) ||
                                      (stageCategory === "Inspection" &&
                                        (userPermissions.R2.P === "3" ||
                                          userPermissions.R2.P === "4"))) && (
                                      <Link to="#" className="text-success">
                                        <i
                                          onClick={() => {
                                            edit(item[1])
                                          }}
                                          className="mdi mdi-pencil font-size-18"
                                          id="edittooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="edittooltip"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </Link>
                                    )}

                                    {((stageCategory === "Design" &&
                                      userPermissions.R1.P === "4") ||
                                      (stageCategory === "Inspection" &&
                                        userPermissions.R2.P === "4")) && (
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() => {
                                          Delete(item[1].SectionID)
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="deletetooltip"
                                        >
                                          Delete
                                        </UncontrolledTooltip>
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </ReactDragListView>
                    </div>
                  </CardBody>
                  {/****** pop up *****/}
                  {/******* pop for Add **********/}
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader>Add Section</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          handleAddSection(e)
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label htmlFor="sectionName">
                                Section Name :
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="sectionName"
                                placeholder="Section Name..."
                                onChange={e =>
                                  setSection({
                                    ...section,
                                    name: e.target.value,
                                  })
                                }
                              />
                              {!section.name && (
                                <p className="error">required</p>
                              )}
                            </div>
                          </Col>
                        </Row>

                        <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                          <Button
                            onClick={() => {
                              toggle()
                            }}
                            className="bg-primary"
                          >
                            Close
                          </Button>
                          <Button type="submit" className="bg-primary">
                            Add
                          </Button>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                  </Modal>
                  {/****** pop for Edit*********/}
                  <Modal isOpen={editModal} toggle={editToggle}>
                    <ModalHeader>Edit Section</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          editHandler(e)
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label htmlFor="sectionName">Section Name</Label>
                              <Input
                                type="text"
                                id="sectionName"
                                className="form-control"
                                value={newName}
                                onChange={e => {
                                  setNewName(e.target.value)
                                }}
                              />
                              {!newName && <p className="error">required</p>}
                            </div>
                          </Col>
                        </Row>
                        <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                          <Button
                            onClick={() => {
                              editToggle()
                            }}
                            className="bg-primary"
                          >
                            Close
                          </Button>
                          <Button type="submit" className="bg-primary">
                            Edit
                          </Button>
                        </FormGroup>
                      </Form>
                    </ModalBody>
                  </Modal>
                  {/********* delete pop up ******/}
                  <Modal isOpen={deleteModal} toggle={deleteToggle}>
                    <ModalBody>
                      <div className="modal-content">
                        <ModalBody className="px-4 py-5 text-center">
                          <button
                            type="button"
                            className="btn-close position-absolute end-0 top-0 m-3"
                          ></button>
                          <div className="avatar-sm mb-4 mx-auto">
                            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                              <i className="mdi mdi-trash-can-outline"></i>
                            </div>
                          </div>
                          <p className="text-muted font-size-16 mb-4">
                            Are you sure you want to Delete this section.
                          </p>

                          <div className="hstack gap-2 justify-content-center mb-0">
                            <button
                              type="button"
                              onClick={() => {
                                deleteHandler()
                              }}
                              className="btn btn-danger"
                            >
                              Delete Now
                            </button>
                            <button
                              onClick={() => {
                                deleteToggle()
                              }}
                              type="button"
                              className="btn btn-secondary"
                            >
                              Close
                            </button>
                          </div>
                        </ModalBody>
                      </div>
                    </ModalBody>
                  </Modal>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Viewstage
