import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

function NewTicketModal({
  newTicketToggle,
  newTicketModal,
  ticketData,
  editing,
  type,
  setEditing,
  ProjectID,
}) {
  const formData = {
    PerToken: localStorage.getItem("token"),
    PerUserID: localStorage.getItem("id"),
    PerRoleID: localStorage.getItem("userRoleID"),
  }

  const [transferToOptions, setTransferToOptions] = useState([])
  useEffect(() => {
    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/TransferToList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setTransferToOptions(array)
      })
      .catch(err => console.log(err))
  }, [])

  const [degreeList, setDegreeList] = useState([])
  const [betterDegreeList, setBetterDegreeList] = useState([])
  useEffect(() => {
    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DegreeList.php", {        
        ...formData,
        Category: type === "Inspection" ? "Inspection" : "Design",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setDegreeList(
          array.map(item => ({ value: item.DegreeID, label: item.Name }))
        )

        // setBetterDegreeList(
        //   array
        //     .map(item => ({ value: item.DegreeID, label: item.Name }))
        //     .filter(degree => {
        //       // for leads to RD5 (inspection)
        //       if (ticketControlled.DegreeID === "5") {
        //         return degree.value * 1 > 1 && degree.value * 1 < 5
        //       } else {
        //         // for others degree
        //         return (
        //           degree.value * 1 > ticketControlled.DegreeID * 1 &&
        //           degree.value * 1 < 5
        //         )
        //       }
        //     })
        // )
      })
      .catch(err => console.log(err))
  }, [])

  const RD5Comments = [
    { label: "English Comment", name: "CommentEN" },
    { label: "Arabic Comment", name: "CommentAR" },
    { label: "English Corrective", name: "CorrectiveEN" },
    { label: "Arabic Corrective", name: "CorrectiveAR" },
  ]

  const validateHandler = values => {
    const errors = {}
    // console.log("values", values)
    // setIsdegree(values.degree)

    if (values.degree && values.transferTo)
      errors.logic = "could not update degree and transfer it also!!"

    // Validate RD5, Inspection RD5 && TR Degree With TR Comments
    if (
      values.degree === "1" ||
      values.degree === "2" ||
      values.degree === "5"
    ) {
      if (!values.CommentEN) errors.CommentEN = "required"
      if (!values.CommentAR) errors.CommentAR = "required"
      if (!values.CorrectiveEN) errors.CorrectiveEN = "required"
      if (!values.CorrectiveAR) errors.CorrectiveAR = "required"
    }

    // Validate Note Degree With TR Comments
    if (values.degree === "3") {
      if (!values.CommentEN) errors.CommentEN = "required"
      if (!values.CommentAR) errors.CommentAR = "required"
    }

    if (!values.degree && !values.transferTo) {
      errors.sendData = "Kindly update the ticket before submitting"
    }
    if (values.transferTo) {
      if (!values.CommentEN) errors.CommentEN = "required"
    }

    console.log("errors", errors)
    return errors
  }

  const submitHandler = values => {
    const formData = new FormData()

    formData.append("PerToken", localStorage.getItem("token"))
    formData.append("PerUserID", localStorage.getItem("id"))
    formData.append("PerRoleID", localStorage.getItem("userRoleID"))
    formData.append("UpdaterID", localStorage.getItem("id"))
    formData.append("ProjectID", ProjectID)
    formData.append("Type", type)

    if (values.degree) formData.append("DegreeID", values.degree)
    formData.append("TransferTo", values.transferTo)

    if (values.transferTo) {
      formData.append("Description", values.CommentEN)
    }

    // for RD5, Inspection RD5 && TR Degree
    if (
      values.degree === "1" ||
      values.degree === "2" ||
      values.degree === "5"
    ) {
      formData.append("Description", values.CommentEN)
      formData.append("CommentAR", values.CommentAR)
      formData.append("CorrectiveEN", values.CorrectiveEN)
      formData.append("CorrectiveAR", values.CorrectiveAR)
    }

    // for Note Degree
    if (values.degree === "3") {
      formData.append("Description", values.CommentEN)
      formData.append("CommentAR", values.CommentAR)
    }

    const url =
      type === "Inspection"
        ? "https://rd0.cpvarabia.com/api/inspection/AddTicket.php"
        : "https://rd0.cpvarabia.com/api/AddTicket.php"

    axios
      .post(url, formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        setEditing(!editing)
        newTicketToggle()
      })
      .catch(err => console.log(err))
    // onClose()
    // }
  }

  return (
    <Modal
      isOpen={newTicketModal}
      toggle={newTicketToggle}
      className="modal-lg"
    >
      <ModalHeader>Create New Ticket </ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            degree: "",
            transferTo: "",
            betterDegree: "",
          }}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              {/* <Table>
                <thead>
                  <tr>
                    <th>Reference No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{ticketData.ReferenceNo}</td>
                  </tr>
                </tbody>
              </Table> */}

              <Row className="mt-5">
                <Col ms={4}>
                  <Input
                    type="select"
                    name="degree"
                    id="degree"
                    onChange={props.handleChange}
                    disabled={props.values.transferTo}
                  >
                    <option value="">Select Degree ...</option>

                    {degreeList.map(
                      (item, i) =>
                        item.value !== "4" && (
                          <option key={i} value={item.value}>
                            {item.label}
                          </option>
                        )
                    )}
                  </Input>
                </Col>

                <Col ms={4}>
                  <Input
                    type="select"
                    name="transferTo"
                    id="transferTo"
                    onChange={props.handleChange}
                    disabled={props.values.degree}
                  >
                    <option value="">Transfer To...</option>
                    {transferToOptions.map((item, i) => (
                      <option key={i} value={item.ResourceID}>
                        {item.Name}
                      </option>
                    ))}
                    <option value="200">RD0 Issuer</option>
                    <option value="201">Inspection</option>
                  </Input>
                </Col>
              </Row>
              {props.errors.logic ? (
                <p className="error mx-2 d-flex ">{props.errors.logic}</p>
              ) : null}

              {/* *************************  RD5 and TR Comments ***************** */}
              {(props.values.degree === "1" ||
                props.values.degree === "2" ||
                props.values.degree === "3" ||
                props.values.degree === "13" ||
                props.values.degree === "5") && (
                <div className="my-5">
                  {RD5Comments.map((item, i) => {
                    props.values.degree !== "3"
                    if (
                      (props.values.degree !== "3" &&
                        props.values.degree !== "13") ||
                      ((props.values.degree === "3" ||
                        props.values.degree === "13") &&
                        (item.name === "CommentEN" ||
                          item.name === "CommentAR"))
                    ) {
                      return (
                        <Row key={i} className="mb-3">
                          <Col sm={3}>
                            <Label htmlFor={item.name}>{item.label}</Label>
                          </Col>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name={item.name}
                              id={item.name}
                              value={props.values[`${item.name}`]}
                              onChange={props.handleChange}
                            />
                            {props.errors[`${item.name}`] ? (
                              <span className="error mx-2 d-flex justify-content-end">
                                {props.errors[`${item.name}`]}
                              </span>
                            ) : null}
                          </Col>
                        </Row>
                      )
                    }
                  })}
                </div>
              )}

              {props.values.transferTo && (
                <div>
                  <Row className="mt-3 align-items-center">
                    <Col sm={3}>
                      <Label htmlFor={"CommentEN"}>{"Description"} :</Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        type="textarea"
                        name={"CommentEN"}
                        id={"CommentEN"}
                        value={props.values[`${"CommentEN"}`]}
                        onChange={props.handleChange}
                      />
                      {props.errors[`${"CommentEN"}`] ? (
                        <span className="error mx-2 d-flex justify-content-end">
                          {props.errors[`${"CommentEN"}`]}
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              )}

              <div className="mt-5 pt-3">
                {props.errors.sendData ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.sendData}
                  </p>
                ) : null}
              </div>
              <FormGroup className="d-flex ms-auto col-3  justify-content-around">
                <Button
                  onClick={() => {
                    console.log("newTicketToggle", newTicketToggle)
                    newTicketToggle()
                  }}
                  className="bg-secondary me-2"
                >
                  Cancel
                </Button>
                <Button type="submit" className="bg-primary me-5">
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default NewTicketModal
