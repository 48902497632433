import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { Tab, Tabs } from "react-bootstrap"
import axios from "axios"
import { Formik } from "formik"
import * as yup from "yup"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const SendInspectionEmail = ({
  selectedItem,
  sendEmailToggle,
  editing,
  setEditing,
  setAuth,
}) => {
  //   console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    sendEmailToggle()
  }
  const onClose = () => {
    sendEmailToggle()
  }

  // ********************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // *********************** Email Logs ******************************** //
  const [logs, setLogs] = useState([])
  // console.log("logs", logs)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: selectedItem.PStageID,
    }
    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/InspectionEmailHistory.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res==========", res)
        if (res.data.error === false) {
          let array = Object.values(res.data)
          array.splice(-1)
          setLogs(array)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [selectedItem])

  // *********************** Validation Schema ******************************** //
  const sendingSchema = yup.object().shape({
    conEmailCheck: yup.boolean().when("ownEmailCheck", {
      is: false,
      then: yup.boolean().oneOf([true], "at least one email required"),
    }),
    conEmail: yup
      .string()
      .when("conEmailCheck", {
        is: true,
        then: yup.string().required("required"),
      })
      .email("invalid email"),

    ownEmailCheck: yup.boolean(),
    ownEmail: yup
      .string()
      .when("ownEmailCheck", {
        is: true,
        then: yup.string().required("required"),
      })
      .email("invalid email"),

    // manEmailCheck: yup.boolean(),
    // manEmail: yup
    //   .string()
    //   .when("manEmailCheck", {
    //     is: true,
    //     then: yup.string().required("required"),
    //   })
    //   .email("invalid email"),

    conPhoneCheck: yup.boolean(),
    conPhone: yup.string().when("conPhoneCheck", {
      is: true,
      then: yup.string().required("required"),
    }),

    ownPhoneCheck: yup.boolean(),
    ownPhone: yup.string().when("ownPhoneCheck", {
      is: true,
      then: yup.string().required("required"),
    }),
  })

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    // console.log("submitted values=======>", values)

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: selectedItem.PStageID,
    }
    if (values.conEmailCheck) formData.CE = values.conEmail
    if (values.conPhoneCheck) formData.CM = values.conPhone
    if (values.ownEmailCheck) formData.OE = values.ownEmail
    if (values.ownPhoneCheck) formData.OM = values.ownPhone

    // console.log("formData", formData)

    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/InspectionSendReports.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        setEditing(!editing)
      })
      .catch(error => {
        console.log(error)
      })
    onClose()
  }
  //   console.log("selectedItem", selectedItem)

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Resend Inspection Email
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody>
          <Tabs className="mb-3" justify fill>
            <Tab eventKey="sendEmail" title="Send Email">
              <Formik
                enableReinitialize={true}
                initialValues={{
                  conEmail: selectedItem.ConstructorEmail,
                  conEmailCheck: false,
                  conPhone: selectedItem.ConstructorMobile,
                  conPhoneCheck: false,
                  ownEmail: selectedItem.OwnerEmail,
                  ownEmailCheck: false,
                  ownPhone: selectedItem.OwnerMobile,
                  ownPhoneCheck: false,
                  // manEmail: "",
                  // manEmailCheck: false,
                }}
                validationSchema={sendingSchema}
                onSubmit={submitHandler}
              >
                {props => (
                  <Form onSubmit={props.handleSubmit}>
                    {/* ********************** Contractor Email  **************************** */}
                    <Row className="mt-3">
                      <Col
                        sm={5}
                        className="d-flex align-items-center justify-content-start"
                        // style={{ backgroundColor: "red" }}
                      >
                        <Input
                          type="checkbox"
                          className="my-auto"
                          defaultChecked={props.values.conEmailCheck}
                          name="conEmailCheck"
                          id="conEmailCheck"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <Label
                          htmlFor="conEmailCheck"
                          onClick={() =>
                            console.log("props.errors", props.errors)
                          }
                          className="my-auto mx-2"
                        >
                          Contractor Email
                        </Label>
                      </Col>
                      <Col>
                        <Input
                          type="email"
                          name="conEmail"
                          id="conEmail"
                          placeholder="contractor email"
                          value={props.values.conEmail}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </Col>
                    </Row>
                    {props.touched.conEmail && props.errors.conEmail ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.conEmail}
                      </p>
                    ) : null}
                    {props.touched.conEmailCheck &&
                    props.errors.conEmailCheck ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.conEmailCheck}
                      </p>
                    ) : null}

                    {/* ********************** Contractor Phone **************************** */}
                    <Row className="mt-3">
                      <Col
                        sm={5}
                        className="d-flex align-items-center justify-content-start"
                      >
                        <Input
                          type="checkbox"
                          className="my-auto"
                          defaultChecked={props.values.conPhoneCheck}
                          name="conPhoneCheck"
                          id="conPhoneCheck"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <Label htmlFor="conPhoneCheck" className="my-auto mx-2">
                          Contractor Phone
                        </Label>
                      </Col>
                      <Col>
                        <Input
                          type="tel"
                          name="conPhone"
                          id="conPhone"
                          placeholder="contractor phone"
                          value={props.values.conPhone}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </Col>
                    </Row>
                    {props.touched.conPhone && props.errors.conPhone ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.conPhone}
                      </p>
                    ) : null}

                    {/* ********************** Owner Email **************************** */}
                    <Row className="mt-3">
                      <Col
                        sm={5}
                        className="d-flex align-items-center justify-content-start"
                      >
                        <Input
                          type="checkbox"
                          className="my-auto"
                          defaultChecked={props.values.ownEmailCheck}
                          name="ownEmailCheck"
                          id="ownEmailCheck"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <Label htmlFor="ownEmailCheck" className="my-auto mx-2">
                          Owner Email
                        </Label>
                      </Col>
                      <Col>
                        <Input
                          type="email"
                          name="ownEmail"
                          id="ownEmail"
                          placeholder="owner email"
                          value={props.values.ownEmail}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </Col>
                    </Row>
                    {props.touched.ownEmail && props.errors.ownEmail ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.ownEmail}
                      </p>
                    ) : null}

                    {/* ********************** Owner Phone **************************** */}
                    <Row className="mt-3">
                      <Col
                        sm={5}
                        className="d-flex align-items-center justify-content-start"
                      >
                        <Input
                          type="checkbox"
                          className="my-auto"
                          defaultChecked={props.values.ownPhoneCheck}
                          name="ownPhoneCheck"
                          id="ownPhoneCheck"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <Label htmlFor="ownPhoneCheck" className="my-auto mx-2">
                          Owner Phone
                        </Label>
                      </Col>
                      <Col>
                        <Input
                          type="tel"
                          name="ownPhone"
                          id="ownPhone"
                          placeholder="owner phone"
                          value={props.values.ownPhone}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </Col>
                    </Row>
                    {props.touched.ownPhone && props.errors.ownPhone ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.ownPhone}
                      </p>
                    ) : null}

                    {/* ********************** Manager Email **************************** */}
                    {/* <Row className="mt-3">
                      <Col
                        sm={5}
                        className="d-flex align-items-center justify-content-start"
                      >
                        <Input
                          type="checkbox"
                          className="my-auto"
                          defaultChecked={props.values.manEmailCheck}
                          name="manEmailCheck"
                          id="manEmailCheck"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                        <Label htmlFor="manEmailCheck" className="my-auto mx-2">
                          Manager Email
                        </Label>
                      </Col>
                      <Col>
                        <Input
                          type="email"
                          name="manEmail"
                          id="manEmail"
                          placeholder="manager email"
                          value={props.values.manEmail}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </Col>
                    </Row>
                    {props.touched.manEmail && props.errors.manEmail ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.manEmail}
                      </p>
                    ) : null} */}

                    <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                      <div className="col-4 d-flex ms-auto justify-content-around">
                        <Button className="bg-primary" onClick={onClose}>
                          Close
                        </Button>
                        <Button className="bg-primary" type="submit">
                          Send
                        </Button>
                      </div>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Tab>

            <Tab eventKey="emailLogs" title="Email Logs">
              <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Report</th>
                      <th>Action</th>
                      <th>User</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{item.IEHID}</th>
                        <td>{item.PStageID}</td>
                        <td>{item.Action}</td>
                        <td>{item.UserName}</td>
                        <td>{item.CreatedAt?.split(" ")[0]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <FormGroup className="d-flex ms-auto  mt-5 justify-content-end">
                <Button className="bg-primary" onClick={onClose}>
                  Close
                </Button>
              </FormGroup>
            </Tab>
          </Tabs>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default SendInspectionEmail
