import React, { useState } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { Table } from "antd"
import "antd/dist/antd.css"
import { Link } from "react-router-dom"
import axios from "axios"
import EditQuestion from "./EditQuestion"
import { useHistory } from "react-router-dom"
import ConfirmingAction from "common/ConfirmingAction"

const Icons = ({
  record,
  QuestionData,
  newchildQuestion,
  userPermissions,
  setAuth,
}) => {
  // console.log("newchildQuestion=====>", newchildQuestion)

  const history = useHistory()

  const [dpopup, setdPopup] = useState(false)
  const deletefunc = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      QID: newchildQuestion.QID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/DeleteQuestion", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        window.location.reload(false)
      })

    // console.log("newchildQuestion ---------->", newchildQuestion)
    // console.log("record------>", record)
    // console.log("child deleted", record.QID)
    setdPopup(!dpopup)
  }

  const toggledPopup = () => {
    setdPopup(!dpopup)
  }
  const DeleteFun = e => {
    toggledPopup()
  }

  //-----------Edit Question-------------------//
  const [editToggle, setEditToggle] = useState(false)
  const [editedQuestion, setEditedQuestion] = useState(null)

  const editToggleHandler = () => {
    setEditToggle(!editToggle)
  }

  const editQuestionHandler = () => {
    editToggleHandler()
    setEditedQuestion(
      QuestionData.filter(question => question.QID == newchildQuestion.QID)[0]
    )
  }

  return (
    <div className="d-flex justify-content-center">
      {(userPermissions.R1.P === "3" || userPermissions.R1.P === "4") && (
        <Link to="#" className="text-primary">
          <i
            onClick={editQuestionHandler}
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
          />
          <UncontrolledTooltip placement="top" target="edittooltip">
            Edit
          </UncontrolledTooltip>
        </Link>
      )}

      {userPermissions.R1.P === "4" && (
        <Link
          to="#"
          className="text-danger ms-1"
          onClick={e => {
            DeleteFun()
          }}
        >
          <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          <UncontrolledTooltip placement="right" target="deletetooltip">
            Delete
          </UncontrolledTooltip>
        </Link>
      )}

      {/********icons pop *******/}
      {dpopup && (
        <ConfirmingAction
        confirmFunc={deletefunc}
        action={"delete"}
        Confirmetoggle={toggledPopup}
        ConfirmeModel={dpopup}
        massege={"Are you sure you want to delete this question ?"}
        />
      )}

      {/*********** popup edit  Question *****/}
      {editToggle && (
        <EditQuestion
          questiontoggle={editToggleHandler}
          editedQuestion={editedQuestion}
          setAuth={setAuth}
        />
      )}
    </div>
  )
}

//***********************main component *******************//
const ChildTable = props => {
  // console.log("props ----->", props)
  let ChildQuestionData = props.QuestionData.filter(item => {
    return item.ParentAID
  })

  const childQ = [
    {
      title: "ChildQ",
      dataIndex: "childQ",
    },
    {
      title: "Action",
      render: record => {
        return (
          <Icons
            test={record}
            record={props.record}
            QuestionData={ChildQuestionData}
            newchildQuestion={
              newchildQuestion.filter(child => child.QID === record.QID)[0]
            }
            userPermissions={props.userPermissions}
            setAuth={props.setAuth}
          ></Icons>
        )
      },
    },
  ]
  //----------------------------------------------------------------//
  const childQuestion = []
  const childAnswer = []
  const CAnswers = [{ title: "ChildQ", dataIndex: "childA" }]
  // console.log("childQuestion", childQuestion)

  for (let item in props.QuestionData) {
    props.QuestionData[item].ParentAID &&
      childQuestion.push({
        key: item,
        childQ: props.QuestionData[item].QTitle,
        ParentAID: props.QuestionData[item].ParentAID,
        QID: props.QuestionData[item].QID,
      })

    for (let answer in props.QuestionData[item].Answers) {
      let answerOperator

      props.QuestionData[item].Answers[answer].operator
        ? (answerOperator = props.QuestionData[item].Answers[answer].operator)
        : (answerOperator = " ")
      props.QuestionData[item].ParentAID &&
        childAnswer.push({
          key: answer,
          QID: props.QuestionData[item].QID,
          childA:
            answerOperator +
            " " +
            props.QuestionData[item].Answers[answer].value,
          childAOperator: props.QuestionData[item].Answers[answer].opertator,
          childAID: props.QuestionData[item].Answers[answer].id,
        })
    }
  }
  // console.log("childQuestion :>> ", childQuestion)
  // console.log("props.record :>> ", props.record)

  let newchildQuestion = childQuestion.filter(item => {
    return (
      (item.ParentAID == props.record.answerID) |
      (item.ParentAID == props.record.childAID)
    )
  })
  // console.log("newchildQuestion :>> ", newchildQuestion)
  return (
    <Table
      rowClassName={"table-row-light rounded-5"}
      pagination={false}
      columns={childQ}
      showHeader={false}
      dataSource={newchildQuestion}
      expandable={{
        rowExpandable: record => true,
        expandedRowRender: record => {
          let newdata = childAnswer.filter(item => {
            // console.log(record);
            return item.QID == record.QID
          })
          // console.log("newdata", newdata)
          return (
            <Table
              columns={CAnswers}
              pagination={false}
              dataSource={newdata}
              showHeader={false}
              expandable={{
                //***** child question *******//
                rowExpandable: record => {
                  // console.log(record)
                  for (let i in childQuestion) {
                    if (
                      childQuestion[i].ParentAID == record.childAID //condition for expand
                    ) {
                      return true
                    }
                  }
                },
                //******************************** child ************************//+
                expandedRowRender: record => {
                  return (
                    <ChildTable
                      record={record}
                      QuestionData={ChildQuestionData}
                      userPermissions={props.userPermissions}
                      setAuth={props.setAuth}
                    ></ChildTable>
                  )
                },
              }}
            ></Table>
          )
        },
      }}
    ></Table>
  )
}
export default ChildTable
