import PropTypes from "prop-types"
import React, { useState } from "react"
import axios from "axios"

import { useHistory } from "react-router-dom"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { convertPermission } from "permissionUtils"

const Login = () => {
  const [invalidUser, setInvalidUser] = useState("")
  let history = useHistory()
  //meta title
  document.title = "Login | DomApp - React Admin & Dashboard Template"

  // const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email:
        // "admin@themesbrand.com" ||
        "",
      password:
        // "123456" ||
        "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // dispatch(loginUser(values, props.history))
      const formData = {
        Email: values.email,
        Password: values.password,
      }

      axios
        .post("https://rd0.cpvarabia.com/api/Login", formData)
        .then(response => {
          if (!response) {
            setErrors({
              ...error,
              submitError: "No Server Response",
            })
            return
          }

          console.log("response", response)

          /** Handle Errors
           */
          let token = response.data.Token
          let id = response.data.UserID
          let role = response.data.RoleName
          let userRoleID = response.data.UserRoleID
          let roles = response.data.Roles
          let fullName = response.data.FirstName + " " + response.data.LastName
          let statusCode = response.data.Status

          // console.log(id)
          // console.log(role)
          // console.log(token)
          // console.log(fullName)
          // console.log(statusCode)

          localStorage.setItem("token", token)
          localStorage.setItem("id", id)
          // localStorage.setItem("role", role)
          localStorage.setItem("useName", fullName)
          localStorage.setItem("userRoleID", userRoleID)
          localStorage.setItem("roles", JSON.stringify(roles))

          if (response.data.error == false) {
            setTimeout(() => {
              history.push("/projects-list")
            }, 1000)
            // window.location.reload()
          } else {
            setInvalidUser("Email or password is incorrect")
            // console.log(invalidUser)
          }
        })
    },
  })

  // const { error } = useSelector(state => ({
  //   error: state.Login.error,
  // }))

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to DomApp.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}

                        {invalidUser ? (
                          <div
                            type="invalid"
                            className="danger mt-1 text-danger"
                          >
                            {invalidUser}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
