import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Row,
  Card,
  CardTitle,
  CardText,
  CardBody,
  Input,
  Modal,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
// import ChildQuestion from "./RD-0ChildQuestion"
import RD6ChildQuestion from "./RD6ChildQuestion"
// import OtherChoice from "./Other"
import OtherMultiSelect from "./OtherMultiSelect"
import HelpComponent from "./helpcomponent"
import { useHistory } from "react-router-dom"
import { onSubmitHandler, validationSchema } from "./RD-6Helper"
import LoadingComponent from "common/LoadingComponent"
import ImagePreview from "./ImagePreview"
import ImageUploadAPreview from "./ImageUploadAPreview"
import MultiImages from "./MultiImages"
import OtherChoice from "./Other"

const OtherRD6Section = props => {
  const setAuth = props.setAuth()

  const SpecialSystem = props.SpecialSystem?.SpecialSystem || []

  const [response, setResponse] = useState(null)
  let ticketanswers
  // setTicketanswers] = useState(null)

  const isLastTab = props.submittedKey == props.tabsLength
  const Previous = props.submittedKey > 1
  const history = useHistory()
  const userId = localStorage.getItem("id")
  let projectid = props.projectId
  const [QuestionData, setQuestionData] = useState([])
  const [loading, setLoading] = useState(true)

  function handleAnswersFrom_DataBase(numberAnswers, item) {
    let answerArray = []
    for (let i = 0; i < numberAnswers; i++) {
      answerArray.push({
        value: item["OptionTitle" + i],
        id: item["QOID" + i],
        Hint: item["AHint" + i],
        default: item["DefaultValue" + i],
        operator: item["Operator" + i],
      })
    }
    return answerArray
  }

  // ******************* Preparing showing and hiding questions ************************
  let ignoredQuestionsIds = ["476", "343", "344", "345", "346"]
  SpecialSystem.map(item => {
    if (item === "437") {
      ignoredQuestionsIds.push("233")
      ignoredQuestionsIds = ignoredQuestionsIds.filter(id => id !== "476")
    }
    if (item === "438") {
      ignoredQuestionsIds = ignoredQuestionsIds.filter(id => id !== "343")
    }
    if (item === "439") {
      ignoredQuestionsIds = ignoredQuestionsIds.filter(id => id !== "344")
    }
    if (item === "440") {
      ignoredQuestionsIds = ignoredQuestionsIds.filter(id => id !== "345")
    }
    if (item === "441") {
      ignoredQuestionsIds = ignoredQuestionsIds.filter(id => id !== "346")
    }
  })

  // const isSameUser = (a, b) => {
  //   return a[1].QID === b.QID
  // }
  // const onlyInLeft = (left, right, compareFunction) =>
  //   left.filter(
  //     leftValue =>
  //       !right.some(rightValue => compareFunction(leftValue, rightValue))
  //   )

  // ************************** Answers comming from database ************************************//
  const [dataBaseAnswers, setDataBaseAnswers] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: props.projectId,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD6/AllRD6QAnswers.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        let answers = array.filter(answer => {
          return answer[1].SectionID == props.id
        })
        setDataBaseAnswers(answers)
        // if (answers.length === 0) props.setActiveOtherSection(props.nextSection)
      })
      .catch(err => console.log(err))
  }, [])
  // console.log("dataBaseAnswers", dataBaseAnswers)
  // Preparing disabled of comming miss data
  let missObject = {}
  QuestionData.filter(Q => Q.MissingData).map(item => {
    missObject[`${item.AnswerType}${item.QID}`] = React.createRef()
  })

  // useEffect(() => {
  //   dataBaseAnswers.forEach(answerdata => {
  //     let answer = answerdata[1]
  //     if (answer.AnswerType === "Dropdown" && answer.RSelect[0]) {
  //       // dataValues[`Dropdown${answer.QID}`] = answer.OptionIDs[0]
  //       if (answer.Answers.length > 0) {
  //         setTicketanswers(answer.Answers)
  //       }
  //     }
  //   })
  // }, [])
  useEffect(() => {
    let missDataQuestions = Object.entries(missObject)
    missDataQuestions.map(question => {
      if (question[1]?.current?.checked) {
        let missID = question[1].current.id.slice(8)
        // console.log("missID", missID)
        let childNodes = document
          .getElementById(`answerOfQ${missID}`)
          .getElementsByTagName("*")
        for (var node of childNodes) {
          node.disabled = true
          node.checked = false
          node.value = null
        }
      }
    })
  })

  let dataValues = {}
  let LengthOfotherCheckbox = ""
  dataBaseAnswers.forEach(answerdata => {
    let answer = answerdata[1]
    if (answer.MissingData === "1") {
      dataValues[`${answer.AnswerType}${answer.QID}`] = "MD"
    } else {
      if (answer.AnswerType === "Integer") {
        dataValues[`Integer${answer.QID}`] = answer.Answers[0]
      }

      if (answer.AnswerType === "Radio") {
        dataValues[`Radio${answer.QID}`] = answer.OptionIDs[0]
        if (answer.Answers.length > 0) {
          dataValues[`otherRadio${answer.QID}`] = answer.Answers[0]
        }
      }

      if (answer.AnswerType === "Boolean") {
        dataValues[`Boolean${answer.QID}`] = answer.OptionIDs[0]
      }

      if (answer.AnswerType === "CheckBox") {
        if (answer.OptionIDs[0] === null) {
          dataValues[`CheckBox${answer.QID}`] = answer.OptionIDs[0]
        } else {
          dataValues[`CheckBox${answer.QID}`] = answer.OptionIDs
        }
        if (answer.Answers.length > 0) {
          LengthOfotherCheckbox = answer.Answers.length
          answer.Answers.map(
            (checklistOther, i) =>
              (dataValues[`otherCheckBox${answer.QID}-${i + 1}`] =
                checklistOther)
          )
        }
      }

      if (answer.AnswerType === "Dropdown" && answer.Single == "1") {
        // dataValues[`DropdownMulti${answer.QID}`] = []

        // answer.Answers.map(item =>
        //   dataValues[`DropdownMulti${answer.QID}`].push({ Answer: item })
        // )

        dataValues[`Dropdown${answer.QID}`] = answer.OptionIDs[0]
      }

      // if (answer.AnswerType === "Dropdown") {
      if (answer.AnswerType === "Dropdown" && answer.Single == "0") {
        dataValues[`DropdownMulti${answer.QID}`] = []

        if (answer.Answers.length > 0) {
          answer.Answers.map((item, i) => {
            dataValues[`DropdownMulti${answer.QID}`].push({
              Answer: answer.Answers[i],
              AnswerAR: answer.AnswerAR[i],
              RSelect: answer.RSelect[i],
              CSelect: answer.CSelect[i],
              Term: answer.Term[i],
              TermAR: answer.TermAR[i],
            })
          })
        }

        dataValues[`DropdownMulti${answer.QID}`] = JSON.stringify(
          dataValues[`DropdownMulti${answer.QID}`]
        )
      }

      // if (answer.AnswerType === "Dropdown" && answer.Single == "1") {
      //   dataValues[`Dropdown${answer.QID}`] = answer.OptionIDs[0]
      // }

      // if (answer.AnswerType === "Dropdown" && answer.Single == "0") {
      //   // dataValues[`Dropdown${answer.QID}`] = answer.OptionIDs[0]
      //   if (answer.Answers.length > 0) {
      //     answer.Answers.map((item, i) => {
      //       ticketanswers = answer.Answers
      //       dataValues[`Dropdown${answer.QID}-Answer${i + 1}`] =
      //         answer.Answers[i]
      //       dataValues[`Dropdown${answer.QID}-AnswerAR${i + 1}`] =
      //         answer.AnswerAR[i]
      //       dataValues[`Dropdown${answer.QID}-RSelect${i + 1}`] =
      //         answer.RSelect[i]
      //       dataValues[`Dropdown${answer.QID}-CSelect${i + 1}`] =
      //         answer.CSelect[i]
      //       dataValues[`Dropdown${answer.QID}-Term${i + 1}`] = answer.Term[i]
      //       dataValues[`Dropdown${answer.QID}-TermAR${i + 1}`] =
      //         answer.TermAR[i]
      //     })
      //   }
      // }

      if (answer.AnswerType === "Text") {
        dataValues[`Text${answer.QID}`] = answer.Answers[0]
      }

      if (answer.AnswerType === "File") {
        dataValues[`File${answer.QID}`] = answer.Answers
      }

      if (answer.AnswerType === "Date") {
        dataValues[`Date${answer.QID}`] = answer.Answers[0]
      }
    }
  })

  useEffect(() => {
    props.setAllValues({ ...props.allValues, ...dataValues })
    setTimeout(() => {
      props.setActiveOtherSection(props.nextSection)
    }, 2000)
  }, [dataBaseAnswers])

  useEffect(() => {
    let questionscollect = []
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      SectionID: props.id,
      ProjectID: props.projectId,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD6/show_rd6_all.php", formData)
      .then(questions => {
        if (
          questions.data.error === true &&
          questions.data.message === "Access denied!"
        ) {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("questions.data", questions.data)
        let array = Object.entries(questions.data)
        array.splice(-1)
        array.forEach(item => {
          questionscollect.push({
            QTitle: item[1].QTitle,
            QID: item[1].QID,
            Answers: handleAnswersFrom_DataBase(item[1].OptionsCount, item[1]),
            AnswerType: item[1].AnswerType,
            QHint: item[1].QHint,
            ParentAID: item[1].ParentAID,
            ParentQID: item[1].ParentQID,
            children: [],
            allLevel3: [],
            childrenLevel3: [],
            Other: item[1].Other,
            QHelp: item[1].QHelp,
            MissingData: item[1].MissingData,
            SingleDropdown: item[1].Single,
            initialChildrenCheck: false,
            initialChildrenCheckLevel3: false,
            order: item[1].QuestionOrder,
          })
        }) //end of push

        // ***************Showing and Hiding Questions as comming from special system *****************
        ignoredQuestionsIds.map(id => {
          questionscollect = questionscollect.filter(item => item.QID !== id)
        })

        let sortedarray = [...questionscollect].sort(
          (a, b) => a.order - b.order
        )
        // questionscollect.map

        setQuestionData(sortedarray)
      }) //end of foreach
  }, [])
  // console.log("QuestionData", QuestionData)
  // ******************* end of chilD COMPONENT ************************************************//

  const handleKeypress = e => {
    if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.shiftKey) {
      e.preventDefault()
    }
  }

  // ***** preparing child question array for validation *********
  let childQuestionArray = []
  let childQuestionArrayLevel3 = []
  QuestionData.map(item => {
    if (item.children.length > 0) {
      childQuestionArray = [...childQuestionArray, ...item.children]
      item.children.map(child => {
        if (child.childrenLevel3.length > 0) {
          childQuestionArrayLevel3 = [
            ...childQuestionArrayLevel3,
            ...child.childrenLevel3,
          ]
        }
      })
    }
  })

  //****************************** child questions ****************************//
  const childQuestion = []
  for (let item in QuestionData) {
    QuestionData[item].ParentAID && childQuestion.push(QuestionData[item])
  }
  const [defaultArray, setDefaultArray] = useState([])

  function transferoperator(answer, value) {
    let targetValue = Number(value)
    let answerValue = Number(answer.value)
    switch (answer.operator) {
      case "NQ":
        return targetValue != answerValue
      case "EQ":
        return targetValue == answerValue
      case "GT":
        return targetValue > answerValue
      case "LT":
        return targetValue < answerValue
      case "LQ":
        return targetValue <= answerValue
      case "GQ":
        return targetValue >= answerValue
      case "FT":
        let FT = answer.value.split("|")
        return targetValue >= Number(FT[0]) && targetValue <= Number(FT[1])
      default:
        break
    }
  }
  const condtionFunction = (Qitem, value) => {
    let Array = []
    Qitem.Answers.forEach(item => {
      Array.push({
        condtion: transferoperator(item, value),
        answer: item,
      })
    })
    return Array
  }

  //****************************** child questions ****************************//

  const getChild = (
    answerID,
    index,
    IntegerValue,
    Question,
    isChecked,
    initialCheck
  ) => {
    let temp = [...QuestionData]
    let childdata = []
    let searchForLevel3 = false
    let tobeAdded = []
    if (initialCheck && Question.initialChildrenCheck) return
    if (Question.AnswerType == "Integer") {
      condtionFunction(Question, IntegerValue).forEach(item => {
        if (item.condtion) {
          tobeAdded = childQuestion.filter(cQ => item.answer.id == cQ.ParentAID)
          childdata = [...childdata, ...tobeAdded]
          searchForLevel3 = true
        }
      })
    } else if (Question.AnswerType == "CheckBox" && isChecked == false) {
      childdata = Question.children.filter(Q => Q.ParentAID != answerID)
    } else if (Question.AnswerType == "CheckBox" && isChecked == true) {
      tobeAdded = childQuestion.filter(cQ => answerID == cQ.ParentAID)
      childdata = [...Question.children, ...tobeAdded]
      searchForLevel3 = true
    } else {
      childdata = childQuestion.filter(cQ => answerID == cQ.ParentAID)
      searchForLevel3 = true
    }

    if (searchForLevel3) {
      // level 3
      childdata.forEach(Q => {
        Q.allLevel3 = childQuestion.filter(cQ => Q.QID == cQ.ParentQID)
      })
    }
    if (initialCheck || childdata.length > 0) {
      // get children first time from "stored answeres"
      // or "action" taken and found childdata
      temp[index].initialChildrenCheck = true
      temp[index].children = childdata
      setQuestionData(temp)
    } else {
      // clear children if "action" happened and not found childdata
      temp[index].initialChildrenCheck = true
      temp[index].children = []
      setQuestionData(temp)
    }
  }

  // ************************* missing data function ************************
  const [missData, setMissData] = useState([])
  const missingData = (target, values, errors) => {
    let questionId = target.id.slice(8)
    let clickedQuestion = QuestionData.filter(
      item => item.QID === questionId
    )[0]

    let tempQuestionData = [...QuestionData]
    tempQuestionData = tempQuestionData.map(item => {
      if (item.QID === questionId) item.children = []
      return item
    })
    setQuestionData(tempQuestionData)

    if (target.checked == true) {
      missData.push(questionId)
    } else {
      let tempAnswerIds = [...missData]
      tempAnswerIds.splice(missData.indexOf(questionId), 1)
      setMissData(tempAnswerIds)
    }
    let childNodes = document
      .getElementById(`answerOfQ${questionId}`)
      .getElementsByTagName("*")

    if (target.checked == true) {
      delete errors[`${clickedQuestion.AnswerType}${clickedQuestion.QID}`]
      for (var node of childNodes) {
        node.disabled = true
        node.checked = false
        node.value = null
      }
    } else {
      errors[`${clickedQuestion.AnswerType}${clickedQuestion.QID}`] = "Required"
      for (var node of childNodes) {
        node.disabled = false
        delete values[`${clickedQuestion.AnswerType}${clickedQuestion.QID}`]
        delete dataValues[`${clickedQuestion.AnswerType}${clickedQuestion.QID}`]
      }
      // console.log(`${clickedQuestion.AnswerType}${clickedQuestion.QID}`)
    }
  }

  // ***********************************select section **********************//
  let answersDropDownArray = []
  QuestionData.map((item, index) => {
    item.AnswerType == "Dropdown" &&
      answersDropDownArray.push({
        answerId: item.QID,
        answers: [],
      })
    answersDropDownArray.map(answer => {
      item.QID == answer.answerId &&
        item.Answers.map(itemAnswer =>
          answer.answers.push({
            options: [{ label: itemAnswer.value, value: itemAnswer.id }],
          })
        )
    })
  })

  const [error, setError] = useState({})

  const PreviousFunc = () => {
    if (props.submittedKey - 1 >= 1) {
      // props.setAllValues([...props.allValues, ...data])
      props.setActiveTab((props.submittedKey - 1).toString())
    }
  }

  // for redirecting to reports page after submitting
  useEffect(() => {
    if (response) history.push("/rd6-reports")
  }, [response])

  const [viewImages, setViewImages] = useState(false)
  const toggleViewImages = () => {
    setViewImages(!viewImages)
  }
  const [Images, setImages] = useState([])
  const [name, setName] = useState()
  const [imageprops, setProps] = useState()
  const [multiImages, setMultiImages] = useState()

  function imagesPreview(images, name, props, multi) {
    setImages(images)
    setProps(props)
    setName(name)
    setMultiImages(multi)
    toggleViewImages()
  }

  //******************************************* component ************************************************//
  return null
  //   return (
  //     <React.Fragment>
  //       <div>
  //         <Formik
  //           enableReinitialize={true}
  //           initialValues={{ ...dataValues }}
  //           validate={values => {
  //             let errors = {}
  //             errors = validationSchema(
  //               values,
  //               errors,
  //               QuestionData,
  //               childQuestionArray,
  //               childQuestionArrayLevel3,
  //               missData,
  //               defaultArray,
  //               setError,
  //               transferoperator
  //             )

  //             return errors
  //           }}
  //           onSubmit={async (values, actions) => {
  //             await onSubmitHandler(
  //               values,
  //               actions,
  //               QuestionData,
  //               props,
  //               error,
  //               SpecialSystem,
  //               userId,
  //               projectid,
  //               setResponse,
  //               setLoading,
  //               history,
  //               setAuth
  //             )
  //           }}
  //         >
  //           {props => (
  //             <Form
  //               className="ms-1"
  //               onSubmit={props.handleSubmit}
  //               onKeyPress={e => handleKeypress(e)}
  //             >
  //               <div>
  //                 <Row className="ms-2 me-3  ">
  //                   {/* Parent Question show */}
  //                   {QuestionData.map((Qitem, index) => {
  //                     return (
  //                       !Qitem.ParentAID &&
  //                       (props.values[`${Qitem.AnswerType}${Qitem.QID}`] &&
  //                         !Qitem.initialChildrenCheck &&
  //                         getChild(
  //                           props.values[`${Qitem.AnswerType}${Qitem.QID}`],
  //                           index,
  //                           props.values[`${Qitem.AnswerType}${Qitem.QID}`],
  //                           QuestionData[index],
  //                           true,
  //                           true
  //                         ),
  //                       (
  //                         <Card
  //                           id={Qitem.QID}
  //                           key={index + "PQ"}
  //                           style={{ marginBottom: "12px" }}
  //                           className="border border-light "
  //                           // onClick={() => {
  //                           //   console.log(
  //                           //     "Qitem ===============================>",
  //                           //     Qitem
  //                           //     // QuestionData[index].children
  //                           //   )
  //                           //   console.log(
  //                           //     "Qitem-value ===============================>",
  //                           //     props.values[`${Qitem.AnswerType}${Qitem.QID}`]
  //                           //     // QuestionData[index].children
  //                           //   )
  //                           // }}
  //                         >
  //                           <CardBody className="ms-5 ">
  //                             <div className="d-flex  col-12 mb-0">
  //                               <div className="col-6">
  //                                 <CardTitle
  //                                   style={{ fontSize: "14px" }}
  //                                   className="col-12"
  //                                 >
  //                                   {Qitem.QTitle}
  //                                 </CardTitle>
  //                               </div>

  //                               {Qitem.QHelp && (
  //                                 <div id="Help" className="col-2 ms-5">
  //                                   <HelpComponent
  //                                     id={Qitem.QID}
  //                                     Title={Qitem.QHelp}
  //                                   />
  //                                 </div>
  //                               )}

  //                               <div className="col-4">
  //                                 {Qitem.MissingData && (
  //                                   <label
  //                                     htmlFor={"missingdata" + Qitem.QID}
  //                                     className="ms-2 "
  //                                   >
  //                                     <input
  //                                       className="ms-5 mb-2 me-1 "
  //                                       type="checkbox"
  //                                       id={"missData" + Qitem.QID}
  //                                       value="MD"
  //                                       ref={
  //                                         missObject[
  //                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                         ]
  //                                       }
  //                                       defaultChecked={
  //                                         props.values[
  //                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                         ] &&
  //                                         props.values[
  //                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                         ].includes("MD")
  //                                       }
  //                                       onClick={e => {
  //                                         missingData(
  //                                           e.target,
  //                                           props.values,
  //                                           props.errors
  //                                         )
  //                                       }}
  //                                     />
  //                                     {Qitem.MissingData}
  //                                   </label>
  //                                 )}
  //                               </div>
  //                               <div className="col-3 ms-5">
  //                                 {props.errors[
  //                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                 ] ? (
  //                                   <div className="error col-12" style={{}}>
  //                                     {
  //                                       props.errors[
  //                                         `${Qitem.AnswerType}${Qitem.QID}`
  //                                       ]
  //                                     }
  //                                   </div>
  //                                 ) : null}
  //                                 {/* {props.errors[
  //                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                 ] ? (
  //                                   <div className="error col-12" style={{}}>
  //                                     {
  //                                       props.errors[
  //                                         `${Qitem.AnswerType}${Qitem.QID}`
  //                                       ]
  //                                     }
  //                                   </div>
  //                                 ) : null} */}
  //                               </div>
  //                               <div className="col-2 ms-5">
  //                                 {
  //                                   // props.touched[
  //                                   //   `${Qitem.AnswerType}${Qitem.QID}`
  //                                   // ] &&
  //                                   props.errors[
  //                                     `${Qitem.AnswerType}${Qitem.QID}`
  //                                   ] ? (
  //                                     <div className="error col-12" style={{}}>
  //                                       {
  //                                         props.errors[
  //                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                         ]
  //                                       }
  //                                     </div>
  //                                   ) : null
  //                                 }
  //                               </div>
  //                             </div>
  //                             <div>
  //                               {Qitem.QHint && (
  //                                 <div className="text-warning mb-2 d-block">
  //                                   <h6 className="d-inline"> *{Qitem.QHint}</h6>
  //                                 </div>
  //                               )}
  //                             </div>

  //                             <div id={"answerOfQ" + Qitem.QID} className="ms-2">
  //                               {/*******  Answers show ******/}
  //                               {Qitem.AnswerType == "Radio" ||
  //                               Qitem.AnswerType == "CheckBox" ||
  //                               Qitem.AnswerType == "Boolean" ? (
  //                                 <>
  //                                   {Qitem.Answers.map((Aitem, key) => {
  //                                     Aitem.default == 1 &&
  //                                       defaultArray.push({
  //                                         answer: Aitem.id,
  //                                         question: `${Qitem.AnswerType}${Qitem.QID}`,
  //                                       })
  //                                     return (
  //                                       <CardText
  //                                         className="d-flex"
  //                                         style={{ marginBottom: "0px" }}
  //                                         key={key + "F"}
  //                                       >
  //                                         {Qitem.AnswerType == "Boolean" ||
  //                                         Qitem.AnswerType == "Radio" ? (
  //                                           <label
  //                                             className="ms-1 "
  //                                             htmlFor={Aitem.id}
  //                                           >
  //                                             <input
  //                                               onChange={props.handleChange}
  //                                               type="radio"
  //                                               name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                               value={Aitem.id}
  //                                               defaultChecked={
  //                                                 Aitem.default == 1 ||
  //                                                 (props.values[
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ] &&
  //                                                   props.values[
  //                                                     `${Qitem.AnswerType}${Qitem.QID}`
  //                                                   ].includes(Aitem.id))
  //                                               }
  //                                               onClick={e => {
  //                                                 getChild(
  //                                                   Aitem.id,
  //                                                   index,
  //                                                   e.target.value,
  //                                                   Qitem,
  //                                                   e.target.checked,
  //                                                   false
  //                                                 )
  //                                                 props.setFieldValue(
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                   Aitem.id
  //                                                 )
  //                                               }}
  //                                               id={Aitem.id}
  //                                               className="me-1"
  //                                             />
  //                                             {Aitem.value}
  //                                           </label>
  //                                         ) : (
  //                                           <label
  //                                             className="ms-1 "
  //                                             htmlFor={Aitem.id}
  //                                           >
  //                                             <input
  //                                               onChange={props.handleChange}
  //                                               type="checkbox"
  //                                               name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                               value={Aitem.id}
  //                                               checked={
  //                                                 (props.values[
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ] &&
  //                                                   props.values[
  //                                                     `${Qitem.AnswerType}${Qitem.QID}`
  //                                                   ].includes(Aitem.id)) ||
  //                                                 Aitem.default == 1
  //                                               }
  //                                               onClick={e => {
  //                                                 getChild(
  //                                                   Aitem.id,
  //                                                   index,
  //                                                   e.target.value,
  //                                                   Qitem,
  //                                                   !e.target.checked,
  //                                                   false
  //                                                 )

  //                                                 if (
  //                                                   props.values[
  //                                                     `${Qitem.AnswerType}${Qitem.QID}`
  //                                                   ]
  //                                                 ) {
  //                                                   if (
  //                                                     props.values[
  //                                                       `${Qitem.AnswerType}${Qitem.QID}`
  //                                                     ].includes(Aitem.id)
  //                                                   ) {
  //                                                     props.setFieldValue(
  //                                                       `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                       [
  //                                                         ...props.values[
  //                                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                                         ].filter(
  //                                                           checkAnswer =>
  //                                                             checkAnswer !==
  //                                                             Aitem.id
  //                                                         ),
  //                                                       ]
  //                                                     )
  //                                                   } else {
  //                                                     props.setFieldValue(
  //                                                       `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                       [
  //                                                         ...props.values[
  //                                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                                         ],
  //                                                         Aitem.id,
  //                                                       ]
  //                                                     )
  //                                                   }
  //                                                 } else {
  //                                                   props.setFieldValue(
  //                                                     `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                     [Aitem.id]
  //                                                   )
  //                                                 }
  //                                               }}
  //                                               id={Aitem.id}
  //                                               className="me-1"
  //                                             />
  //                                             {Aitem.value}
  //                                           </label>
  //                                         )}

  //                                         {Aitem.Hint && (
  //                                           <div className="fw-bold mb-2 d-flex ">
  //                                             <h6 className="d-inline fw-bolder">
  //                                               {Aitem.Hint}
  //                                             </h6>
  //                                           </div>
  //                                         )}
  //                                       </CardText>
  //                                     )
  //                                   })}
  //                                   {Qitem.AnswerType === "Radio" && (
  //                                     <>
  //                                       {(Qitem.Other || Qitem.Other != null) && (
  //                                         <div>
  //                                           <CardText
  //                                             style={{ marginBottom: "0px" }}
  //                                           >
  //                                             <label
  //                                               className="ms-1"
  //                                               htmlFor={`other${Qitem.QID}`}
  //                                             >
  //                                               <input
  //                                                 onChange={props.handleChange}
  //                                                 type="radio"
  //                                                 name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                                 value="other"
  //                                                 id={`other${Qitem.QID}`}
  //                                                 className="me-1"
  //                                                 defaultChecked={
  //                                                   props.values[
  //                                                     `other${Qitem.AnswerType}${Qitem.QID}`
  //                                                   ]
  //                                                 }
  //                                               />
  //                                               {Qitem.Other}
  //                                             </label>
  //                                           </CardText>

  //                                           <FormGroup className="d-flex">
  //                                             <input
  //                                               onChange={props.handleChange}
  //                                               type="text"
  //                                               id={`otherChoice${Qitem.QID}`}
  //                                               name={`other${Qitem.AnswerType}${Qitem.QID}`}
  //                                               style={{ height: "24px" }}
  //                                               disabled={
  //                                                 !Boolean(
  //                                                   props.values[
  //                                                     `${Qitem.AnswerType}${Qitem.QID}`
  //                                                   ] == "other"
  //                                                 ) ||
  //                                                 !Boolean(
  //                                                   props.values[
  //                                                     `other${Qitem.AnswerType}${Qitem.QID}`
  //                                                   ]
  //                                                 )
  //                                               }
  //                                               defaultValue={
  //                                                 props.values[
  //                                                   `other${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ]
  //                                               }
  //                                             />
  //                                           </FormGroup>
  //                                         </div>
  //                                       )}
  //                                     </>
  //                                   )}
  //                                   {Qitem.AnswerType === "CheckBox" &&
  //                                     (Qitem.Other || Qitem.Other != null) && (
  //                                       <OtherChoice
  //                                         LengthOfotherCheckbox={
  //                                           LengthOfotherCheckbox
  //                                         }
  //                                         props={props}
  //                                         name={`other${Qitem.AnswerType}${Qitem.QID}`}
  //                                         label={Qitem.Other}
  //                                       />
  //                                     )}
  //                                 </>
  //                               ) : Qitem.AnswerType == "Dropdown" &&
  //                                 Qitem.SingleDropdown == "0" ? (
  //                                 <CardText
  //                                   className="d-flex"
  //                                   style={{ marginBottom: "0px" }}
  //                                 >
  //                                   <div className="d-flex flex-column col-10 justify-content-between">
  //                                     {/* Adding other info like TR */}
  //                                     {
  //                                       <OtherMultiSelect
  //                                         answers={ticketanswers && ticketanswers}
  //                                         props={props}
  //                                         Qitem={Qitem}
  //                                         dataAnswers={
  //                                           dataBaseAnswers.filter(
  //                                             answer => answer.QID === Qitem.QID
  //                                           )[0]?.RSelect
  //                                         }
  //                                         setAuth={setAuth}
  //                                       />
  //                                     }
  //                                   </div>
  //                                   {Qitem.Answers.map(
  //                                     (Aitem, key) =>
  //                                       Aitem.Hint && (
  //                                         <div
  //                                           className="fw-bold mb-2 d-flex "
  //                                           key={key + "hint"}
  //                                         >
  //                                           <h6 className="d-inline fw-bolder">
  //                                             {Aitem.Hint}
  //                                           </h6>
  //                                         </div>
  //                                       )
  //                                   )}
  //                                 </CardText>
  //                               ) : Qitem.AnswerType == "Dropdown" &&
  //                                 Qitem.SingleDropdown == "1" ? (
  //                                 <CardText
  //                                   className="d-flex"
  //                                   style={{ marginBottom: "0px" }}
  //                                 >
  //                                   <div className="d-flex flex-column col-8 justify-content-between">
  //                                     <Input
  //                                       name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                       type="select"
  //                                       onChange={props.handleChange}
  //                                     >
  //                                       <option selected disabled>
  //                                         -- Choose one Option --
  //                                       </option>
  //                                       {Qitem.Answers.map((Aitem, key) => (
  //                                         <option
  //                                           key={key}
  //                                           value={Aitem.id}
  //                                           selected={
  //                                             props.values[
  //                                               `${Qitem.AnswerType}${Qitem.QID}`
  //                                             ] === Aitem.id
  //                                           }
  //                                         >
  //                                           {Aitem.value}
  //                                         </option>
  //                                       ))}
  //                                     </Input>
  //                                   </div>
  //                                 </CardText>
  //                               ) : Qitem.AnswerType == "Integer" ? (
  //                                 <CardText
  //                                   className="d-flex"
  //                                   style={{ marginBottom: "0px" }}
  //                                 >
  //                                   {Qitem.Answers.map((Aitem, key) => {
  //                                     if (key == 0)
  //                                       return (
  //                                         <div
  //                                           className="d-flex col-12 me-5"
  //                                           key={key + "Int"}
  //                                         >
  //                                           <div className="d-flex flex-column col-2 justify-content-between">
  //                                             <Input
  //                                               name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                               value={
  //                                                 props.values[
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ] || ""
  //                                               }
  //                                               onChange={e => {
  //                                                 props.handleChange(
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 )(e.target.value)
  //                                                 getChild(
  //                                                   Aitem.id,
  //                                                   index,
  //                                                   e.target.value,
  //                                                   Qitem,
  //                                                   true,
  //                                                   false
  //                                                 )
  //                                               }}
  //                                               type="number"
  //                                               step="0.0001"
  //                                               className="d-flex"
  //                                               onWheel={e => e.target.blur()}
  //                                             />
  //                                           </div>
  //                                           {Aitem.Hint && (
  //                                             <div
  //                                               className="fw-bolder mt-2 d-flex ms-5 "
  //                                               key={key + "hint"}
  //                                             >
  //                                               <h6 className="d-inline fw-bolder">
  //                                                 {Aitem.Hint}
  //                                               </h6>
  //                                             </div>
  //                                           )}
  //                                         </div>
  //                                       )
  //                                   })}
  //                                 </CardText>
  //                               ) : Qitem.AnswerType == "Text" ? (
  //                                 Qitem.Answers.map((Aitem, key) => {
  //                                   Aitem.default === "1" &&
  //                                     defaultArray.push({
  //                                       answer: Aitem.value,
  //                                       question: `${Qitem.AnswerType}${Qitem.QID}`,
  //                                     })
  //                                   return (
  //                                     <CardText
  //                                       className="d-flex"
  //                                       style={{ marginBottom: "0px" }}
  //                                       key={key + "F"}
  //                                     >
  //                                       <div className="d-flex col-12">
  //                                         <div className="d-flex col-8">
  //                                           <Input
  //                                             onChange={props.handleChange}
  //                                             type="textarea"
  //                                             name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                             defaultValue={
  //                                               Aitem.default === "1"
  //                                                 ? Aitem.value
  //                                                 : ""
  //                                             }
  //                                             value={
  //                                               props.values[
  //                                                 `${Qitem.AnswerType}${Qitem.QID}`
  //                                               ]
  //                                             }
  //                                             id={Aitem.id}
  //                                             className="me-1"
  //                                           />
  //                                         </div>
  //                                         {Aitem.Hint && (
  //                                           <div
  //                                             className="fw-bold mt-2 d-flex ms-2 "
  //                                             key={key + "hint"}
  //                                           >
  //                                             <h6 className="d-inline fw-bolder">
  //                                               {Aitem.Hint}
  //                                             </h6>
  //                                           </div>
  //                                         )}
  //                                       </div>
  //                                     </CardText>
  //                                   )
  //                                 })
  //                               ) : Qitem.AnswerType == "File" ? (
  //                                 <div className="d-flex ">
  //                                   {Qitem.Answers.map((Aitem, key) => {
  //                                     return (
  //                                       <CardText
  //                                         className="d-flex"
  //                                         style={{ marginBottom: "0px" }}
  //                                         key={key + "F"}
  //                                       >
  //                                         <div className="d-flex col-12">
  //                                           <div className="d-flex col-10">
  //                                             {Aitem.value == "pdf" ? (
  //                                               <div className="d-flex">
  //                                                 <ImageUploadAPreview
  //                                                   name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                                   value={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                                   props={props}
  //                                                   setAuth={setAuth}
  //                                                 />

  //                                                 {props.values[
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ] && (
  //                                                   <div>
  //                                                     <Button
  //                                                       onClick={() =>
  //                                                         imagesPreview(
  //                                                           props.values[
  //                                                             `${Qitem.AnswerType}${Qitem.QID}`
  //                                                           ],
  //                                                           `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                           props
  //                                                         )
  //                                                       }
  //                                                     >
  //                                                       preview
  //                                                     </Button>
  //                                                   </div>
  //                                                 )}
  //                                               </div>
  //                                             ) : Aitem.value == "multi" ? (
  //                                               <div className="d-flex">
  //                                                 <MultiImages
  //                                                   name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                                   value={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                                   props={props}
  //                                                   setAuth={setAuth}
  //                                                 />

  //                                                 {props.values[
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ] && (
  //                                                   <div>
  //                                                     <Button
  //                                                       onClick={() =>
  //                                                         imagesPreview(
  //                                                           props.values[
  //                                                             `${Qitem.AnswerType}${Qitem.QID}`
  //                                                           ],
  //                                                           `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                           props,
  //                                                           " multi"
  //                                                         )
  //                                                       }
  //                                                     >
  //                                                       preview
  //                                                     </Button>
  //                                                   </div>
  //                                                 )}
  //                                               </div>
  //                                             ) : (
  //                                               <div className="d-flex">
  //                                                 <Input
  //                                                   onChange={e =>
  //                                                     props.setFieldValue(
  //                                                       `${Qitem.AnswerType}${Qitem.QID}`,
  //                                                       e.target.files[0]
  //                                                     )
  //                                                   }
  //                                                   type="file"
  //                                                   name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                                   id={Aitem.id}
  //                                                   className="me-1"
  //                                                   accept="image/*"
  //                                                 />

  //                                                 {props.values[
  //                                                   `${Qitem.AnswerType}${Qitem.QID}`
  //                                                 ] && (
  //                                                   <Button
  //                                                     onClick={() =>
  //                                                       imagesPreview(
  //                                                         props.values[
  //                                                           `${Qitem.AnswerType}${Qitem.QID}`
  //                                                         ]
  //                                                       )
  //                                                     }
  //                                                   >
  //                                                     preview
  //                                                   </Button>
  //                                                 )}
  //                                               </div>
  //                                             )}
  //                                           </div>
  //                                           {Aitem.Hint && (
  //                                             <div
  //                                               className="fw-bold mt-2 d-flex ms-2 "
  //                                               key={key + "hint"}
  //                                             >
  //                                               <h6 className="d-inline fw-bolder">
  //                                                 {Aitem.Hint}
  //                                               </h6>
  //                                             </div>
  //                                           )}
  //                                         </div>
  //                                       </CardText>
  //                                     )
  //                                   })}
  //                                 </div>
  //                               ) : (
  //                                 <CardText
  //                                   className="d-flex"
  //                                   style={{ marginBottom: "0px" }}
  //                                   id={"answerOfQ" + Qitem.QID}
  //                                 >
  //                                   <input
  //                                     onChange={props.handleChange}
  //                                     type={Qitem.AnswerType}
  //                                     name={`${Qitem.AnswerType}${Qitem.QID}`}
  //                                   />
  //                                 </CardText>
  //                               )}
  //                             </div>
  //                             <RD6ChildQuestion
  //                               key={QuestionData[index].children}
  //                               childData={QuestionData[index].children}
  //                               props={props}
  //                               missData={missData}
  //                               setMissData={setMissData}
  //                               defaultArray={defaultArray}
  //                               setAuth={setAuth}
  //                             />
  //                           </CardBody>
  //                         </Card>
  //                       ))
  //                     )
  //                   })}
  //                 </Row>
  //               </div>
  //               <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around"></FormGroup>

  //               <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
  //                 {isLastTab ? (
  //                   <div>
  //                     <Button onClick={PreviousFunc} className="bg-primary me-2">
  //                       {"< previous"}
  //                     </Button>
  //                     <Button type="submit" className="bg-primary">
  //                       Submit
  //                     </Button>
  //                   </div>
  //                 ) : (
  //                   <div>
  //                     {Previous && (
  //                       <Button
  //                         onClick={PreviousFunc}
  //                         className="bg-primary me-2"
  //                       >
  //                         {"< previous"}
  //                       </Button>
  //                     )}
  //                     <Button type="submit" className="bg-primary">
  //                       {"Next >"}
  //                     </Button>
  //                   </div>
  //                 )}
  //               </FormGroup>
  //             </Form>
  //           )}
  //         </Formik>
  //         <LoadingComponent response={loading} setResponse={setLoading} />
  //         <Modal
  //           isOpen={viewImages}
  //           toggle={toggleViewImages}
  //           className="modal-lg"
  //         >
  //           <ImagePreview
  //             Images={Images}
  //             name={name}
  //             toggleViewImages={toggleViewImages}
  //             imageprops={imageprops}
  //             multiImages={multiImages}
  //             setAuth={setAuth}
  //           />
  //         </Modal>
  //       </div>
  //     </React.Fragment>
  //   )
}

export default OtherRD6Section
