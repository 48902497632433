import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import ViewTicketHistory from "./ViewTicketHistory"
import TicketDataReceived from "./TicketDataReceived"

const TicketsTable = ({ ProjectID, setAuth }) => {
  const history = useHistory()

  const [tickets, setTickets] = useState([])
  const [isHovering, setIsHovering] = useState(false)
  const [hoveringTicket, setHoveringTicket] = useState(null)

  // **************** Ticket History Modal *********************
  const [selectedTicket, setSelectedTicket] = useState()

  const [ticketHistoryModal, setTicketHistoryModal] = useState(false)
  const ticketHistoryToggle = () => {
    setTicketHistoryModal(!ticketHistoryModal)
  }

  //   // **************** Ticket Data Modal *********************
  const [selectedFile, setSelectedFile] = useState()
  const [ticketDataModal, setTicketDataModal] = useState(false)
  const ticketDataToggle = () => {
    setTicketDataModal(!ticketDataModal)
  }

  // **************** pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
      Page: page,
    }
    axios
      .post(
        "https://rd0.cpvarabia.com/api/ProjectDetails/ProjectTicketsList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-4)
        setTickets(array)
      })
      .catch(err => console.log("error------>", err))
  }, [])

  return (
    <div className="table-responsive">
      <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
        <thead>
          <tr>
            <th scope="col" style={{ width: "20px" }}>
              #
            </th>
            <th scope="col">Id</th>
            <th scope="col">Type</th>
            <th scope="col">Description</th>
            <th scope="col">Code</th>
            <th scope="col">Created By</th>
            <th scope="col">Updated By</th>
            <th scope="col">Degree</th>
            <th scope="col">RD5 Status</th>
            <th scope="col">Activity</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        {/***********table body *****************/}
        <tbody>
          {tickets.map((item, key) => (
            <tr key={key} style={{ position: "relative" }}>
              <td>
                <input
                  //   value={item.Code}
                  type="checkbox"
                  //   onClick={e => {
                  //     console.log("---------->", e.target.value)
                  //     selectedTicketsHandler(e.target.value)
                  //   }}
                  className="form-check-input p-1 border-primary"
                />
              </td>
              <td>{item.TicketID}</td>
              <td>
                <span>
                  <i
                    id={`category-${item.TicketID}`}
                    className={
                      item.StageCategory === "Design"
                        ? "fas fa-pencil-ruler"
                        : "fas fa-map-marked-alt"
                    }
                  />

                  <UncontrolledTooltip
                    // autohide={true}
                    placement="bottom"
                    target={`category-${item.TicketID}`}
                  >
                    {item.StageCategory}
                  </UncontrolledTooltip>
                </span>
              </td>

              <td style={{ position: "relative" }}>
                <span
                  onMouseOver={() => {
                    setIsHovering(true)
                    setHoveringTicket(item.TicketID)
                  }}
                  onMouseOut={() => {
                    setIsHovering(false)
                    setHoveringTicket(null)
                  }}
                  style={{ cursor: "pointer" }}
                  className="my-auto"
                >
                  {item.Description?.slice(0, 9)}
                  {item.Description?.length > 9 && " ..."}
                </span>
              </td>
              <td>{item.Code}</td>
              <td>{item.CreatorName}</td>
              <td>{item.UpdaterName}</td>
              <td>{item.DegreeName}</td>
              <td>
                <span
                  className={
                    item.RD5Status === "Active"
                      ? "badge bg-danger"
                      : item.RD5Status === "Inactive"
                      ? "badge bg-success"
                      : "badge bg-warning"
                  }
                >
                  {item.RD5Status}
                </span>
              </td>
              <td>
                {item.LastUpdate?.split(" ")[0] ||
                  item.CreatedAt?.split(" ")[0]}
              </td>
              <td>
                <UncontrolledDropdown className="btn-group bg-primary">
                  <button
                    onClick={() => {
                      setSelectedTicket(item)
                      ticketHistoryToggle()
                    }}
                    className="btn btn-primary btn-sm dropdown-toggle"
                  >
                    View
                  </button>
                </UncontrolledDropdown>
              </td>

              {isHovering &&
                hoveringTicket === item.TicketID &&
                item.Description?.length > 9 && (
                  <p
                    style={{
                      position: "absolute",
                      top: "70%",
                      left: "15%",
                      right: "30%",
                      zIndex: "10",
                      backgroundColor: "#fbfcd4",
                      border: "1px solid black",
                      borderRadius: "5px",
                      padding: "0 5px",
                    }}
                  >
                    {item.Description}
                  </p>
                )}
            </tr>
          ))}
        </tbody>
      </Table>

      {ticketHistoryModal && (
        <ViewTicketHistory
          ticketHistoryToggle={ticketHistoryToggle}
          selectedTicket={selectedTicket}
          ticketDataToggle={ticketDataToggle}
          setSelectedFile={setSelectedFile}
          setAuth={setAuth}
        />
      )}

      {ticketDataModal && (
        <TicketDataReceived
          ticketDataToggle={ticketDataToggle}
          selectedTicket={selectedTicket}
          selectedFile={selectedFile}
        />
      )}

      {tickets.length > 1 && (
        <Row>
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </Row>
      )}
    </div>
  )
}

export default TicketsTable
