import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const ChangePayment = ({
  paymentToggle,
  selectedItem,
  editing,
  setEditing,
}) => {
  // console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    paymentToggle()
  }
  const onClose = () => {
    paymentToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()
    const quotationId = selectedItem.QuotationID
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      QID: selectedItem.QuotationID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/UpdateRD7PaidStatus", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          history.push("/logout")
        }

        // console.log("res", res)
        setEditing(!editing)
      })
      .catch(error => {
        console.log(error)
      })
    onClose()
  }
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Change Payment Status
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <Form onSubmit={submitHandler}>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to change payment status to be{" "}
              <span className="font-size-20">PAID</span> ?
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={
                  userPermissions.R14.P !== "3" && userPermissions.R14.P !== "4"
                }
              >
                Change Now
              </button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ChangePayment
