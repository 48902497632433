import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import ViewTicket from "./ViewTicket"
import RD6TicketHistory from "./InspectionTicketHistory"
import RD6DataFileView from "./InspectionDataFileView"
import NewTicketModal from "common/NewTicket/NewTicketModal"
import ConfirmingAction from "common/ConfirmingAction"
import InspectionDataFileView from "./InspectionDataFileView"
import InspectionTicketHistory from "./InspectionTicketHistory"
// import ViewTicketHistory from "./ViewTicketHistory"
// import TicketDataReceived from "./TicketDataReceived"

const InspectionRelatedTickets = ({
  pStageID,
  ProjectID,
  userPermissions,
  setAuth,
  editing,
  setEditing,
}) => {
  const history = useHistory()

  const [tickets, setTickets] = useState([])
  const [isHovering, setIsHovering] = useState(false)
  const [hoveringTicket, setHoveringTicket] = useState(null)

  //**********new ticket modal********************
  const [newTicketModal, setNewTicketModal] = useState(false)
  const newTicketToggle = () => {
    setNewTicketModal(!newTicketModal)
  }
  // ********** confirm New Ticket Action ***************
  const [ConfirmNewTicketModel, setConfirmNewTicketModel] = useState(false)
  const ConfirmeNewTickettoggle = () => {
    setConfirmNewTicketModel(!ConfirmNewTicketModel)
  }
  const AddNewTicketFunction = () => {
    ConfirmeNewTickettoggle()
    TicketSearchToggle()
  }
  const confirmFunc = () => {
    newTicketToggle()
    ConfirmeNewTickettoggle()
  }

  // **********Ticket History *********************
  const [historyModal, setHistoryModal] = useState(false)
  const historyToggle = () => {
    setHistoryModal(!historyModal)
  }

  // **********Ticket Data and File *********************
  const [selectedFile, setSelectedFile] = useState()
  const [dataFileModal, setDataFileModal] = useState(false)
  const dataFileToggle = () => {
    setDataFileModal(!dataFileModal)
  }

  // **********Ticket Control *********************
  const [ticketControlled, setTicketControlled] = useState()

  const [viewTicketModal, setViewTicketModal] = useState(false)
  const ToggleviewTicket = () => {
    setViewTicketModal(!viewTicketModal)
  }

  // ********view New Ticket Modal**************
  const [viewNewTicketModal, setViewNewTicketModal] = useState(false)
  const ToggleviewNewTicket = () => {
    setViewNewTicketModal(!viewNewTicketModal)
  }
  const [newTicketData, setNewTicketData] = useState([])

  // ********** update Permission *********************
  const updatePermission = item => {
    if (!item.TransferTo) {
      return item.CreatorID === localStorage.getItem("id")
    } else if (
      item.TransferTo === "16" ||
      item.TransferTo === "17" ||
      item.TransferTo === "18"
    ) {
      return (
        userPermissions[`R${item.TransferTo}`]?.P === "1" ||
        userPermissions[`R${item.TransferTo}`]?.P === "2" ||
        userPermissions[`R${item.TransferTo}`]?.P === "3" ||
        userPermissions[`R${item.TransferTo}`]?.P === "4"
      )
      // for RD0 Issuer
    } else if (item.TransferTo === "200") {
      return item.CreatorID === localStorage.getItem("id")
    } else {
      return true
    }
  }

  // **************** pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: pStageID,
      Page: page,
    }
    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/InsTicketsList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-4)
        setTickets(array)
      })
      .catch(err => console.log("error------>", err))
  }, [editing])

  return (
    <div className="table-responsive">
      {(userPermissions.R2.G === "1" || userPermissions.R4.G === "1")&&(
        <Button
          onClick={ConfirmeNewTickettoggle}
          className=" d-flex ms-auto mx-2 mt-3 justify-content-end font-size-11 fw-bold color-primary "
        >
          + New Ticket
        </Button>
      )}

      {/******************* table section**************************/}
      {tickets.length > 0 && (
        <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
          <thead>
            <tr>
              {/* <th scope="col" style={{ width: "20px" }}>
              #
            </th> */}
              <th scope="col">Id</th>
              <th scope="col">Type</th>
              <th scope="col">Description</th>
              <th scope="col">Code</th>
              <th scope="col">Reference NO</th>
              <th scope="col">Created By</th>
              <th scope="col">Updated By</th>
              <th scope="col">Degree</th>

              <th scope="col">RD5 Status</th>
              <th scope="col">Activity</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((item, key) => (
              <tr key={key} style={{ position: "relative" }}>
                <td>{item.TicketID}</td>
                <td>
                  <span>
                    <i
                      id={`category-${item.TicketID}`}
                      className={
                        item.StageCategory === "Design"
                          ? "fas fa-pencil-ruler"
                          : "fas fa-map-marked-alt"
                      }
                    />

                    <UncontrolledTooltip
                      // autohide={true}
                      placement="bottom"
                      target={`category-${item.TicketID}`}
                    >
                      {item.StageCategory}
                    </UncontrolledTooltip>
                  </span>
                </td>

                <td style={{ position: "relative" }}>
                  <span
                    onMouseOver={() => {
                      setIsHovering(true)
                      setHoveringTicket(item.TicketID)
                    }}
                    onMouseOut={() => {
                      setIsHovering(false)
                      setHoveringTicket(null)
                    }}
                    style={{ cursor: "pointer" }}
                    className="my-auto"
                  >
                    {item.Description?.slice(0, 9)}
                    {item.Description?.length > 9 && " ..."}
                  </span>
                </td>
                <td>{item.Code}</td>
                <td>{item.ReferenceNo}</td>
                <td>{item.CreatorName}</td>
                <td>{item.UpdaterName}</td>
                <td>{item.DegreeName}</td>
                <td>{item.RD5Status}</td>
                <td>
                  {item.LastUpdate?.split(" ")[0] ||
                    item.CreatedAt?.split(" ")[0]}
                </td>
                <td>
                  <UncontrolledDropdown className="btn-group bg-primary">
                    <button
                      onClick={() => {
                        setTicketControlled(item)
                        ToggleviewTicket()
                      }}
                      className="btn btn-primary btn-sm dropdown-toggle"
                    >
                      Update
                    </button>
                    <DropdownToggle
                      tag="a"
                      to="#"
                      className="card-drop"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={() => {
                          setTicketControlled(item)
                          historyToggle()
                        }}
                      >
                        View Ticket History
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>

                {isHovering &&
                  hoveringTicket === item.TicketID &&
                  item.Description?.length > 9 && (
                    <p
                      style={{
                        position: "absolute",
                        top: "70%",
                        left: "15%",
                        zIndex: "10",
                        backgroundColor: "#fbfcd4",
                        border: "1px solid black",
                        borderRadius: "5px",
                        padding: "0 5px",
                      }}
                    >
                      {item.Description}
                    </p>
                  )}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* *********NewTicketModal******** */}
      {newTicketModal && (
        <NewTicketModal
          newTicketToggle={newTicketToggle}
          newTicketModal={newTicketModal}
          editing={editing}
          setEditing={setEditing}
          type={"Inspection"}
          ProjectID={ProjectID}
        />
      )}

      {/* *********confirm New Ticket Action action******** */}
      {ConfirmNewTicketModel && (
        <ConfirmingAction
          confirmFunc={confirmFunc}
          action={"warning"}
          Confirmetoggle={ConfirmeNewTickettoggle}
          ConfirmeModel={ConfirmNewTicketModel}
          massege={
            "Are you sure that you want to add ticket and send it to the client immediately ?"
          }
        />
      )}

      {/*****************view Ticket***********************/}
      {viewTicketModal && (
        <ViewTicket
          ToggleviewTicket={ToggleviewTicket}
          viewTicketModal={viewTicketModal}
          setViewTicketModal={setViewTicketModal}
          ticketControlled={ticketControlled}
          editing={editing}
          setEditing={setEditing}
          updatePermission={updatePermission}
          setAuth={setAuth}
          setNewTicketData={setNewTicketData}
          ToggleviewNewTicket={ToggleviewNewTicket}
          viewNewTicketModal={viewNewTicketModal}
        />
      )}

      {/***************viewNewTicket*****************/}
      {viewNewTicketModal && (
        <ViewTicket
          ToggleviewTicket={ToggleviewNewTicket}
          viewTicketModal={viewNewTicketModal}
          ticketControlled={newTicketData}
          editing={editing}
          setEditing={setEditing}
          updatePermission={updatePermission}
          setAuth={setAuth}
          setNewTicketData={setNewTicketData}
          ToggleviewNewTicket={ToggleviewNewTicket}
          viewNewTicketModal={viewNewTicketModal}
          ProjectID={ProjectID}
        />
      )}

      {/**************** Ticket History ****************/}
      {historyModal && (
        <InspectionTicketHistory
          historyToggle={historyToggle}
          ticketControlled={ticketControlled}
          dataFileToggle={dataFileToggle}
          setSelectedFile={setSelectedFile}
          setAuth={setAuth}
        />
      )}

      {/**************** Data and File View ****************/}
      {dataFileModal && (
        <InspectionDataFileView
          dataFileToggle={dataFileToggle}
          ticketControlled={ticketControlled}
          selectedFile={selectedFile}
          editing={editing}
          setEditing={setEditing}
          setAuth={setAuth}
        />
      )}

      {tickets.length > 1 && (
        <Row>
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </Row>
      )}
    </div>
  )
}

export default InspectionRelatedTickets
