import React, { useEffect, useState } from "react"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { convertPermission } from "permissionUtils"
import LoadingInsider from "common/LoadingInsider"
import RD7Summary from "./RD7Summary"
import Accurate from "./Accurate"

const RD7Review = props => {
  const history = useHistory()

  const stageID = props.match.params.stageID
  const selectedItem = props.location.state?.selectedItem
  const IsAreaManager = props.location.state?.IsAreaManager
  console.log("selectedItem from RD7Review",selectedItem);

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const [answers, setAnswers] = useState([])
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD7/GetRD7StageAnswers.php", {
        ...formData,
        PStageID: stageID,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("Answers res", Object.entries(res.data))
        let array = Object.entries(res.data)
        array.splice(-1)
        let dataAnswers = array.map(item => item[1])
        setAnswers(dataAnswers)
      })
      .catch(err => console.log(err))

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/show_rd7_all.php", {
        ...formData,
        StageID: "12",
        PStageID: stageID,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("Questions res", Object.entries(res.data))
        let dataQuestions = Object.entries(res.data).map(item => item[1])
        // console.log("dataQuestions", dataQuestions)
        setQuestions(dataQuestions)
      })
      .catch(err => console.log(err))
  }, [])

  const [questionAndAnswersPage, setQuestionAndAnswersPage] = useState([])


  useEffect(() => {
    setQuestionAndAnswersPage(
      answers.map((item, i) => {
        let A = item
        let Q = questions.filter(question => question.QID === item.QID)[0]
        return { A, Q, order: i + 1 }
      })
    )
  }, [answers, questions])

  // ************************ Rejected Questions ******************************
  const [rejectedQuestions, setRejectedQuestions] = useState([])
  //   console.log("rejectedQuestions", rejectedQuestions)

  const rejectedQuestionsHandler = id => {
    if (rejectedQuestions.includes(id)) {
      setRejectedQuestions(rejectedQuestions.filter(item => item !== id))
    } else {
      setRejectedQuestions([...rejectedQuestions, id])
    }
  }

  //   ************************** Reject Handler ********************************
  const approveHandler = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: stageID,
      Approve: "1",
    }

    // console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/ADDRD7Reject.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          history.push("/rd7-reports")
        }
      })
      .catch(err => console.log(err))
  }

  //   ************************** Reject Handler ********************************
  const rejectHandler = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: stageID,
      RD7RejectArray: rejectedQuestions,
    }


    axios
      .post("https://rd0.cpvarabia.com/api/RD7/ADDRD7Reject.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          history.push("/rd7-reports")
        }
      })
      .catch(err => console.log(err))
  }

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R21.P !== "1" &&
    userPermissions.R21.P !== "2" &&
    userPermissions.R21.P !== "3" &&
    userPermissions.R21.P !== "4" &&
    userPermissions.R21.G !== "1" &&
    IsAreaManager !== true
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="RD7 Review" />

          {questionAndAnswersPage.length === 0 ? (
            <LoadingInsider type="spin" color="gray" />
          ) : (
            <div>
              {questionAndAnswersPage.map((item, i) => {
                return (
                  <Card
                    key={i}
                    style={{
                      // backgroundColor: "#dedee5",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <CardBody>
                      <CardTitle>
                        <input
                          value={item.Q?.QID}
                          type="checkbox"
                          onClick={e => {
                            //   console.log("---------->", e.target.value)
                            rejectedQuestionsHandler(e.target.value)
                          }}
                          className="form-check-input mx-2 border-primary"
                        />
                        {item.order}- {item.Q?.QTitle}
                        {/* {i + 1}- {item.Q?.QTitle} */}
                      </CardTitle>

                      {item.A.MissingData === "1" ? (
                        <ListGroup>
                          <ListGroupItem>{item.Q?.MissingData}</ListGroupItem>
                        </ListGroup>
                      ) : item.A.AnswerType === "Boolean" ||
                        item.A.AnswerType === "Radio" ||
                        (item.A.AnswerType === "Dropdown" &&
                          item.Q?.Single === "1") ? (
                        <ListGroup>
                          <ListGroupItem>{item.A.OptionTitle[0]}</ListGroupItem>
                        </ListGroup>
                      ) : item.A.AnswerType === "Dropdown" &&
                        item.Q?.Single === "0" ? (
                        <ListGroup>
                          {item.A.Answers.map((option, index) => (
                            <ListGroupItem key={index}>
                              <p>Answer: {option}</p>
                              <p>RSelect: {item.A.RSelect[index]}</p>
                              {item.A.CSelect[index] && (
                                <p>CSelect: {item.A.CSelect[index]}</p>
                              )}
                              {item.A.Term[index] && (
                                <p>Term: {item.A.Term[index]}</p>
                              )}
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      ) : item.A.AnswerType === "CheckBox" ? (
                        <ListGroup>
                          {item.A.OptionTitle.map((option, index) => (
                            <ListGroupItem key={index}>{option}</ListGroupItem>
                          ))}
                        </ListGroup>
                      ) : (
                        // for all other questions (text, integer)
                        <ListGroup>
                          <ListGroupItem>{item.A.Answers[0]}</ListGroupItem>
                        </ListGroup>
                      )}
                    </CardBody>
                  </Card>
                )
              })}

              <FormGroup className="d-flex ms-auto col-3 my-4 justify-content-between">
                {IsAreaManager === true && (
                  <Button
                    className="bg-danger"
                    disabled={
                      rejectedQuestions.length > 0 ||
                      selectedItem.Status === "12"
                    }
                    onClick={approveHandler}
                  >
                    Approve
                  </Button>
                )}

                <Button
                  className="bg-danger"
                  disabled={
                    rejectedQuestions.length === 0 ||
                    selectedItem.Status === "12"
                  }
                  onClick={rejectHandler}
                >
                  Submit Rejected Questions
                </Button>
              </FormGroup>
            </div>
          )}

          {(userPermissions.R21.P === "1" ||
            userPermissions.R21.P === "2" ||
            userPermissions.R21.P === "3" ||
            userPermissions.R21.P === "4" ||
            userPermissions.R21.G === "1") && (
            <RD7Summary setAuth={setAuth} selectedItem={selectedItem} />
          )}
          {selectedItem.Status === "9" &&
            (userPermissions.R21.P === "1" ||
              userPermissions.R21.P === "2" ||
              userPermissions.R21.P === "3" ||
              userPermissions.R21.P === "4" ||
              userPermissions.R21.G === "1") && (
              <Accurate setAuth={setAuth} selectedItem={selectedItem} />
            )}
        </Container>
      </div>
    )
  }
}

export default RD7Review
