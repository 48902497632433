import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import axios from "axios"
import Select from "react-select"
import { useFormik } from "formik"
import ApprovedMultiselect from "../MangedApprove/ApprovedMultiselect"

function DelegationMan({
  delegationManModal,
  toggledelegationMan,
  delegationData,
}) {
  console.log("delegationData", delegationData)
  const [users, setUsers] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://rd0.cpvarabia.com/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const { values, handleSubmit, errors, touched, setFieldValue } = useFormik({
    enableReinitialize: true,

    initialValues: {
      CPV: delegationData&&delegationData.CPV?.split(","),
      subContractor: delegationData && delegationData.SubContractor?.split(","),
    },

    validate: values => {
      let errors = {}

      if (!values["CPV"]) {
        errors["CPV"] = "required"
      }
      if (!values["subContractor"]) {
        errors["subContractor"] = "required"
      }
      return errors
    },

    onSubmit: values => {
      console.log("values", values)
      let formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        SubContractor: values["subContractor"].toString(),
        CPV: values["CPV"].toString(),
      }
      axios
        .post("https://rd0.cpvarabia.com/api/RD6Delegate.php", formData)
        .then(
          res => (console.log(res),toggledelegationMan())
        )
        .catch(err => console.log(err))
    },
  })

  const getValue = (Options, initialValue) => {
    if (Options) {
      return Options.filter(
        option => initialValue && initialValue.indexOf(option.value) >= 0
      )
    } else {
      return props.isMulti ? [] : ""
    }
  }

  //   const handleSubmit = () => {
  //     let formData = {
  //       PerToken: localStorage.getItem("token"),
  //       PerUserID: localStorage.getItem("id"),
  //       PerRoleID: localStorage.getItem("userRoleID"),
  //       Action: 2,
  //       type: type,
  //       ApprovedModels: approvedModel,
  //     }
  //     axios
  //       .post("https://rd0.cpvarabia.com/api/AutoApprove.php", formData)
  //       .then(res => {
  //         toggledelegationMan()
  //       })
  //       .catch(err => console.log(err))
  //   }

  return (
    <React.Fragment>
      <Modal
        isOpen={delegationManModal}
        toggle={toggledelegationMan}
        className="modal-lg"
      >
        <ModalHeader toggle={toggledelegationMan}>Delegation Man</ModalHeader>
        <Form onSubmit={handleSubmit} className="col-12">
          <ModalBody>

            <ApprovedMultiselect
              options={users}
              title={"CPV"}
              name={"CPV"}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            //   defaultValue={delegationData && delegationData?.CPV?.split(",")}
            />
            <ApprovedMultiselect
              options={users}
              title={"Sub-Contractor"}
              name={"subContractor"}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            //   defaultValue={}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggledelegationMan}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              save
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default DelegationMan
