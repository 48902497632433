import axios from "axios"
import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { useHistory } from "react-router-dom"

const ReportsHistory = ({ reportHistoryToggle, selectedReport, setAuth }) => {

  const [modal, setModal] = useState(true)
  const toggle = () => {
    reportHistoryToggle()
  }

  const onClose = () => {
    reportHistoryToggle()
  }

  const history = useHistory()

  const [stages, setStages] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: selectedReport.ProjectID,
      StageID: "10",
    }

    axios
      .post("https://rd0.cpvarabia.com/api/PStageIDHistoryList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        setStages(array)
      })
      .catch(err => console.log(err))
  }, [])

  const clickedHandler = stage => {
    history.push({
      pathname: `/project-Answers/${stage.PStageID}`,
      state: { stage },
    })
  }

  return (
    <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
      <ModalHeader>
        Stage Reports History
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th scope="col" style={{ width: "20px" }}>
                  #
                </th>
                <th scope="col">Report</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Responsible</th>
                <th scope="col">Version</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            <tbody>
              {stages.map((stage, index) => (
                <tr key={index}>
                  <td>
                    <input
                      // value={item.Code}
                      type="checkbox"
                      className="form-check-input  p-1 border-primary"
                    />
                  </td>

                  <td>{stage.PStageID}</td>

                  <td>{stage.Status}</td>

                  <td>{stage.StageDate}</td>

                  <td>
                    {stage.FirstName} {stage.LastName}
                  </td>

                  <td>
                    {stage.Rev?.trim().length === 1
                      ? `0${stage.Rev}`
                      : stage.Rev}
                  </td>

                  <td>
                    <UncontrolledDropdown className="btn-group bg-primary">
                      <button
                        onClick={() => {
                          clickedHandler(stage)
                        }}
                        className="btn btn-primary btn-sm dropdown-toggle"
                      >
                        View
                      </button>
                      <DropdownToggle
                        tag="a"
                        to="#"
                        className="card-drop"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem>
                          <a
                            // href={`https://rd0.cpvarabia.com/uploads/reports/RD5_Reports/RD5Report.php?RD5T=${stage.Token}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-dark"
                          >
                            Export
                          </a>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ReportsHistory
