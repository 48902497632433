import React, { useEffect, useState } from "react"
import { Form, Row, Table, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import axios from "axios"
import Delete from "sharedComponent/Delete"
import PaginationComponent from "common/PaginationComponent"
import Warning from "./Warning"

function ListContent({
  setApprovedModel,
  setAPID,
  type,
  toggleViewApproved,
  change,
  setChange,
}) {
//warning 
let [warning,setWarning]=useState(false)
const toggleWarning=()=>{
  setWarning(!warning)
}

  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)

  let [approved, setApproved] = useState([])
  let [approvedName, setApprovedName] = useState("")
  const [dpopup, setdPopup] = useState(false)
  const toggledPopup = () => {
    setdPopup(!dpopup)
  }
  const [aprrovedId, setApprovedID] = useState()
  let [acitveArray,setActiveArray]=useState([]) 
  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: 1,
      Type: type,
      Page: page,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/AutoApproveList.php", formData)
      .then(res => {
        let array = []
        let data = Object.entries(res.data)
        console.log("res.data", res.data)
        // data.splice(-1)
        // console.log(" data.splice(-1)", data);
        data.forEach(item => item[1]["APID"] && array.push(item[1]))
        setApproved(array)     
        setTotalPages(res.data.TotalPages)
      })
      .catch(err => console.log(err))
  }, [page, change])


  console.log("approved", approved);

  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: 1,
      Type: type,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/AutoApproveList.php", formData)
      .then(res => {
        let array = []
        let active=[]
        let data = Object.entries(res.data)
        // data.splice(-1)
        data.forEach(item => item[1]["APID"] && array.push(item[1]))
        array.forEach(
          item => (item.Active === "1" && active.push(item.APID))
        )
        setActiveArray(active)
        setApprovedModel(active)
      })
      .catch(err => console.log(err))
  }, [change])

  const deleteApproved = () => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: "3",
      APID: aprrovedId,
      Type: type,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/AutoApprove.php", formData)
      .then(res => {
        console.log(res)
        setChange(!change)
        toggledPopup()
      })
      .catch(err => console.log(err))
  }

console.log("activesasds",acitveArray);
  return (
    <>
      <Table hover striped className="  text-center   border-bottom">
        <thead>
          <tr>
            <th scope="col" style={{ width: "20px" }}>
              #
            </th>
            <th scope="col">Name</th>

            <th scope="col">Action</th>
          </tr>
        </thead>
        {/***********table body *****************/}
        <tbody>
          {approved.map((item, key) => {
            return (
              <tr key={item.APID}>
                <td>
                  <input
                    type="checkbox"
                    className="form-control form-check-input p-1 border-primary"
                    name={"approvedmodel" + key}
                    value={item.APID}
                    onClick={e => {
                      acitveArray.includes(e.target.value)
                        ? acitveArray.splice(acitveArray.indexOf(e.target.value),1)
                        : acitveArray.push(e.target.value)
                      return setApprovedModel(acitveArray)
                    }}
                    defaultChecked={item.Active==="1"||acitveArray.includes(item.APID)}
                  />
                </td>

                <td>{item.Name}</td>

                <td>
                  <div className="d-flex justify-content-center gap-3">
                    <div className="text-primary" style={{ cursor: "pointer" }}>
                      <i
                        className="mdi mdi-eye font-size-18"
                        id="viewtooltip"
                        onClick={() => {
                          return toggleViewApproved(), setAPID(item.APID)
                        }}
                      />
                      <UncontrolledTooltip placement="top" target="viewtooltip">
                        view
                      </UncontrolledTooltip>
                    </div>

                    <Link to="#" className="text-danger">
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => (
                          !acitveArray.includes(item.APID)? (setApprovedID(item.APID),
                          toggledPopup(),
                          setApprovedName(item.Name)):(toggleWarning())
                        )}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {dpopup && (
        <Delete
          toggledPopup={() => {
            toggledPopup()
          }}
          deletefunc={() => {
            deleteApproved()
          }}
          item={approvedName}
        ></Delete>
      )}

      {warning&&
      <Warning
        warning={warning}
        toggleWarning={toggleWarning}
        massege={"can’t delete Active Approved model !! "}
      />

      }
      {/* ********************** Pagination ********************************* */}
      {approved.length > 1 && (
        <Row>
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </Row>
      )}
    </>
  )
}

export default ListContent
