import axios from "axios"
import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const Malath = ({
  malathModal,
  toggleMalath,
  type,
  selectedItem,
  setAuth,
  editing,
  setEditing,
}) => {
  const ApprovalFunc = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD7ID: selectedItem.RD7ID,
      MApproval: true,
    }

    console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/RD7Action.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        toggleMalath()
      })
      .catch(err => console.log(err))
  }
  const AssignFunc = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD7ID: selectedItem.RD7ID,
      Massign: true,
    }

    console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/RD7Action.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        toggleMalath()
      })
      .catch(err => console.log(err))
  }
  return (
    /**************delete pop up***************/
    <Modal isOpen={malathModal} toggle={toggleMalath}>
      <ModalBody>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 text-center">
            <button
              onClick={toggleMalath}
              type="button"
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-warning bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-alert-outline font-size-20"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">{type} to Malath?</p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                onClick={() => {
                  toggleMalath()
                }}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  type === "Approval" ? ApprovalFunc() : AssignFunc()
                }}
              >
                Submit
              </button>
            </div>
          </ModalBody>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default Malath
