import axios from "axios"
import { Formik } from "formik"
import { convertPermission } from "permissionUtils"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap"

const ViewComponent = ({
  selectedItem,
  onClose,
  editing,
  setEditing,
  userPermissions,
}) => {
  const history = useHistory()

  const validateHandler = values => {
    let errors = {}

    if (values.TArea <= 0) errors.TArea = "value must be greater than 0"
    if (values.TArea === "") errors.TArea = "required"

    if (values.EMCost <= 0) errors.EMCost = "value must be greater than 0"
    if (values.EMCost === "") errors.EMCost = "required"

    // console.log("errors", errors)
    // console.log("values", values)
    return errors
  }

  const submitHandler = values => {
    // console.log("submitted values", values)

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PEstimationID: selectedItem.PEstimationID,
    }

    if (selectedItem.TArea !== values.TArea) formData.TArea = values.TArea
    if (selectedItem.EMCost !== values.EMCost) formData.EMCost = values.EMCost

    // console.log("formData", formData)

    axios
      .post(
        "https://rd0.cpvarabia.com/api/CostEstimation/EditProjectEstimation.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res.data)
        setEditing(!editing)
      })

    onClose()
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          TArea: selectedItem.TArea,
          EMCost: selectedItem.EMCost,
        }}
        validate={validateHandler}
        onSubmit={submitHandler}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Table bordered className="align-middle">
              <thead>
                <tr>
                  <th>Reference No</th>
                  <td>{selectedItem.ReferenceNo}</td>
                  <th>Owner</th>
                  <td>{selectedItem.OwnerName}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Cost IDI</th>
                  <td>{selectedItem.PCost}</td>
                  <th>Contractor</th>
                  <td>{selectedItem.ConstructorName}</td>
                </tr>

                <tr>
                  <th scope="row">Total Floors Area</th>
                  <td>
                    <Input
                      style={{ maxWidth: "100px" }}
                      type="number"
                      min={0}
                      name="TArea"
                      id="TArea"
                      value={props.values.TArea}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={
                        userPermissions.R12 !== "3" &&
                        userPermissions.R12 !== "4"
                      }
                    />
                    {props.touched.TArea && props.errors.TArea && (
                      <span className="error mx-1 d-flex">
                        {props.errors.TArea}
                      </span>
                    )}
                  </td>
                  <th>Supervisor</th>
                  <td>{selectedItem.SupervisorName}</td>
                </tr>

                <tr>
                  <th scope="row">
                    Est Cost/m<sup>2</sup>
                  </th>
                  <td>
                    <Input
                      style={{ maxWidth: "100px" }}
                      type="number"
                      min={0}
                      name="EMCost"
                      id="EMCost"
                      value={props.values.EMCost}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={
                        userPermissions.R12 !== "3" &&
                        userPermissions.R12 !== "4"
                      }
                    />
                    {props.touched.EMCost && props.errors.EMCost && (
                      <span className="error mx-1 d-flex">
                        {props.errors.EMCost}
                      </span>
                    )}
                  </td>
                  <th>Designer</th>
                  <td>{selectedItem.DesignerName}</td>
                </tr>

                <tr>
                  <th scope="row">Est Project Cost</th>
                  <td>{selectedItem.ECost}</td>
                </tr>

                <tr>
                  <th scope="row">Est Cost Ratio</th>
                  <td>{selectedItem.Ratio}</td>
                </tr>
              </tbody>
            </Table>

            <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
              <a
                //   href={`https://rd0.cpvarabia.com/uploads/reports/RD7_Quotation/quotation.php?RD7T=${selectedItem.Token}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                Export
              </a>
              <Button
                className="bg-primary"
                type="submit"
                disabled={
                  userPermissions.R12 !== "3" && userPermissions.R12 !== "4"
                }
              >
                Submit
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </>
  )
}

const LogComponent = ({ selectedItem }) => {
  const history = useHistory()

  const [logsList, setLogsList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PEstimationID: selectedItem.PEstimationID,
    }

    axios
      .post(
        "https://rd0.cpvarabia.com/api/CostEstimation/ProjectCostEstimationHistory.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        console.log("res =========>", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // // console.log("array", array)
        setLogsList(array)
      })
      .catch(err => console.log(err))
  }, [])
  return (
    <Table bordered className="text-center align-middle">
      <thead>
        <tr>
          <th scope="col" style={{ width: "20px" }}>
            #
          </th>
          <th>Date</th>
          <th>User</th>
          <th>Total Area</th>
          <th>
            Est Cost/m<sup>2</sup>
          </th>
        </tr>
      </thead>
      <tbody>
        {logsList.map((item, i) => (
          <tr key={i}>
            <th>{i + 1}</th>
            <td>{item.CreatedAt}</td>
            <td>{item.UpdaterName}</td>
            <td>{item.TArea || "-"}</td>
            <td>{item.EMCost || "-"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const ViewCost = ({ viewCostToggle, selectedItem, editing, setEditing }) => {
  // console.log("selectedItem", selectedItem)
  const [modal, setModal] = useState(true)
  const toggle = () => {
    viewCostToggle()
  }

  const onClose = () => {
    viewCostToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  return (
    <div>
      <Modal className="modal-lg" isOpen={modal} toggle={toggle}>
        <ModalHeader>
          View Project Cost Estimation
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <div>
            <Tabs id="justify-tab-example" className="mb-3" justify fill>
              <Tab eventKey="view" title="View">
                <ViewComponent
                  selectedItem={selectedItem}
                  onClose={onClose}
                  editing={editing}
                  setEditing={setEditing}
                  userPermissions={userPermissions}
                />
              </Tab>
              <Tab eventKey="log" title="Log">
                <LogComponent selectedItem={selectedItem} />
              </Tab>
            </Tabs>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ViewCost
