import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"

const DeleteUser = ({
  deleteUserToggle,
  selectedUser,
  editing,
  setEditing,
  setAuth,
}) => {
  const history = useHistory()

  // console.log("selectedUser", selectedUser)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    setmodal(!modal)
  }
  const onClose = () => {
    deleteUserToggle()
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      UserID: selectedUser.UserID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/DeleteUser", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // setisSuccessul(true)
        setEditing(!editing)
      })
      .catch(error => {
        console.log(error)
      })
    onClose()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Delete User
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        ></button>
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <Form onSubmit={submitHandler}>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to permanently erase this user(
              {selectedUser.FirstName} {selectedUser.LastName}).
            </p>

            <div className="hstack gap-2 justify-content-center mb-0">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                disabled={userPermissions.R9.P !== "4"}
              >
                Delete Now
              </button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default DeleteUser
