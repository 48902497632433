import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"
import LocationInputs from "./LocationInputs"

const FilterRDDR = ({ filteredValues, setFilteredValues, setAuth }) => {
  // console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }

  const reportTypeOptions = [
    { value: "Simple", label: "Simple" },
    { value: "Complex", label: "Complex" },
  ]

  const casesOptions = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
  ]

  const [users, setUsers] = useState([])
  const [regionList, setRegionList] = useState([])
  const [degreeOptions, setDegreeOptions] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://rd0.cpvarabia.com/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.entries(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user[1].UserID,
          label: `${user[1].FirstName} ${user[1].LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post(
        "https://rd0.cpvarabia.com/api/CostEstimation/RegionList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setRegionList(
          array.map(item => ({ value: item.RCID, label: item.Name }))
        )
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DegreeList.php", {
        ...formData,
        Category: "Design",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setDegreeOptions(
          array.map(item => ({ value: item.DegreeID, label: item.Name }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  // ************************ Cost Range Error ******************************
  const errors = {
    areaFrom: "",
    areaTo: "",

    costFrom: "",
    costTo: "",

    lat: "",
    long: "",
    rad: "",
    location: "",
  }
  //   console.log("errors", errors)

  // Checking location inputs errors
  if (filteredValues.lat || filteredValues.long || filteredValues.rad) {
    if (!filteredValues.lat) {
      errors.lat = "required"
      errors.location = "invalid"
    }
    if (!filteredValues.long) {
      errors.long = "required"
      errors.location = "invalid"
    }
    if (!filteredValues.rad) {
      errors.rad = "required"
      errors.location = "invalid"
    }
  }

  const checkingErrors = (from, to) => {
    // negative value
    if (filteredValues[from] < 0)
      errors[from] = "must be greater than or equal to 0"
    if (filteredValues[to] < 0)
      errors[to] = "must be greater than or equal to 0"

    // only one value is available
    if (filteredValues[from] && !filteredValues[to]) errors[to] = "required"
    if (filteredValues[to] && !filteredValues[from]) errors[from] = "required"

    // the to value is smaller
    if (
      filteredValues[from] &&
      filteredValues[to] &&
      filteredValues[from] * 1 > filteredValues[to] * 1
    ) {
      errors[from] = "must be smaller"
      errors[to] = "must be greater"
    }
  }

  checkingErrors("areaFrom", "areaTo")
  checkingErrors("costFrom", "costTo")

  const [menu, setMenu] = useState(false)
  return (
    <>
      <Row className="mb-3">
        {/* ******************** Reference No *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        {/* ******************** Responsible Person *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={users}
                name="resPerson"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.resPerson}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, resPerson: e })
                }}
                classNamePrefix="select"
                placeholder="Res. Person ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Area Range *************************** */}
        <Col sm={6}>
          <Row>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="areaFrom"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        areaFrom: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Area From ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.areaFrom && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.areaFrom}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="areaTo"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        areaTo: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Area To ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.areaTo && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.areaTo}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {/* ******************** Date Range *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable={true}
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Date ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Region *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={regionList}
                name="region"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.users}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, region: e })
                }}
                classNamePrefix="select"
                placeholder="Region ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Project Cost Range *************************** */}
        <Col sm={6}>
          <Row>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="costFrom"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        costFrom: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Project Cost From ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.costFrom && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.costFrom}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="search-box me-3 col-12">
                <div className="position-relative mb-2">
                  <Input
                    type="number"
                    min={0}
                    name="costTo"
                    onChange={e => {
                      //   console.log(e.target.value)
                      setFilteredValues({
                        ...filteredValues,
                        costTo: e.target.value,
                      })
                    }}
                    className="form-control border-dark"
                    style={{ minHeight: "40px" }}
                    placeholder="Project Cost To ..."
                  />
                  <i className="bx bx-search-alt search-icon " />
                  {errors.costTo && (
                    <span className="error mx-3 d-flex justify-content-end">
                      {errors.costTo}
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        {/* ******************** Report Type *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                // isMulti
                isClearable
                options={reportTypeOptions}
                name="reportType"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.reportType}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, reportType: e })
                }}
                classNamePrefix="select"
                placeholder="Report Type ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Cases *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                // isClearable
                options={casesOptions}
                name="cases"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.cases}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, cases: e })
                }}
                classNamePrefix="select"
                placeholder="Cases ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Degree *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                // isClearable
                options={degreeOptions}
                name="degree"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.degree}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, degree: e })
                }}
                classNamePrefix="select"
                placeholder="Degree ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Location *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Dropdown
                toggle={() => setMenu(!menu)}
                isOpen={menu}
                style={{ height: "40px" }}
                className="my-auto dropdown-toggle me-3 d-flex align-items-center"
              >
                <DropdownToggle
                  tag="a"
                  to="#"
                  className="card-drop d-flex"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <Label
                    onClick={() => setMenu(!menu)}
                    className="my-auto"
                    style={{ color: "grey", cursor: "pointer" }}
                  >
                    Location Inputs ...
                  </Label>
                </DropdownToggle>
                <DropdownMenu
                  style={{ width: "400px" }}
                  className="dropdown-menu-end"
                >
                  <LocationInputs
                    filteredValues={filteredValues}
                    setFilteredValues={setFilteredValues}
                    errors={errors}
                  />
                </DropdownMenu>
              </Dropdown>
            </div>
            {errors.location && (
              <span className="error  mx-3 d-flex justify-content-end">
                {errors.location}
              </span>
            )}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default FilterRDDR
