import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { Formik } from "formik"
import axios from "axios"
import { Link } from "react-router-dom"

import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ProjectHeader from "pages/RD-0/ProjectHeader"
import { forEach } from "lodash"

const RD2Material = props => {
  const [lastReasons, setLastReasons] = useState([])

  let history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const projectId = props.match.params.ProjectID
  const [dataBaseAnswers, setDataBaseAnswers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: projectId,
      Type: "RD2",
    }
    axios
      .post(
        "https://rd0.cpvarabia.com/api/Reports/AllSPQAnswers.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        let answers = array.filter(answer => {
          return answer[1].QID == "1142"
        })
        setDataBaseAnswers(answers)
      })
      .catch(err => console.log(err))
  }, [])

  // const id = localStorage.getItem("id")

  let Answers = [
    { label: "Concrete", value: "129", id: "1803" },
    { label: "Wood", value: "130", id: "1804" },
    { label: "Plastic", value: "130", id: "1805" },
    { label: "Aluminum", value: "130", id: "1806" },
    { label: "New Construction Technology", value: "132", id: "1807" },
  ]

  let initialvalues = {}
  Answers.forEach(answerObject => {
    dataBaseAnswers[0] &&
      dataBaseAnswers[0][1].OptionIDs.includes(answerObject.id) &&
      (initialvalues[answerObject.label] = answerObject.id)
  })

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content mt-5 mx-5">
        <ProjectHeader projectID={props.match.params.ProjectID} />
        <Formik
          enableReinitialize={true}
          initialValues={{ ...initialvalues }}
          onSubmit={(values, actions) => {
            // actions.setSubmitting(false)

            let answer = Object.values(values).flat()

            history.push(`/rd-2/${props.match.params.ProjectID}`, {
              questionAnswer: answer,
            })
          }}
          validate={values => {
            const errors = {}
            let answer = Object.values(values).flat()

            console.log("values", values)

            // Answers.forEach(answerObject => {
            //   dataBaseAnswers[0] &&
            //     dataBaseAnswers[0][1].OptionIDs.includes(answerObject.id) &&
            //     answer.push(answerObject.id)
            // })

            if (answer.length < 1) {
              errors.submited = "choose one reason at least"
            }
            return errors
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <div className="d-flex mt-5">
                <h5 className="mb-4 me-5">Type of innovative material used:</h5>
              </div>
              <div className="ms-5">
                {Answers.map((answer, key) => {
                  // console.log("props",props);
                  return (
                    <FormGroup key={key}>
                      <label htmlFor={answer.SectionName}>
                        <input
                          className="me-1"
                          // onClick={e => (e.target.checked = !e.target.checked)}
                          onClick={(e)=>{
                            e.target.checked?props.setFieldValue(answer.label, e.target.value):props.setFieldValue(answer.label, "")
                          }}
                          type="checkbox"
                          id={answer.label}
                          name={answer.label}
                          // checked={props.values[answer.label] ? true : false}
                          value={answer.id}
                          defaultChecked={
                            dataBaseAnswers[0] &&
                            dataBaseAnswers[0][1].OptionIDs.includes(answer.id)
                          }
                        />
                        {answer.label}
                      </label>
                    </FormGroup>
                  )
                })}
              </div>

              {props.errors.submited ? (
                <p className="d-flex error mx-2 ms-5 ">
                  {props.errors.submited}
                </p>
              ) : null}
              <FormGroup className=" ms-auto col-3 mt-5 ">
                <Button
                  type="submit"
                  className="bg-primary ms-5"
                  disabled={
                    userPermissions.R3.P !== "2" &&
                    userPermissions.R3.P !== "3" &&
                    userPermissions.R3.P !== "4"
                  }
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  )
}

export default RD2Material
