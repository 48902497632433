import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import ViewPQuotation from "./ViewPQuotation"
import UpdateVisits from "./UpdateVisits"
import FilterPQuotations from "./FilterPQuotations"
import SendInstallmentQuotation from "./SendInstallmentQuotation"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import PaginationComponent from "common/PaginationComponent"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { usePrevious } from "common/CustomHooks"

const ProjectQuotations = () => {
  const history = useHistory()

  const [quotations, setQuotations] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ********** Pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // **************** Filter Section ************************
  const initialFilteredValues = {
    quotationNo: [],
    referenceNo: [],
    users: [],
    payment: "",
    projectCostFrom: "",
    projectCostTo: "",
    startDate: "",
    endDate: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  //******************************** View Quotation Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()
  const [selectedInstallment, setSelectedInstallment] = useState()

  const [viewQuotationModal, setViewQuotationModal] = useState(false)
  const viewQuotationToggle = () => {
    setViewQuotationModal(!viewQuotationModal)
  }

  //******************************** Update Visits Toggle********************************//
  const [updateVisitsModal, setUpdateVisitsModal] = useState(false)
  const updateVisitsToggle = () => {
    setUpdateVisitsModal(!updateVisitsModal)
  }

  //******************************** Update Visits Toggle********************************//
  const [sendInstallmentQuotationModal, setSendInstallmentQuotationModal] =
    useState(false)
  const sendInstallmentQuotationToggle = () => {
    setSendInstallmentQuotationModal(!sendInstallmentQuotationModal)
  }

  const prevFilteredValues = usePrevious(filteredValues);

  useEffect(() => {

    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
     
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      QuotationID: filteredValues.quotationNo.join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      Users: filteredValues.users.map(item => item.value).join(","),
      Payment: filteredValues.payment,

      ProjectCostFrom: "",
      ProjectCostTo: "",

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
    }

    if (
      filteredValues.projectCostFrom * 1 >= 0 &&
      filteredValues.projectCostTo * 1 >= 0 &&
      filteredValues.projectCostTo * 1 >= filteredValues.projectCostFrom * 1
    ) {
      formData.ProjectCostFrom = filteredValues.projectCostFrom
      formData.ProjectCostTo = filteredValues.projectCostTo
    }

    axios
      .post("https://rd0.cpvarabia.com/api/ProjectQuotationsView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =========>", res)
        setTotalPages(res.data.TotalPages)
        let array = Object.values(res.data)
        array.splice(-4)
        // console.log("array", array)
        setQuotations(array)
      })
      .catch(err => console.log(err))
  }, [editing, page, filteredValues])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R13.P !== "1" &&
    userPermissions.R13.P !== "2" &&
    userPermissions.R13.P !== "3" &&
    userPermissions.R13.P !== "4" &&
    userPermissions.R13.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Quotations"
              breadcrumbItem="Project Quotations"
            />

            {/* ********************** Filter Section ***************************** */}
            <FilterPQuotations
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />

            {/* ********************** Table Section ***************************** */}
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table header **************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Quotation No</th>
                          <th scope="col">Reference No</th>
                          <th scope="col">Quoted By</th>
                          <th scope="col">Project Cost</th>
                          <th scope="col">Quotation Date</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Visits No</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      {/***********table body *****************/}
                      <tbody>
                        {quotations.map((item, key) => (
                          <tr key={key} style={{ position: "relative" }}>
                            <td>
                              <input
                                // value={item.Code}
                                type="checkbox"
                                onClick={e => {
                                  // console.log("---------->", e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>
                            <td>{item.PQuotationID}</td>
                            <td>{item.ReferenceNo}</td>
                            <td>{item.UserName}</td>
                            <td>{item.PCost}</td>
                            <td>{item.CreatedAt?.split(" ")[0]}</td>
                            <td>
                              <span
                                className={
                                  item.PaymentStatus === "Yes"
                                    ? "badge bg-success"
                                    : item.PaymentStatus === "Partially"
                                    ? "badge bg-warning"
                                    : item.PaymentStatus === "No"
                                    ? "badge bg-danger"
                                    : ""
                                }
                              >
                                {item.PaymentStatus === "Yes"
                                  ? "Paid"
                                  : item.PaymentStatus === "Partially"
                                  ? "Partially Paid"
                                  : "Not Paid"}
                              </span>
                            </td>
                            <td>{item.VCount}</td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewQuotationToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  {(userPermissions.R13.P === "3" ||
                                    userPermissions.R13.P === "4") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        updateVisitsToggle()
                                      }}
                                    >
                                      Update Visits No
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {viewQuotationModal && (
              <ViewPQuotation
                viewQuotationToggle={viewQuotationToggle}
                sendInstallmentQuotationToggle={sendInstallmentQuotationToggle}
                selectedItem={selectedItem}
                setSelectedInstallment={setSelectedInstallment}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {sendInstallmentQuotationModal && (
              <SendInstallmentQuotation
                sendInstallmentQuotationToggle={sendInstallmentQuotationToggle}
                selectedItem={selectedItem}
                selectedInstallment={selectedInstallment}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {updateVisitsModal && (
              <UpdateVisits
                updateVisitsToggle={updateVisitsToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {/* ******************** Pagination *************************** */}
            {quotations.length > 0 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default ProjectQuotations
