import React, { useEffect, useState } from "react"
import {
  Container,
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
} from "reactstrap"
// import { all } from "redux-saga/effects"
import FormUpload from "./FormUpload"

function ImagePreview(props) {
  // add
  const [otherImages, setOtherImages] = useState([])
  const [dropzone, setDropzone] = useState(false)
  const toggleDropzone = () => {
    setDropzone(true)
  }
  // end of add
  const onClose = () => {
    props.toggleViewImages()
  }

  let apiPdf = "https://rd0.cpvarabia.com/uploads/pdftoimages/images/"
  let apiImg = "https://rd0.cpvarabia.com/uploads/reports/RD1/"

  // console.log("props.Images", props.Images)
  const [allImages, setAllImages] = useState([])
  useEffect(() => {
    Array.isArray(props.Images) &&
      setAllImages([...otherImages, ...props.Images])
  }, [otherImages])
  const handleClick = async source => {
    const check = allImages.includes(source)
    if (check) {
      const id = allImages.indexOf(source)
      let newArr = allImages
      newArr.splice(id, 1)
      setAllImages([...newArr])
    } else {
      allImages.push(source)
      setAllImages([...allImages])
    }
  }
  const onsubmit = () => {
    onClose()
    props.imageprops.setFieldValue(props.name, allImages)
  }
  return (
    <Container>
      <Row className="mt-3 d-flex justify-content-around">
        {Array.isArray(props.Images) ? (
          props.Images.map((data, index) => {
            if (props.Images.length >= 1) {
              return (
                data !== false && (
                  <Col ms={5} key={index} className="d-flex justify-content-around"
                  >
                    <div
                      className={`img-card mx-2 ${
                        allImages.includes(data)
                          ? "border border-2 border-primary mt-2 shadow-lg p-1 mb-1 bg-body-blue rounded"
                          : null
                      } `}
                      onClick={() => handleClick(data)}
                    >
                      {props.multiImages == "single" ? (
                        <img
                          style={{ width: "200px", height: "200px" }}
                         alt="Thumbnail"
                          src={apiImg + data}
                        />
                      ) : (
                        <img
                          style={{ width: "200px", height: "200px" }}
                         alt="Thumbnail"
                          src={
                            !data.includes("http") && !props.multiImages
                              ? data.includes("img") && apiPdf + data
                              : !data.includes("http") && props.multiImages
                              ? !data.includes("img") && apiImg + data
                              : data
                          }
                        />
                      )}
                    </div>
                  </Col>
                )
              )
            } else {
              return (
                <Col ms={5} className="d-flex justify-content-around" key={index}>
                  <div
                    className={`img-card mx-2 ${
                      allImages.includes(data)
                        ? "border border-2 border-primary mt-2 shadow-lg p-1 mb-1 bg-body-blue rounded"
                        : null
                    } `}
                    onClick={() => handleClick(data)}
                  >
                    <img
                      style={{ width: "200px", height: "200px" }}
                     alt="Thumbnail"
                      src={!data.includes("http") ? apiImg + data : data}
                    />
                  </div>
                </Col>
              )
            }
          })
        ) : (
          <Col ms={5} className="d-flex justify-content-around">
            <div className={`img-card mx-2 `}>
              <img
                style={{ width: "200px", height: "200px" }}
               alt="Thumbnail"
                src={URL.createObjectURL(props.Images)}
              />
            </div>
          </Col>
        )}
      </Row>
      <FormGroup className="d-flex ms-auto col-6 mt-5 justify-content-around">
        <Button
          onClick={() => {
            onClose()
          }}
          className="bg-primary me-2"
        >
          close
        </Button>

        {Array.isArray(props.Images) && (
          <Button
            type="button"
            onClick={() => {
              onsubmit()
            }}
            className=" me-2 bg-primary"
          >
            Submit
          </Button>
        )}
        <Button onClick={toggleDropzone}>add</Button>
      </FormGroup>
      {dropzone && (
        <FormUpload
          otherImages={otherImages}
          setOtherImages={setOtherImages}
          setAuth={props.setAuth}
        />
      )}
    </Container>
  )
}

export default ImagePreview
