import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Modal,
  ModalBody,
  Label,
  Input,
  Col,
  Form,
  ModalHeader,
  FormGroup,
  Row,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import AnswerHint from "../QuestionBuilder/AnswerHint"
import IconsEditQuestion from "../QuestionBuilder/IconsEditQuestion"
import EditAnswerHint from "../QuestionBuilder/EditAnswerHint"
import { useHistory } from "react-router-dom"
import FileAnswer from "../QuestionBuilder/FileAnswer"

const EditQuestion = ({ questiontoggle, editedQuestion, setAuth }) => {
  // console.log("editedQuestion", editedQuestion)
  const [Operator, setOperator] = useState(
    editedQuestion.Answers[0].operator == "FT" ? "Between" : "Operator"
  ) //for operators

  const history = useHistory()
  //************ Question-Hint ************/
  const [QHint, setQHint] = useState(true)
  const addQHintToggle = () => {
    setQHint(!QHint)
  }

  //************ Question-Help ************/
  const [QHelp, setQHelp] = useState(true)
  const addQHelpToggle = () => {
    setQHelp(!QHelp)
  }

  //************ Question-Other ************/
  const [QOther, setQOther] = useState(true)
  const addQOtherToggle = () => {
    setQOther(!QOther)
  }

  //************ Question-Other ************/
  const [QMissingData, setQMissingData] = useState(true)
  const addQMissingDataToggle = () => {
    setQMissingData(!QMissingData)
  }

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    setmodal(!modal)
  }
  const onClose = () => {
    questiontoggle()
  }

  // ******* add & remove Answers ****************/

  const [answersBackend, setAnswersBackend] = useState(editedQuestion.Answers)

  let startIds = []
  for (let i = 0; i < editedQuestion.Answers.length; i++) {
    startIds.push(i + 1)
  }

  const [answerIds, setAnswerIds] = useState(startIds) //for creation answers ID array

  //*********Input type to controll********/
  let [inputType, setInputtype] = useState(editedQuestion.AnswerType)

  //************** Errors **********//
  const [error, setError] = useState({})

  // *********************Object of Answers from backend***************************** //
  const AnswerObject = {}
  answersBackend.map((answer, index) => {
    AnswerObject[`Answer${index + 1}`] = answer.value || ""
    AnswerObject[`from${index + 1}`] = answer.value.split("|")[0] || ""
    AnswerObject[`to${index + 1}`] = answer.value.split("|")[1] || ""
    AnswerObject[`OperatorOption${index + 1}`] = answer.operator || ""
    AnswerObject[`AnswerHint${index + 1}`] = answer.hint || ""
    AnswerObject[`AnswerId${index + 1}`] = answer.id || ""
    AnswerObject[`OperatorAnswer${index + 1}`] = answer.value || ""
    AnswerObject[`default${index + 1}`] = answer.default || ""
  })

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>Edit Question</ModalHeader>
      <ModalBody className="d-flex row">
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...AnswerObject,
            Type: inputType,
            QuestionTitle: editedQuestion.QTitle,
            QuestionHint: editedQuestion.QHint || "",
            QuestionHelp: editedQuestion.QHelp || "",
            QuestionOther: editedQuestion.Other || "",
            QuestionMissingData: editedQuestion.MissingData || "",
          }}
          validate={values => {
            let errors = {}

            if (!values.QuestionTitle) {
              errors.QuestionTitle = "Question Title is required"
            }

            if (
              inputType != "Number" &&
              inputType != "Text" &&
              inputType != "Date"
            ) {
              if (!values.Answer1) {
                errors.Answer1 = "Question should have one answer at least"
              }
            }

            if (!values.Type) {
              errors.Type = "You should choose type"
            }
            setError(errors)
            // console.log("errors -------->", errors)
            return errors
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false)
            let formData = {}
            if (Object.keys(error).length === 0) {
              formData = {
                PerToken: localStorage.getItem("token"),
                PerUserID: localStorage.getItem("id"),
                PerRoleID: localStorage.getItem("userRoleID"),
                Type: values.Type,
                ParentAID: editedQuestion.ParentAID,
                QID: editedQuestion.QID,
                QuestionTitle: values.QuestionTitle,
                QuestionHint: values.QuestionHint || null,
                QuestionHelp: values.QuestionHelp || null,
                Other: values.QuestionOther || null,
                MissingData: values.QuestionMissingData || null,
                Answers: [],
              }

              if (
                inputType === "Number" ||
                inputType === "Date" ||
                inputType === "Integer"
              ) {
                if (Operator === "Between") {
                  answerIds.forEach(id => {
                    let answer = `F${values[`from${id}`]}|T${values[`to${id}`]}`
                    formData.Answers.push({
                      Value: answer,
                      Operator: "FT",
                      Hint: values[`AnswerHint${id}`] || null,
                      Default: values[`default${id}`] || null,
                      QOID: values[`AnswerId${id}`] || null,
                    })
                  })
                } else if (Operator === "Operator") {
                  answerIds.forEach(id => {
                    formData.Answers.push({
                      Value:
                        // values[`OperatorOption${id}`] +
                        values[`OperatorAnswer${id}`],
                      Operator: values[`OperatorOption${id}`],
                      Hint: values[`AnswerHint${id}`] || null,
                      Default: values[`default${id}`] || null,
                      QOID: values[`AnswerId${id}`] || null,
                    })
                  })
                } else if (inputType == "MultibleSelection") {
                  answerIds.forEach(id => {
                    let answer = [
                      {
                        label: values["Answer" + id],
                        options: [{ value: values["Option" + id] }],
                      },
                    ]
                    formData.Answers.push({
                      Value: answer,
                      Hint: values[`AnswerHint${id}`] || null,
                      Default: values[`default${id}`] || null,
                      QOID: values[`AnswerId${id}`] || null,
                    })
                  })
                } else {
                  answerIds.forEach(id => {
                    let answer = ` ${values[`Operator${id}`]}${
                      values[`Answer${id}`]
                    } `
                    formData.Answers.push({
                      Value: answer,
                      Hint: values[`AnswerHint${id}`] || null,
                      Default: values[`default${id}`] || null,
                      QOID: values[`AnswerId${id}`] || null,
                    })
                  })
                }
              } else {
                answerIds.forEach(id => {
                  formData.Answers.push({
                    Value: values[`Answer${id}`],
                    Hint: values[`AnswerHint${id}`] || null,
                    Default: values[`default${id}`] || null,
                    QOID: values[`AnswerId${id}`] || null,
                  })
                })
              }
              axios
                .post("https://rd0.cpvarabia.com/api/EditQuestion", formData)
                .then(res => {
                  if (
                    res.data.error === true &&
                    res.data.message === "Access denied!"
                  ) {
                    setAuth(true)
                    setTimeout(() => {
                      history.push("/logout")
                      setTimeout(() => {
                        history.push("/login")
                      }, 1000)
                    }, 4000)
                  }

                  console.log(res)
                  // window.location.reload(false)
                })
                .catch(error => {
                  console.log(error)
                })
              // console.log("values ---------->", values)
              // console.log("formData ------->", formData)
              onClose()
            }
          }}
        >
          {props => (
            <Form className="ms-1" onSubmit={props.handleSubmit}>
              {/**************************************question section******************************************************/}

              {/**************** section-Question Title *************/}
              <FormGroup className="d-flex row mt-3">
                <Label for="Question" sm={2}>
                  Question Title :
                </Label>
                <Col className="d-flex" sm={10}>
                  <Input
                    id="Question"
                    name="QuestionTitle"
                    className="me-3 form-control"
                    placeholder="Enter your question ?"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    onClick={() => {
                      if (inputType == "boolean") {
                        props.values["Answer1"] = "Yes"
                        props.values["Answer2"] = "No"
                      }
                    }}
                    value={props.values.QuestionTitle}
                    type="Text"
                  />
                </Col>
                {props.touched.QuestionTitle && props.errors.QuestionTitle ? (
                  <div className="error" style={{ marginInlineStart: "130px" }}>
                    {props.errors.QuestionTitle}
                  </div>
                ) : null}
              </FormGroup>
              {/********************** Question Hint ! **********************/}
              <FormGroup
                className="d-flex row mb-0 mt-1"
                style={{ paddiing: "3px" }}
              >
                <Col sm={2}>
                  <Label className=" mx-2  mt-1" for="QuestionHint">
                    QHint:
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="form-control p-1"
                    value={props.values.QuestionHint}
                    id="QuestionHint"
                    name="QuestionHint"
                    placeholder="Question Hint!"
                    type="Text"
                    disabled={QHint}
                  />
                </Col>
                <Col>
                  <Input
                    onClick={() => {
                      addQHintToggle()
                    }}
                    type="checkbox"
                    id="getQData"
                  />
                  <Label for="getQData" className="ms-1">
                    Edit Hint
                  </Label>
                </Col>
              </FormGroup>

              {/********************** Question Help ! **********************/}
              <FormGroup
                className="d-flex row mb-0 mt-1"
                style={{ paddiing: "3px" }}
              >
                <Col sm={2}>
                  <Label className=" mx-2  mt-1" for="QuestionHelp">
                    Help:
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="form-control p-1"
                    value={props.values.QuestionHelp}
                    id="QuestionHelp"
                    name="QuestionHelp"
                    placeholder="Question Hint!"
                    type="Text"
                    disabled={QHelp}
                  />
                </Col>
                <Col>
                  <Input
                    onClick={() => {
                      addQHelpToggle()
                    }}
                    type="checkbox"
                    id="getData"
                  />
                  <Label for="getData" className="ms-1">
                    Edit Help
                  </Label>
                </Col>
              </FormGroup>

              {/********************** Question Other ! **********************/}
              <FormGroup
                className="d-flex row mb-0 mt-1"
                style={{ paddiing: "3px" }}
              >
                <Col sm={2}>
                  <Label className=" mx-2  mt-1" for="QuestionOther">
                    Other:
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="form-control p-1"
                    value={props.values.QuestionOther}
                    id="QuestionOther"
                    name="QuestionOther"
                    placeholder="Write other text here!"
                    type="Text"
                    disabled={QOther}
                  />
                </Col>
                <Col>
                  <Input
                    onClick={() => {
                      addQOtherToggle()
                    }}
                    type="checkbox"
                    id="getData"
                  />
                  <Label for="getData" className="ms-1">
                    Edit Other
                  </Label>
                </Col>
              </FormGroup>

              {/********************** Question MissingData ! **********************/}
              <FormGroup
                className="d-flex row mb-0 mt-1"
                style={{ paddiing: "3px" }}
              >
                <Col sm={2}>
                  <Label className=" mx-2  mt-1" for="QuestionMissingData">
                    MissingData:
                  </Label>
                </Col>
                <Col sm={3}>
                  <Input
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    className="form-control p-1"
                    value={props.values.QuestionMissingData}
                    id="QuestionMissingData"
                    name="QuestionMissingData"
                    placeholder="Write Missing Data text here!"
                    type="Text"
                    disabled={QMissingData}
                  />
                </Col>
                <Col>
                  <Input
                    onClick={() => {
                      addQMissingDataToggle()
                    }}
                    type="checkbox"
                    id="getData"
                  />
                  <Label for="getData" className="ms-1">
                    Edit Missing Data
                  </Label>
                </Col>
              </FormGroup>

              {/*************************************** section-Answer ***************************************************/}

              {answerIds.map((id, index) => (
                <div key={index}>
                  <div
                    id={"div" + id}
                    className="d-flex row"
                    style={{ marginBottom: "0" }}
                  >
                    <Label for="Answer" sm={2}>
                      Answer{id}:
                    </Label>
                    <Col
                      sm={inputType == "text" ? 12 : 6}
                      className="d-flex align-items-center"
                    >
                      {/**************** if Number or Date *********************/}
                      {inputType == "Number" ||
                      inputType == "Integer" ||
                      inputType == "Date" ? (
                        <div>
                          <Input
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            type="text"
                            name="operator"
                            value={
                              props.values.OperatorOption == "FT"
                                ? "Between"
                                : "Operator"
                            }
                            className="mb-2 me-5 p-1 form-control"
                          />
                          <div className="d-flex align-items-center">
                            {Operator == "Operator" ? (
                              <FormGroup className="d-flex">
                                <Input
                                  value={"User-Input"}
                                  readOnly
                                  className="text-bg-danger"
                                />

                                <Input
                                  id={`OperatorOption${id}`}
                                  name={`OperatorOption${id}`}
                                  type="select"
                                  value={
                                    props.values[`OperatorOption${id}`] || ""
                                  }
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  sm={10}
                                  className="mx-3 p-1 text-center text-color-primary form-control"
                                >
                                  <option disabled value="">
                                    Choose operator
                                  </option>
                                  <option value={"EQ"}>{"="}</option>
                                  <option value={"NQ"}>{"!="}</option>
                                  <option value={"GT"}>{">"}</option>
                                  <option value={"LT"}>{"<"}</option>
                                  <option value={"LQ"}>{"<="}</option>
                                  <option value={"GQ"}>{">="}</option>
                                </Input>
                                <Input
                                  id={`OperatorAnswer${id}`}
                                  name={`OperatorAnswer${id}`}
                                  value={props.values[`OperatorAnswer${id}`]}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  placeholder="Enter your Answer"
                                  type={inputType == "Date" ? "date" : "number"}
                                  className="p-1"
                                />
                              </FormGroup>
                            ) : Operator == "Between" ? (
                              <FormGroup className="d-flex">
                                <Label for="from" className="mx-3">
                                  from
                                </Label>
                                <Input
                                  id={"from" + id}
                                  name={"from" + id}
                                  value={props.values["from" + id]}
                                  placeholder="Enter your Answer"
                                  type={inputType == "Date" ? "date" : "number"}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  className="form-control p-1"
                                />
                                <Label for="to" className="mx-3">
                                  to
                                </Label>
                                <Input
                                  id={"to" + id}
                                  name={"to" + id}
                                  value={props.values["to" + id]}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  placeholder="Enter your Answer"
                                  className="p-1 form-control"
                                  type={inputType == "Date" ? "date" : "number"}
                                />
                              </FormGroup>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : //*********** if file ||dropdown||radio||checkbox ******************//

                      inputType == "Dropdown" ||
                        inputType == "CheckBox" ||
                        inputType == "Radio" ? (
                        <Input
                          id={`Answer${id}`}
                          name={`Answer${id}`}
                          value={props.values[`Answer${id}`]}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          className="form-control"
                          placeholder="Enter your Answer"
                          type="Text"
                        />
                      ) : inputType == "File" ? (
                        <FileAnswer props={props} id={id} />
                      ) : //*********** if boolean **************//

                      inputType == "Boolean" ? (
                        index == 0 ? (
                          <FormGroup className="d-flex">
                            <div className="col-12">
                              <Input
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className="form-control"
                                id={`AnswerYesBoolean`}
                                name={`AnswerYesBoolean`}
                                value={"Yes"}
                                type="Text"
                                readOnly
                              />
                            </div>
                          </FormGroup>
                        ) : (
                          <FormGroup className="d-flex">
                            <div className="col-12">
                              <Input
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                className="form-control"
                                id={`AnswerNoBoolean`}
                                name={`AnswerNoBoolean`}
                                value={"No"}
                                type="Text"
                                readOnly
                              />
                            </div>
                          </FormGroup>
                        )
                      ) : //***************** if text *********************//
                      inputType == "Text" ? (
                        <div className="d-flex col-12">
                          <Col sm={12}>
                            <Input
                              id={`Answer${id}`}
                              name={`Answer${id}`}
                              type="textarea"
                              value={props.values[`Answer${id}`]}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              className="form-control mx-3"
                            />
                          </Col>
                        </div>
                      ) : null}
                    </Col>
                    {/******************* Icons of answer *******************/}

                    <IconsEditQuestion
                      props={props}
                      setAnswerIds={setAnswerIds}
                      answerIds={answerIds}
                      inputType={inputType}
                      id={id}
                    />

                    {/************************* Answer-Hint ***************************/}
                    <EditAnswerHint
                      index={index}
                      id={index + 1}
                      props={props}
                    />
                  </div>
                </div>
              ))}
              {props.touched.Answer1 && props.errors.Answer1 ? (
                <div className="error" style={{ marginInlineStart: "130px" }}>
                  {props.errors.Answer1}
                </div>
              ) : null}

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <Button
                  onClick={() => {
                    onClose()
                  }}
                  className="bg-primary"
                >
                  close
                </Button>
                <Button type="submit" className="bg-primary">
                  Edit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default EditQuestion
