import React, { useState, useEffect, useRef } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  Input,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Dropdown,
} from "reactstrap"
import Switch from "react-switch"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
// import FilterReports from "./FilterReports"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import ViewRD6Report from "./ViewRD6Report"
import ReturnRD6Report from "./ReturnRD6Report"
import TransferRD6Report from "./TransferRD6Report"
import FilterRD6 from "./FilterRD6"
import LoadingInsider from "common/LoadingInsider"
import ApproveMainModal from "../MangedApprove/ApproveMainModal"
import DelegationMan from "./DelegationMan"
import { usePrevious } from "common/CustomHooks"

const RD6Reports = () => {
  const history = useHistory()
  //Manged Approved
  const [menu, setMenu] = useState(false)
  const [approvedModal, setapprovedModal] = useState(false)
  const toggleApproved = () => setapprovedModal(!approvedModal)

  // switch button
  const [Adminchecked, setAdminChecked] = useState(false)
  const [Staffchecked, setStaffchecked] = useState(false)
  const [ReportShow, setReportshow] = useState(true)
  const [allReportsChecked, setAllReportsChecked] = useState(false)
  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Status: Adminchecked === true ? 2 : 1,
      }

      axios
        .post("https://rd0.cpvarabia.com/api/RD6Delegate.php", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          console.log(res)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [Adminchecked])

  const handleAdminChange = () => {
    setAdminChecked(!Adminchecked)
  }

  // Delegation Man
  const [delegationData, setDelegationData] = useState({})
  const [delegationManModal, setdelegationManModal] = useState(false)
  const toggledelegationMan = () => setdelegationManModal(!delegationManModal)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD6Delegate.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setAdminChecked(res.data.Status === "1" ? false : true)
        setDelegationData(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const handleStaffChange = () => {
    setStaffchecked(!Staffchecked)
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Status: Adminchecked === true ? 2 : 1,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD6Delegate.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        console.log(res)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const [reportData, setReportData] = useState([])
  const [editing, setEditing] = useState(false)
  const [cleanReport, setCleanReport] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  //******************************** Return Report Toggle********************************//
  const [returnReportModal, setReturnReportModal] = useState(false)
  const returnReportToggle = () => {
    setReturnReportModal(!returnReportModal)
  }

  //******************************** Transfer Report Toggle********************************//
  const [transferReportModal, setTransferReportModal] = useState(false)
  const transferReportToggle = () => {
    setTransferReportModal(!transferReportModal)
  }

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    reportID: [],
    RD6Status: [],
    costFrom: "",
    costTo: "",
    referenceNo: [],
    designer: [],
    inspector: [],
    category: [],
    startDate: "",
    endDate: "",
    RD3StartDate: "",
    RD3EndDate: "",
    RD3Status: [],
    inprogress: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)
  const prevFilteredValues = usePrevious(filteredValues)
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      ReportID: filteredValues.reportID.join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      InProgress: filteredValues.inprogress.value,
      Category: filteredValues.category.map(item => item.value).join(","),
      RD6Status: filteredValues.RD6Status.map(item => item.value).join(","),

      Designer: filteredValues.designer.map(item => item.value).join(","),
      Inspector: filteredValues.inspector.map(item => item.value).join(","),

      CostFrom: "",
      CostTo: "",

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
      RD3StartDate: filteredValues.RD3StartDate,
      RD3EndDate: filteredValues.RD3EndDate || filteredValues.RD3StartDate,
      RD3Status: filteredValues.RD3Status.map(item => item.value).join(","),
    }

    if (
      filteredValues.costFrom * 1 >= 0 &&
      filteredValues.costTo * 1 >= 0 &&
      filteredValues.costTo * 1 >= filteredValues.costFrom * 1
    ) {
      formData.CostFrom = filteredValues.costFrom
      formData.CostTo = filteredValues.costTo
    }

    if (allReportsChecked) {
      formData.ReportShow = true
    }

    // console.log("formData", formData)
    axios
      .post("https://rd0.cpvarabia.com/api/RD6/RD6List.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        setCleanReport(res.data.cleanReport)
        array.splice(-5)
        setReportData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues, allReportsChecked])

  // ******************* selected Reports ************************
  const [selectedReports, setSelectedReports] = useState([])
  const [selectedReportsStatus, setSelectedReportsStatus] = useState("")
  // console.log("selectedReports==========>", selectedReports)
  // console.log("selectedReportsStatus==========>", selectedReportsStatus)

  const selectedReportsHandler = id => {
    if (selectedReports.includes(id)) {
      setSelectedReports(selectedReports.filter(item => item !== id))
    } else {
      setSelectedReports([...selectedReports, id])
    }
  }

  const errors = { selectedReports: "", selectedStatus: "" }
  selectedReports.map((item, i) => {
    const report = reportData.filter(report => report.PStageID === item)[0]
    // console.log("report", report)
    if (report.Tnum * 1 > 0)
      errors.selectedReports = "some selected reports still have tickets!!"
  })

  if (selectedReports.length > 0 && !selectedReportsStatus)
    errors.selectedStatus = "required status"

  // console.log("errors", errors)

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R1.P !== "1" &&
    userPermissions.R1.P !== "2" &&
    userPermissions.R1.P !== "3" &&
    userPermissions.R1.P !== "4" &&
    userPermissions.R1.G !== "1" &&
    userPermissions.R2.P !== "1" &&
    userPermissions.R2.P !== "2" &&
    userPermissions.R2.P !== "3" &&
    userPermissions.R2.P !== "4" &&
    userPermissions.R2.G !== "1" &&
    userPermissions.R3.P !== "1" &&
    userPermissions.R3.P !== "2" &&
    userPermissions.R3.P !== "3" &&
    userPermissions.R3.P !== "4" &&
    userPermissions.R3.G !== "1" &&
    userPermissions.R4.P !== "1" &&
    userPermissions.R4.P !== "2" &&
    userPermissions.R4.P !== "3" &&
    userPermissions.R4.P !== "4" &&
    userPermissions.R4.G !== "1" &&
    userPermissions.R20.P !== "1" &&
    userPermissions.R20.P !== "2" &&
    userPermissions.R20.P !== "3" &&
    userPermissions.R20.P !== "4" &&
    userPermissions.R20.G !== "1" &&
    userPermissions.R22.P !== "1" &&
    userPermissions.R22.P !== "2" &&
    userPermissions.R22.P !== "3" &&
    userPermissions.R22.P !== "4" &&
    userPermissions.R22.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Reports" breadcrumbItem="RD-6 Reports" />

            {/* ********************** Filter Section ***************************** */}
            <div className="d-flex col-12">
              <FilterRD6
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
              />
              {/* Managed Approve */}
              <Dropdown
                // toggle={() => setMenu(!menu)}
                isOpen={menu}
                style={{ height: "40px" }}
                className="d-inline-block d-flex ms-auto col-1 dropdown-toggle justify-content-end  me-3"
              >
                <DropdownToggle
                  className="btn header-item  "
                  id="page-header-user-dropdown"
                  tag="button"
                  style={{ height: "15px" }}
                >
                  <i className="bx bx-cog h3" onClick={() => setMenu(!menu)} />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem tag="a" href="#" onClick={toggleApproved}>
                    Auto Approved
                  </DropdownItem>

                  <div className="dropdown-divider" />

                  {userPermissions.R3.G === "1" &&
                    userPermissions.R1.G === "1" && (
                      <DropdownItem
                        tag="a"
                        href="#"
                        className="d-flex align-items-center"
                      >
                        <label className="mt-1">Delegation RD6</label>
                        <Switch
                          onChange={handleAdminChange}
                          checked={Adminchecked}
                          onColor="#4458b8"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          height={15}
                          width={42}
                          className="react-switch ms-2"
                          id="material-switch"
                        />
                      </DropdownItem>
                    )}
                  {userPermissions.R3.P === "1" &&
                    userPermissions.R3.P === "2" &&
                    userPermissions.R3.P === "3" && (
                      <DropdownItem
                        tag="a"
                        href="#"
                        className="d-flex align-items-center"
                      >
                        <label className="mt-1">Delegation RD6</label>
                        <Switch
                          onChange={handleStaffChange}
                          checked={Staffchecked}
                          onColor="#4458b8"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          height={15}
                          width={42}
                          className="react-switch ms-2"
                          id="material-switch"
                        />
                      </DropdownItem>
                    )}
                  {Adminchecked && (
                    <DropdownItem
                      tag="a"
                      href="#"
                      className="d-flex align-items-center"
                      onClick={toggledelegationMan}
                    >
                      Delegation Man
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
            {ReportShow && (
              <div className="d-flex justify-content-end align-items-center mb-2 mx-4">
                <Label
                  htmlFor="material-switch"
                  className="mt-1"
                  style={{ fontSize: "14px" }}
                >
                  Reports View
                </Label>
                <Switch
                  onChange={() => setAllReportsChecked(!allReportsChecked)}
                  checked={allReportsChecked}
                  onColor="#24b571"
                  // onColor="#4458b8"
                  // uncheckedIcon={false}
                  // checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  height={20}
                  width={42}
                  className="react-switch ms-2"
                  id="material-switch"
                />
              </div>
            )}

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}

                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Report Id</th>
                          <th scope="col">Category</th>
                          <th scope="col">Stage Id</th>
                          <th scope="col">Reference No.</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">Designer</th>
                          <th scope="col">Inspector</th>
                          <th scope="col">Total Cost</th>
                          <th scope="col">Status</th>
                          <th scope="col">RD3 Notif. Date</th>
                          <th scope="col">ٌRD3 Notif. Status</th>
                          <th scope="col">Progress Bar</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {reportData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                disabled={
                                  item.Tnum * 1 > 0 ||
                                  item.Status === "Approved"
                                }
                                value={item.PStageID}
                                type="checkbox"
                                onClick={e => {
                                  console.log("---------->", e.target.value)
                                  selectedReportsHandler(e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td>{item.RD6ID}</td>

                            <td>
                              <i
                                className={
                                  item.Category === "Residential"
                                    ? "fas fa-home"
                                    : item.Category === "Hotels"
                                    ? "fas fa-hotel"
                                    : item.Category === "less than 23 meters"
                                    ? "fas fa-building"
                                    : item.Category === "Commercial Centers"
                                    ? "fas fa-store"
                                    : item.Category === "Entertainment Services"
                                    ? "fas fa-skating"
                                    : item.Category === "High Rise Towers"
                                    ? "fas fa-building"
                                    : item.Category === "High risk buildings"
                                    ? "fas fa-building"
                                    : item.Category === "Motels"
                                    ? "fas fa-hotel"
                                    : item.Category === "Educational"
                                    ? "fas fa-school"
                                    : item.Category ===
                                      "Gathering buildings Mosques"
                                    ? "fas fa-mosque"
                                    : item.Category ===
                                      "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                                    ? "fas fa-building"
                                    : item.Category ===
                                      "Gathering buildings Sports Premises"
                                    ? "fas fa-running"
                                    : item.Category === "Warehouses"
                                    ? "fas fa-warehouse"
                                    : item.Category === "Industrial"
                                    ? "fas fa-industry"
                                    : item.Category ===
                                      "Furnished Hotel Apartments"
                                    ? "fas fa-hotel"
                                    : item.Category ===
                                      "Gathering buildings Wedding Halls Cinemas Theaters"
                                    ? "fas fa-warehouse"
                                    : item.Category === "Hospitals"
                                    ? "fas fa-hospital-alt"
                                    : item.Category === "Healthcare Centers"
                                    ? "fas fa-hospital"
                                    : item.Category ===
                                      "Telecommunication Towers"
                                    ? "fas fa-broadcast-tower"
                                    : "fas fa-building"
                                }
                                id="categorytooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="categorytooltip"
                              >
                                {item.Category || "N/A"}
                              </UncontrolledTooltip>
                            </td>

                            <td>{item.StageID}</td>

                            <td>{item.ReferenceNo}</td>

                            <td>{item.Type}</td>

                            <td>{item.CreatedAt}</td>

                            <td>{item.DesignName}</td>

                            <td>{item.InspectionName}</td>

                            <td>{item.Cost}</td>

                            <td>
                              <span
                              // className={
                              //   item.Status == "Approved"
                              //     ? "badge bg-success"
                              //     : item.Status == "Rejected"
                              //     ? "badge bg-danger"
                              //     : item.Status == "Pending"
                              //     ? "badge bg-warning"
                              //     : item.Status == "Manual" &&
                              //       "badge bg-primary"
                              // }
                              >
                                {item.Status}
                              </span>
                            </td>
                            <td>{item.RD3Date}</td>
                            <td>
                              <span
                                className={
                                  item.RD3Status == "1"
                                    ? "badge bg-success"
                                    : item.RD3Status == "2"
                                    ? "badge bg-warning"
                                    : "badge bg-danger"
                                }
                              >
                                {item.RD3Status == "1"
                                  ? "Sent"
                                  : item.RD3Status == "2"
                                  ? "Scheduled"
                                  : item.RD3Status == "3"
                                  ? "Error":""}
                              </span>
                            </td>

                            <td>
                              <Progress multi>
                                <Progress
                                  style={{ cursor: "pointer" }}
                                  id={`DesReport-${item.RD6ID}`}
                                  bar
                                  color={
                                    item.DesReport === true
                                      ? "success"
                                      : "danger"
                                  }
                                  value="25"
                                />
                                <Progress
                                  style={{ cursor: "pointer" }}
                                  id={`DesStatus-${item.RD6ID}`}
                                  bar
                                  color={
                                    item.DesReport === true &&
                                    item.DesStatus === "Approved"
                                      ? "success"
                                      : "danger"
                                  }
                                  value="25"
                                />
                                <Progress
                                  style={{ cursor: "pointer" }}
                                  id={`InsReport-${item.RD6ID}`}
                                  bar
                                  color={
                                    item.InsReport === true
                                      ? "success"
                                      : "danger"
                                  }
                                  value="25"
                                />
                                <Progress
                                  style={{ cursor: "pointer" }}
                                  id={`InsStatus-${item.RD6ID}`}
                                  bar
                                  color={
                                    item.InsReport === true &&
                                    item.InsStatus === "Approved"
                                      ? "success"
                                      : "danger"
                                  }
                                  value="25"
                                />
                              </Progress>
                              <UncontrolledTooltip
                                placement="bottom"
                                target={`DesReport-${item.RD6ID}`}
                              >
                                {`${
                                  item.DesReport === true
                                    ? "Answered"
                                    : "Not Answered"
                                } Design Report`}
                              </UncontrolledTooltip>

                              <UncontrolledTooltip
                                placement="bottom"
                                target={`DesStatus-${item.RD6ID}`}
                              >
                                {`Design Status: ${item.DesStatus}`}
                              </UncontrolledTooltip>

                              <UncontrolledTooltip
                                placement="bottom"
                                target={`InsReport-${item.RD6ID}`}
                              >
                                {`${
                                  item.InsReport === true
                                    ? "Answered"
                                    : "Not Answered"
                                } Inspection Report`}
                              </UncontrolledTooltip>

                              <UncontrolledTooltip
                                placement="bottom"
                                target={`InsStatus-${item.RD6ID}`}
                              >
                                {`Inspection Status: ${item.InsStatus}`}
                              </UncontrolledTooltip>
                            </td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  {(userPermissions.R20.P === "3" ||
                                    userPermissions.R20.P === "4") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        transferReportToggle()
                                      }}
                                    >
                                      Transfer To
                                    </DropdownItem>
                                  )}

                                  {item.DesUserID ===
                                    localStorage.getItem("id") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        returnReportToggle()
                                      }}
                                    >
                                      Return Report
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {reportData.length === 0 && (
                      <LoadingInsider type="spin" color="gray" />
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            {/**************** Checked Reports Action ****************/}
            {/* {reportData.length > 0 && (
              <Row className="my-4">
                <Col
                  sm={3}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <Label htmlFor="reports-status">
                    CHANGE STATUS OF SELECTED REPORTS
                  </Label>
                  {errors.selectedReports && (
                    <span className="error mx-1 d-flex ">
                      {errors.selectedReports}
                    </span>
                  )}
                </Col>
                <Col sm={2}>
                  <Input
                    disabled={
                      selectedReports.length < 1 ||
                      errors.selectedReports ||
                      userPermissions.R3.G !== "1"
                    }
                    type="select"
                    name="reports-status"
                    id="reports-status"
                    onChange={e => {
                      // console.log("e", e.target.value)
                      setSelectedReportsStatus(e.target.value)
                    }}
                    className="form-control border-dark"
                  >
                    <option value="">Report Status ...</option>
                    {statusOptions.map((status, i) => (
                      <option key={i} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Input>
                  {errors.selectedStatus && (
                    <span className="error mx-1 d-flex ">
                      {errors.selectedStatus}
                    </span>
                  )}
                </Col>
                <Col sm={1}>
                  <button
                    disabled={
                      selectedReports.length < 1 ||
                      errors.selectedReports ||
                      errors.selectedStatus ||
                      userPermissions.R3.G !== "1"
                    }
                    className="btn btn-primary"
                    onClick={submitStatusHandler}
                  >
                    Apply
                  </button>
                </Col>
              </Row>
            )} */}

            {/* ********************* View Report *************************** */}
            {viewModal && (
              <ViewRD6Report
                viewToggle={viewToggle}
                selectedItem={selectedItem}
                userPermissions={userPermissions}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
                cleanReport={cleanReport}
              />
            )}

            {/* ********************* Return Report *************************** */}
            {returnReportModal && (
              <ReturnRD6Report
                returnReportToggle={returnReportToggle}
                selectedItem={selectedItem}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {/* ********************* Transfer Report *************************** */}
            {transferReportModal && (
              <TransferRD6Report
                transferReportToggle={transferReportToggle}
                selectedItem={selectedItem}
                setAuth={setAuth}
                editing={editing}
                setEditing={setEditing}
              />
            )}

            {reportData.length > 1 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
            <ApproveMainModal
              approvedModal={approvedModal}
              toggleApproved={toggleApproved}
              type={6}
            />
            <DelegationMan
              delegationManModal={delegationManModal}
              toggledelegationMan={toggledelegationMan}
              delegationData={delegationData}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RD6Reports
