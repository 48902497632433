import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  Tooltip,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import AddNewQuotation from "./AddNewQuotation"
import ViewQuotation from "./ViewQuotation"
import DeleteModal from "components/Common/DeleteModal"
import DeleteQuotation from "./DeleteQuotation"
import ChangePayment from "./ChangePayment"
import SendQuotation from "./SendQuotation"
import FilterRD7Quotations from "./FilterRD-7Quotations"
import { useHistory } from "react-router-dom"
import { convertPermission } from "permissionUtils"
import PaginationComponent from "common/PaginationComponent"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { usePrevious } from "common/CustomHooks"

const RD7 = () => {
  const history = useHistory()

  const [quotations, setQuotations] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // ***************** Pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** New Quotation Toggle********************************//
  const [newQuotationModal, setNewQuotationModal] = useState(false)
  const newQuotationToggle = () => {
    setNewQuotationModal(!newQuotationModal)
  }

  //******************************** View Quotation Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewQuotationModal, setViewQuotationModal] = useState(false)
  const viewQuotationToggle = () => {
    setViewQuotationModal(!viewQuotationModal)
  }
  //******************************** Change Payment Toggle********************************//
  const [paymentModal, setPaymentModal] = useState(false)
  const paymentToggle = () => {
    setPaymentModal(!paymentModal)
  }
  //******************************** Delete Toggle********************************//
  const [deleteModal, setDeleteModal] = useState(false)
  const deleteToggle = () => {
    setDeleteModal(!deleteModal)
  }

  //******************************** Send Quotation Toggle********************************//
  const [sendQuotationModal, setSendQuotationModal] = useState(false)
  const sendQuotationToggle = () => {
    setSendQuotationModal(!sendQuotationModal)
  }

  // **************** Filter Section ************************
  const initialFilteredValues = {
    quotationNo: [],
    referenceNo: [],
    users: [],
    category: [],
    payment: "",
    RD7CostFrom: "",
    RD7CostTo: "",
    startDate: "",
    endDate: "",
    paymentStartDate: "",
    paymentEndDate: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)
  const prevFilteredValues = usePrevious(filteredValues);
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
        setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      Page: page,
      QuotationID: filteredValues.quotationNo.join(","),
      ReferenceNo: filteredValues.referenceNo.join(","),
      Users: filteredValues.users.map(item => item.value).join(","),
      Category: filteredValues.category.map(item => item.value).join(","),
      Payment: filteredValues.payment,

      RD7CostFrom: "",
      RD7CostTo: "",

      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate || filteredValues.startDate,
      PaymentStartDate: filteredValues.paymentStartDate,
      PaymentEndDate:
        filteredValues.paymentEndDate || filteredValues.paymentStartDate,
    }

    if (
      filteredValues.RD7CostFrom * 1 >= 0 &&
      filteredValues.RD7CostTo * 1 >= 0 &&
      filteredValues.RD7CostTo * 1 >= filteredValues.RD7CostFrom * 1
    ) {
      formData.RD7CostFrom = filteredValues.RD7CostFrom
      formData.RD7CostTo = filteredValues.RD7CostTo
    }

    // console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/RD7Quotation/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res =========>", res)
        setTotalPages(res.data.TotalPages)
        let array = Object.values(res.data)
        array.splice(-4)
        setQuotations(array)
      })
      .catch(err => console.log(err))
  }, [editing, page, filteredValues])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R14.P !== "1" &&
    userPermissions.R14.P !== "2" &&
    userPermissions.R14.P !== "3" &&
    userPermissions.R14.P !== "4" &&
    userPermissions.R14.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Quotations" breadcrumbItem="RD7 Quotations" />

            {/* ********************** Filter Section ***************************** */}
            <FilterRD7Quotations
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />

            {/* ********************** Add New Quotaion Section ***************************** */}
            <div className="d-flex justify-content-end">
              {(userPermissions.R14.P === "2" ||
                userPermissions.R14.P === "3" ||
                userPermissions.R14.P === "4") && (
                <button
                  className="btn btn-primary"
                  onClick={newQuotationToggle}
                >
                  Add New Quotaion
                </button>
              )}
            </div>

            {/* ********************** Table Section ***************************** */}
            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Quotation No</th>
                          <th scope="col">Category</th>
                          <th scope="col">Reference No</th>
                          <th scope="col">Quoted By</th>
                          <th scope="col">RD7 Total Cost</th>
                          <th scope="col">Quotation Date</th>
                          <th scope="col">Payment Status</th>
                          <th scope="col">Payment Date</th>
                          <th scope="col">TIS fees status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      {/***********table body *****************/}
                      <tbody>
                        {quotations.map((item, key) => (
                          <tr
                            key={key}
                            style={{
                              backgroundColor:
                                quotations.filter(
                                  quote =>
                                    quote.ReferenceNo === item.ReferenceNo
                                ).length > 1
                                  ? "lightblue"
                                  : "",
                            }}
                          >
                            <td>
                              <input
                                // value={item.Code}
                                type="checkbox"
                                onClick={e => {
                                  // console.log("---------->", e.target.value)
                                  // selectedTicketsHandler(e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td>{item.QuotationID}</td>

                            <td>
                              <i
                                className={
                                  item.Category === "Residential"
                                    ? "fas fa-home"
                                    : item.Category === "Hotels"
                                    ? "fas fa-hotel"
                                    : item.Category === "less than 23 meters"
                                    ? "fas fa-building"
                                    : item.Category === "Commercial Centers"
                                    ? "fas fa-store"
                                    : item.Category === "Entertainment Services"
                                    ? "fas fa-skating"
                                    : item.Category === "High Rise Towers"
                                    ? "fas fa-building"
                                    : item.Category === "High risk buildings"
                                    ? "fas fa-building"
                                    : item.Category === "Motels"
                                    ? "fas fa-hotel"
                                    : item.Category === "Educational"
                                    ? "fas fa-school"
                                    : item.Category ===
                                      "Gathering buildings Mosques"
                                    ? "fas fa-mosque"
                                    : item.Category ===
                                      "Business Buildings (Airports, Banks, TV Stations Post Offices)"
                                    ? "fas fa-building"
                                    : item.Category ===
                                      "Gathering buildings Sports Premises"
                                    ? "fas fa-running"
                                    : item.Category === "Warehouses"
                                    ? "fas fa-warehouse"
                                    : item.Category === "Industrial"
                                    ? "fas fa-industry"
                                    : item.Category ===
                                      "Furnished Hotel Apartments"
                                    ? "fas fa-hotel"
                                    : item.Category ===
                                      "Gathering buildings Wedding Halls Cinemas Theaters"
                                    ? "fas fa-warehouse"
                                    : item.Category === "Hospitals"
                                    ? "fas fa-hospital-alt"
                                    : item.Category === "Healthcare Centers"
                                    ? "fas fa-hospital"
                                    : item.Category ===
                                      "Telecommunication Towers"
                                    ? "fas fa-broadcast-tower"
                                    : "fas fa-building"
                                }
                                id="categorytooltip"
                              />
                              <UncontrolledTooltip
                                placement="left"
                                target="categorytooltip"
                              >
                                {item.Category}
                              </UncontrolledTooltip>
                            </td>

                            <td>{item.ReferenceNo}</td>

                            <td>{item.UserName}</td>

                            <td>{item.RD7Total}</td>

                            <td>
                              {item.SentDate?.split(" ")[0] || "Not sent yet"}
                            </td>

                            <td>
                              <span
                                className={
                                  item.Paid === "1"
                                    ? "badge bg-success"
                                    : item.Paid === "0"
                                    ? "badge bg-danger"
                                    : ""
                                }
                              >
                                {item.Paid === "1"
                                  ? "Paid"
                                  : item.Paid === "0"
                                  ? "Not Paid"
                                  : ""}
                              </span>
                            </td>

                            <td>{item.PaymentDate?.split(" ")[0]}</td>

                            <td>
                              <span
                                className={
                                  item.ProjectPaymentStatus === "1"
                                    ? "badge bg-success"
                                    : item.ProjectPaymentStatus === "0"
                                    ? "badge bg-danger"
                                    : ""
                                }
                              >
                                {item.ProjectPaymentStatus === "1"
                                  ? "Paid"
                                  : item.ProjectPaymentStatus === "0"
                                  ? "Not Paid"
                                  : ""}
                              </span>
                            </td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <button
                                  onClick={() => {
                                    setSelectedItem(item)
                                    viewQuotationToggle()
                                  }}
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                >
                                  View
                                </button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  {(userPermissions.R14.P === "3" ||
                                    userPermissions.R14.P === "4") &&
                                    item.Paid === "0" &&
                                    item.Sent === "1" && (
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedItem(item)
                                          paymentToggle()
                                        }}
                                      >
                                        Change Payment Status
                                      </DropdownItem>
                                    )}
                                  {userPermissions.R14.P === "4" && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        deleteToggle()
                                      }}
                                    >
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>

            {newQuotationModal && (
              <AddNewQuotation
                newQuotationToggle={newQuotationToggle}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {viewQuotationModal && (
              <ViewQuotation
                viewQuotationToggle={viewQuotationToggle}
                sendQuotationToggle={sendQuotationToggle}
                selectedItem={selectedItem}
              />
            )}

            {deleteModal && (
              <DeleteQuotation
                deleteToggle={deleteToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {paymentModal && (
              <ChangePayment
                paymentToggle={paymentToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {sendQuotationModal && (
              <SendQuotation
                sendQuotationToggle={sendQuotationToggle}
                selectedItem={selectedItem}
                setEditing={setEditing}
                editing={editing}
                setAuth={setAuth}
              />
            )}

            {/* ******************** Pagination *************************** */}
            {quotations.length > 0 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default RD7
