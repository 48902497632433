import React from "react"
import { Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import logo from "assets/images/Logo-02.png"

function AboutUs() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="About Us" breadcrumbItem="About Us" />

          {/* ***************** Filter Section ************************* */}
          <Row className="justify-content-start pt-5 ps-5">
            <Col lg="7">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className=" table-nowrap align-middle table-borderless text-start " style={{ fontSize: '16px' }}>
                    <thead>
                      <tr>
                        <th scope="col-6" >Application Name :</th>
                        <td  className="text-center">RDAPP</td>
                      </tr>
                      <tr>
                        <th>Version : </th>
                        <td  className="text-center">V 1.3.1</td>
                      </tr>
                      <tr>
                        <th scope="col" style={{ width: "20px" }}>
                          Code Reference :
                        </th>
                        <td  className="text-center"> Saudi Building Code (SBC) 2018 </td>
                      </tr>
                      <tr>
                        <th scope="col" style={{ width: "20px" }}>
                          Contact Us :
                        </th>
                        <td  className="text-center">
                          
                          <a href="http://www.domapphub.com">RDAPP Offical Website</a>
                        </td>
                      </tr>
                    </thead>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-start ps-5">
            <img
              src={logo}
              className="mt-4"
              style={{ width: "400px", height: "250px", marginRight: "160px"}}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AboutUs
