import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Col, Container, Row, Table } from "reactstrap"
import { Tab, Tabs } from "react-bootstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import LoadingInsider from "common/LoadingInsider"

import StagesTable from "./StagesTable"
import InspectionRelatedTickets from "./RelatedTickets/InspectionRelatedTickets"

const InspectionDetails = props => {
  const history = useHistory()

  const pStageID = props.match.params.pStageID
  // console.log("pStageID", pStageID)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // ********************** Fetching reports data ****************************
  const [editing, setEditing] = useState(false)
  const [reportData, setReportData] = useState()
  // console.log("reportData", reportData)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: pStageID,
    }

    // console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/inspection/InsRInfo.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        setReportData(array[0])
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Inspection Reports"
            breadcrumbItem="Inspection Details"
          />

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {reportData && (
                    <>
                      {/* ****************************** Report Data Section ******************************************* */}
                      <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                        <thead>
                          <tr>
                            <th>Reference No</th>
                            <th>Date</th>
                            <th>Stage</th>
                            <th>Result</th>
                            <th>Area</th>
                            <th>Location</th>
                            <th>Category</th>
                            <th>Classification</th>
                            <th>Last Stage</th>
                            <th>Next Stage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{reportData.ReferenceNo}</td>
                            <td>{reportData.Date?.split(" ")[0]}</td>
                            <td>{reportData.Stage}</td>
                            <td>{reportData.Result}</td>
                            <td>{reportData.Area}</td>
                            <td>{reportData.Location}</td>
                            <td>{reportData.Category}</td>
                            <td>{reportData.Classification}</td>
                            <td>{reportData.LastStage}</td>
                            <td>{reportData.NextStage}</td>
                          </tr>
                        </tbody>
                      </Table>

                      <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                        <thead>
                          <tr>
                            <th>Inspector</th>
                            <th>Owner</th>
                            <th>Contractor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{reportData.Inspector}</td>
                            <td>{reportData.Owner}</td>
                            <td>{reportData.Contractor}</td>
                          </tr>
                        </tbody>
                      </Table>

                      {/********************************* Tabs section **********************************************/}
                      <div className="mt-5">
                        <Tabs className="mb-3" justify fill>
                          {/* <Tab
                            eventKey="inspectionStage"
                            title="Inspection Stage"
                          >
                            <StagesTable
                              pStageID={pStageID}
                              stageID={reportData.StageID}
                              setAuth={setAuth}
                            />
                          </Tab> */}
                          <Tab eventKey="tickets" title="Tickets">
                            <InspectionRelatedTickets
                              pStageID={pStageID}
                              ProjectID={reportData.ProjectID}
                              userPermissions={userPermissions}
                              setAuth={setAuth}
                              editing={editing}
                              setEditing={setEditing}
                            />
                          </Tab>
                        </Tabs>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InspectionDetails
