import React, { useEffect, useState } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Label,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap"
//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import ViewData from "./ViewData"
//import Images
// import missingData from "./data"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import FilterMissingData from "./FilterMissingData"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import AttachmentsModal from "./AttachmentsModal"
import { usePrevious } from "common/CustomHooks"

const MissData = () => {
  const history = useHistory()

  const [missingData, setMissingData] = useState([])
  // console.log("missingData", missingData)

  const [popup, settogglePopup] = useState(false)
  const [MissingReport, setMissingReport] = useState({})
  const ViewDetails = item => {
    settogglePopup(!popup)
    setMissingReport(item)
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // **************** Permissions ****************************
  const [AttachmentModel, setAttachmentModel] = useState(false)
  const Attachmenttoggle = () => {
    setAttachmentModel(!AttachmentModel)
  }

  const [pStageID,setPStageId] = useState("")
  // ************************** Filter section *****************************
  const initialFilteredValues = {
    ReferenceNo: [],
    StartDate: "",
    EndDate: "",
    User: [],
    StageName: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)
  const prevFilteredValues = usePrevious(filteredValues);
  // **************************************
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      
      setPage(1)
    }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Page: page,
      RefIDs: filteredValues.ReferenceNo.join(","),
      StartDate: filteredValues.StartDate,
      EndDate: filteredValues.EndDate || filteredValues.StartDate,
      // UsersIDs: filteredValues.User.map(item => item.value).join(","),
      StageName: filteredValues.StageName,
    }

    if (userPermissions.R3.G === "1") {
      formData.UsersIDs = filteredValues.User.map(item => item.value).join(",")
    } else {
      formData.UsersIDs = localStorage.getItem("id")
    }

    axios
      .post(`https://rd0.cpvarabia.com/api/MissingDataView`, formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        setTotalPages(res.data.TotalPages)
        let resData = Object.values(res.data)

        // Removing page number info from the array
        resData.splice(-4)
        setMissingData(resData)
      })
      .catch(err => {
        console.log(err)
      })
  }, [filteredValues, page])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R3.P !== "1" &&
    userPermissions.R3.P !== "2" &&
    userPermissions.R3.P !== "3" &&
    userPermissions.R3.P !== "4" &&
    userPermissions.R3.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Missing-Data" breadcrumbItem="Missing Data" />

            {/* ***************** Filter Section ************************* */}
            <Row>
              <FilterMissingData
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
              />
            </Row>

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>
                          <th scope="col">Reference No.</th>
                          <th scope="col">Date</th>
                          <th scope="col">By</th>
                          <th scope="col">Stage Name</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {missingData.map((item, key) => (
                          <tr key={key}>
                            <td>
                              <input
                                type="checkbox"
                                onClick={() => {
                                  checkbox(item)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td>
                              <Link to="" className="text-dark">
                                {item.ReferenceNo}
                              </Link>
                            </td>

                            <td>
                              {item.StageDate
                                ? item.StageDate
                                : "not available"}
                            </td>

                            <td>
                              <h5>
                                {item.FirstName} {item.LastName}
                              </h5>
                            </td>
                            <td>
                              <h5>{item.StageName}</h5>
                            </td>

                            <td>
                              <UncontrolledDropdown className="btn-group bg-primary">
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-sm dropdown-toggle"
                                  onClick={() => {
                                    ViewDetails(item)
                                  }}
                                >
                                  View
                                </Button>
                                <DropdownToggle
                                  tag="a"
                                  to="#"
                                  className="card-drop"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    onClick={() => {
                                      setPStageId(item.PStageID)
                                      Attachmenttoggle()
                                    }}
                                  >
                                    View Attachments
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            {/* ***************pop up*************** */}
            {popup && (
              <ViewData
                togglePopup={() => {
                  settogglePopup()
                }}
                MissingReport={MissingReport}
                setAuth={setAuth}
              ></ViewData>
            )}

            {/* ********************** Pagination ********************************* */}
            {missingData.length > 0 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
            {AttachmentModel && (
              <AttachmentsModal
                Attachmenttoggle={Attachmenttoggle}
                AttachmentModel={AttachmentModel}
                pStageID={pStageID}
              />
            )}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default MissData
