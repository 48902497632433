import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, Row, Table } from "reactstrap"

// import "./projectDetails.scss"
import { convertPermission } from "permissionUtils"

const RelatedProjects = ({ project, setAuth }) => {
  // console.log("project", project)

  const history = useHistory()

  const [projectListData, setProjectListData] = useState([])
  const [referenceSorting, setReferenceSorting] = useState("asc")
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedProject, setSelectedProject] = useState(null)

  // console.log("project=============>", project[1])

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Page: page,
      ConstructorID: project[1].ConstructorID,
      MainProjectID: project[1].ProjectID,
      SortBy: "ReferenceNo",
      SortValue: referenceSorting,
    }
    axios
      .post(
        "https://rd0.cpvarabia.com/api/RelatedProjectsByConstructorID",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.entries(res.data)
        setTotalPages(res.data.TotalPages)
        array.splice(-4)

        array = array.filter(
          (item, i) => i === 0 || item[1].ProjectID !== project[1].ProjectID
        )

        // console.log("array=========>", array)
        setProjectListData(array)
      })
      .catch(error => {
        console.log(error)
      })
    // }
  }, [page, project, referenceSorting])
  // console.log("projectListData=======>", projectListData)

  const userID = JSON.parse(localStorage.getItem("id"))

  const createCopyHandler = fProjectId => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      FProjectID: fProjectId,
      TProjectID: project[1].ProjectID,
      UserID: userID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/PStageIDCopy", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res====>", res)
        if (res.data.error === false) {
          history.push({
            pathname: `/SpecialSystem/ProjectID=${project[1].ProjectID}`,
            state: { project: project[1] },
          })
        }
      })
      .catch(err => console.log(err))
  }

  // console.log("project.ProjectID ===============", project[1].ProjectID)
  return (
    <div className="mt-5">
      <Col lg="12">
        <Row>
          <div>
            <h5>
              Contractor same projects :{" "}
              {(projectListData.length >= 1 &&
                projectListData[0][1].ConstructorName) ||
                "N/A"}
            </h5>
          </div>
        </Row>
        <Row>
          <div></div>
          <div className="col-3">
            <select
              className="form-select "
              onChange={e => setReferenceSorting(e.target.value)}
            >
              <option value={"asc"}>Ascending Reference No.</option>
              <option value={"desc"}>Descending Reference No.</option>
            </select>
          </div>
        </Row>
        <Row>
          {/******************* table section**************************/}
          <div className="table-responsive">
            <Table className="project-list-table table-nowrap align-middle table-borderless my-table">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "20px" }}>
                    #
                  </th>
                  <th scope="col">type</th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer", position: "relative" }}
                    onClick={e => {
                      e.preventDefault()
                      $("select[id='sort-refrence']").trigger("click")
                    }}
                  >
                    Reference No.
                    {referenceSorting === "asc" ? (
                      <i className="mdi mdi-arrow-up-bold" />
                    ) : (
                      <i className="mdi mdi-arrow-down-bold" />
                    )}
                  </th>
                  <th scope="col">Owner</th>
                  <th scope="col">Designer</th>
                  <th scope="col">Project Address</th>
                  <th scope="col">Latitude</th>
                  <th scope="col">Longitude</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {/***********table body *****************/}
              <tbody>
                {projectListData.map((item, key) => (
                  <tr
                    key={key}
                    className={
                      item[1].ReferenceNo === project[1].ReferenceNo
                        ? "selected-project"
                        : ""
                    }
                  >
                    <td>
                      <input
                        type="checkbox"
                        // onClick={() => {
                        //   checkbox(item[1])
                        // }}
                        className="form-check-input  p-1 border-primary"
                      />
                    </td>

                    <td>
                      {item[1].type == "Residential Buildings" ? (
                        <i className="fa fa-home ms-2"></i>
                      ) : (
                        <i className="fa fa-sharp fa-hotel  ms-2"></i>
                      )}
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push("project-details", {
                          ProjectID: item[1].ProjectID,
                        })
                      }
                    >
                      {item[1].ReferenceNo}
                    </td>

                    <td>{item[1].OwnerName || "N/A"}</td>
                    <td>{item[1].DesignerOfficeName || "N/A"}</td>
                    <td>{item[1].ProjectAddress || "N/A"}</td>
                    <td>
                      {item[1].AccurateLocation
                        ? item[1].AccurateLocation.split(",")[0]
                        : item[1].ProjectLocation
                        ? item[1].ProjectLocation.split(",")[0]
                        : "N/A"}
                    </td>
                    <td>
                      {item[1].AccurateLocation
                        ? item[1].AccurateLocation.split(",")[1]
                        : item[1].ProjectLocation
                        ? item[1].ProjectLocation.split(",")[1]
                        : "N/A"}
                    </td>

                    <td>
                      <h5>{item[1].LastStage}</h5>
                    </td>

                    <td>
                      <button
                        onClick={() => createCopyHandler(item[1].ProjectID)}
                        disabled={
                          project[1].LastStage ||
                          item[1].ReferenceNo === project[1].ReferenceNo ||
                          !item[1].LastStage ||
                          // permission for edit projects
                          (userPermissions.R7.P !== "3" &&
                            userPermissions.R7.P !== "4")
                        }
                        className={`btn btn-primary btn-sm dropdown-toggle`}
                      >
                        Create a copy
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Row>
        {projectListData.length > 0 && (
          <Row>
            <PaginationComponent
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
            />
          </Row>
        )}
      </Col>
    </div>
  )
}

export default RelatedProjects
