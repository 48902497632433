import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import Select from "react-select"
import { useHistory } from "react-router-dom"

const AddDepartment = ({
  addDepartmentToggle,
  department,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("department", department)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    addDepartmentToggle()
  }
  const onClose = () => {
    addDepartmentToggle()
  }

  // *********************** Fetch users data ******************************** //
  const [users, setUsers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      OrgChart: true,
    }

    axios
      .post("https://rd0.cpvarabia.com/api/MenuUsersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.values(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user.UserID,
          label: `${user.FirstName} ${user.LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  // *********************** Validate handler ******************************** //
  const validateHandler = values => {
    let errors = {}

    if (!values.departmentName) errors.departmentName = "required"
    if (!values.supervisor) errors.supervisor = "required"

    console.log("values", values)
    console.log("errors", errors)
    return errors
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: "Add",
      ParentID: department.OCID,
      Name: values.departmentName,
      Supervisor: values.supervisor,
      Type: values.type.length === 1 && values.type[0] === "on" ? "1" : "0",
    }


    axios
      .post("https://rd0.cpvarabia.com/api/OrgChartAction.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        Add Department
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>

      <ModalBody>
        <Formik
          initialValues={{ departmentName: "", supervisor: "", type: [] }}
          validate={validateHandler}
          onSubmit={submitHandler}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <FormGroup>
                <Label htmlFor="departmentName">Department Name</Label>
                <Input
                  type="text"
                  name="departmentName"
                  id="departmentName"
                  value={props.values.departmentName}
                  onChange={props.handleChange}
                  placeholder="Department Name ..."
                />
                {props.errors.departmentName ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.departmentName}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label htmlFor="supervisor">Supervisor</Label>
                <Select
                  options={users}
                  name="supervisor"
                  id="supervisor"
                  className="react-select-container"
                  onChange={e => {
                    // console.log(e)
                    // setTransferTo(e.value)
                    props.setFieldValue("supervisor", e.value)
                  }}
                  classNamePrefix="select"
                  placeholder="Department Supervisor ..."
                />
                {props.errors.supervisor ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.supervisor}
                  </p>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Input
                  name="type"
                  id="type"
                  type="checkbox"
                  onChange={props.handleChange}
                />
                <Label htmlFor="type" className="mx-2">
                  Sub Contracted
                </Label>
              </FormGroup>

              <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
                <Button className="bg-primary" onClick={onClose}>
                  Cancel
                </Button>
                <Button className="bg-primary" type="submit">
                  Add
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default AddDepartment
