import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import { convertPermission } from "permissionUtils"
import { useHistory } from "react-router-dom"

const RD5ModeratorApproval = ({
  RD5ModeratorApprovalToggle,
  ticketControlled,
  sendRD5Toggle,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("ticketControlled", ticketControlled)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    RD5ModeratorApprovalToggle()
  }
  const onClose = () => {
    RD5ModeratorApprovalToggle()
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  const [degreeList, setDegreeList] = useState([])
  // console.log("degreeList", degreeList)
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Category: "Design",
    }

    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/DegreeList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        array.splice(-1)
        setDegreeList(
          array.map(item => ({ value: item.DegreeID, label: item.Name }))
        )
      })
      .catch(err => console.log(err))
  }, [])

  // *********************** Validate Handler ******************************** //
  const validateHandler = values => {
    let errors = {}

    if (!values.approve) errors.approve = "required"

    if (values.approve === "no" && !values.file && !values.data)
      errors.data = "required data or file"

    console.log("values", values)
    console.log("errors", errors)

    return errors
  }

  // *********************** Submit Handler ******************************** //
  const submitHandler = values => {
    if (values.approve === "yes") {
      console.log("values ==========>", values)
      RD5ModeratorApprovalToggle()
      sendRD5Toggle()
    } else {
      const formData = new FormData()
      formData.append("PerToken", localStorage.getItem("token"))
      formData.append("PerUserID", localStorage.getItem("id"))
      formData.append("PerRoleID", localStorage.getItem("userRoleID"))
      formData.append("UpdaterID", localStorage.getItem("id"))
      formData.append("TicketID", ticketControlled.TicketID)
      formData.append("ApprovalType", "Moderator")

      formData.append("Approval", false)
      formData.append("Data", values.data)
      formData.append("File", values.file)

      for (var pair of formData.entries()) {
        console.log(pair)
      }

      axios
        .post("https://rd0.cpvarabia.com/api/UpdateRD5Ticket", formData)
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          // console.log("res", res)
          setEditing(!editing)
        })
        .catch(error => {
          console.log(error)
        })
      onClose()
    }
  }
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-md">
      <ModalHeader>
        RD5 Moderator Approval
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 pt-4 pb-2 ">
          <Formik
            enableReinitialize={true}
            initialValues={{}}
            validate={validateHandler}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <FormGroup>
                  <Label htmlFor="approve">Approve RD5</Label>

                  <Input
                    type="select"
                    name="approve"
                    id="approve"
                    onChange={props.handleChange}
                  >
                    <option value="">Approve ... ?</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Input>
                </FormGroup>
                {props.errors.approve ? (
                  <p className="error mx-2 d-flex justify-content-end">
                    {props.errors.approve}
                  </p>
                ) : null}

                {props.values.approve === "no" && (
                  <FormGroup>
                    <Label>Received Data</Label>
                    <Input
                      className="mb-3"
                      name="file"
                      type="file"
                      onChange={e =>
                        props.setFieldValue("file", e.target.files[0])
                      }
                      // accept=".pdf,.docx"
                    />
                    <Input
                      name="data"
                      id="data"
                      onChange={props.handleChange}
                      type="textarea"
                      placeholder="enter your data ..."
                    />
                    {props.errors.data ? (
                      <p className="error mx-2 d-flex justify-content-end">
                        {props.errors.data}
                      </p>
                    ) : null}
                  </FormGroup>
                )}

                <FormGroup className="d-flex ms-auto mt-5 col-5 justify-content-around">
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    className="bg-primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="bg-primary"
                    disabled={
                      !(
                        userPermissions?.R19?.P === "1" ||
                        userPermissions?.R19?.P === "2" ||
                        userPermissions?.R19?.P === "3" ||
                        userPermissions?.R19?.P === "4"
                      )
                    }
                  >
                    {props.values.approve === "yes" ? "Send RD5" : "Submit"}
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default RD5ModeratorApproval
