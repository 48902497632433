import { Formik } from "formik"
import * as yup from "yup"
import React, { useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"

const UpdatePasswordModal = ({
  passwordToggle,
  setSuccess,
  setError,
  setAuth,
}) => {
  const history = useHistory()

  const userID = localStorage.getItem("id")
  const [modal, setModal] = useState(true)
  const toggle = () => {
    passwordToggle()
  }

  const onClose = () => {
    passwordToggle()
  }

  const userPasswordSchema = yup.object().shape({
    currentPassword: yup.string().required("required"),
    newPassword: yup.string().required("required"),
    confirmNewPassword: yup
      .string()
      .required("required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })

  const submitHandler = (values, onSubmitProps) => {
    onSubmitProps.resetForm()

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      OldPassword: values.currentPassword,
      NewPassword: values.newPassword,
    }


    axios
      .post(`https://rd0.cpvarabia.com/api/UpdatePassword`, formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        if (res.data.error === false) {
          setSuccess("Your password updated successfully!!")
          setTimeout(() => {
            history.push("/logout")
            setSuccess("")
          }, 1000)
        } else {
          setError(`${res.data.message}, Kindly try again later!`)
          setTimeout(() => {
            setError("")
          }, 4000)
        }
        onClose()
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Update Password</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmNewPassword: "",
            }}
            validationSchema={userPasswordSchema}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="currentPassword">Current Password</Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="currentPassword"
                        value={props.values["currentPassword"]}
                        type="password"
                        className="form-control"
                        id="currentPassword"
                        placeholder="Current Password ..."
                      />
                      {props.touched.currentPassword &&
                      props.errors.currentPassword ? (
                        <p className="error">{props.errors.currentPassword}</p>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="newPassword">New Password</Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="newPassword"
                        value={props.values["newPassword"]}
                        type="password"
                        className="form-control"
                        id="newPassword"
                        placeholder="New Password ..."
                      />
                      {props.touched.newPassword && props.errors.newPassword ? (
                        <p className="error">{props.errors.newPassword}</p>
                      ) : null}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="confirmNewPassword">
                        Confirm New Password
                      </Label>
                      <Input
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="confirmNewPassword"
                        value={props.values["confirmNewPassword"]}
                        type="password"
                        className="form-control"
                        id="confirmNewPassword"
                        placeholder="Confirm New Password ..."
                      />
                      {props.touched.confirmNewPassword &&
                      props.errors.confirmNewPassword ? (
                        <p className="error">
                          {props.errors.confirmNewPassword}
                        </p>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-between">
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    className="bg-primary"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="bg-primary">
                    Update
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default UpdatePasswordModal
