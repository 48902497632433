import React, { useState } from "react"
import { UncontrolledTooltip } from "reactstrap"

import { useHistory } from "react-router-dom"
import axios from "axios"
import InspectionQuestionBuilder from "../InspectionBuilder/InspectionQuestionBuilder"
import Delete from "sharedComponent/Delete"

function InspectionIcons({
  record,
  QuestionData,
  props,
  questions,
  userPermissions,
  setAuth,
}) {
  // console.log("QuestionData", QuestionData)

  const history = useHistory()

  const [qToggle, setqToggle] = useState(false)
  const questiontoggle = () => {
    setqToggle(!qToggle)
  }

  // console.log("record", record)
  const [dpopup, setdPopup] = useState(false)
  const deletefunc = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      QID: record.QID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/inspection/DeleteQuestion.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res)
        setdPopup(!dpopup)
        window.location.reload(false)
      })

    setdPopup(!dpopup)
  }

  const toggledPopup = () => {
    setdPopup(!dpopup)
  }
  const DeleteFun = e => {
    toggledPopup()
  }

  //-----------Edit Question-------------------//

  const [editedQuestion, setEditedQuestion] = useState(null)
  // console.log("editedQuestion", editedQuestion)

  const editQuestionHandler = () => {
    questiontoggle()
    setEditedQuestion(
      QuestionData.filter(question => question.QID == record.QID)
    )
  }
  // console.log("editedQuestion", editedQuestion)
  let answers = questions.filter(question => question.QID == record.QID)[0]
    .Options
  // console.log("answers", answers)

  return (
    <>
      <div className="d-flex justify-content-center">
        {(userPermissions.R2.P === "3" || userPermissions.R2.P === "4") && (
          <div style={{ cursor: "pointer" }} className="text-primary">
            <i
              onClick={editQuestionHandler}
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
            />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </div>
        )}

        {userPermissions.R2.P === "4" && (
          <div
            style={{ cursor: "pointer" }}
            className="text-danger ms-1"
            onClick={e => {
              DeleteFun(e)
            }}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            <UncontrolledTooltip placement="top" target="deletetooltip">
              Delete
            </UncontrolledTooltip>
          </div>
        )}

        {/********icons pop *******/}
        {dpopup && (
          <Delete
            toggledPopup={() => {
              toggledPopup()
            }}
            deletefunc={() => {
              deletefunc()
            }}
            item={"Question"}
          />
        )}
      </div>
      {/*********** popup edit  Question *****/}
      {qToggle && (
        <InspectionQuestionBuilder
          editedQuestion={editedQuestion}
          questiontoggle={questiontoggle}
          props={props}
          answers={answers}
          edit={true}
        />
      )}
    </>
  )
}

export default InspectionIcons
