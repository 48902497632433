import axios from "axios"
import React, { useEffect, useState } from "react"
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap"

const AttachmentsModal = ({ Attachmenttoggle, AttachmentModel, pStageID }) => {
  const [AttachmentData, setAttachmentData] = useState([])
  const [message, setMessage] = useState("")
  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      PStageID: pStageID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/MissingHistory.php", formData)
      .then(res => {
        console.log("res.data", res.data)
        res.data?.message && setMessage(res.data.message)
        let array = []
        Object.entries(res.data).forEach(data => array.push(data[1]))

        setAttachmentData(array)
        console.log("array", array)
      })
      .catch(err => {
        console.log("err.data", err)
      })
  }, [])

  return (
    /**************confirm pop up***************/
    <Modal isOpen={AttachmentModel} toggle={Attachmenttoggle}>
      <ModalHeader>Attachments</ModalHeader>
      {/* <ModalBody> */}
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
            <Table className="text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Updater</th>

                  <th>file</th>
                </tr>
              </thead>
              <tbody>
                {message ? (
                  <tr>
                    <td></td>
                    <h6 className="d-flex justify-content-center">
                      {" "}
                      NO Data yet !
                    </h6>{" "}
                  </tr>
                ) : (
                  AttachmentData.map((item, i) => (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td>{item.UpdaterName}</td>

                      <td>
                        <button>
                          <a
                            href={item.File}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            download
                          </a>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </div>
      <FormGroup className="d-flex ms-auto col-4 mt-5 justify-content-around">
        <Button
          onClick={() => {
            Attachmenttoggle()
          }}
        >
          close
        </Button>
      </FormGroup>
    </Modal>
  )
}

export default AttachmentsModal
