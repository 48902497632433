import React, { useEffect, useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import RD3 from "./RD3"
import ProjectHeader from "pages/RD-0/ProjectHeader"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { convertPermission } from "permissionUtils"
import OtherRD3Section from "./OtherRD3Section"
import { filter } from "lodash"
import LoadingInsider from "common/LoadingInsider"

const RD3AnswerPage = props => {
  const history = useHistory()
  const projectId = props.match.params.ProjectID

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  let SpecialSystem, project

  //--
  const [allValues, setAllValues] = useState([])

  const [activeTab, setActiveTab] = useState("1")
  const [data, setData] = useState([])


  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: "16",
      // ProjectID: projectId
    }

    axios
      .post("https://rd0.cpvarabia.com/api/Sections/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        setData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [submittedReports, setSubmittedReports] = useState([])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <div className="page-content">
      <ProjectHeader projectID={projectId} />

      <h4 className="mb-3">RD-3 Report Answer</h4>
      <Nav tabs fill style={{ width: "80%" }}>
        {data.map((item, key) => {
          return (
            <NavItem key={key}>
              <NavLink
                className={activeTab == key + 1 ? "active" : ""}
                // onClick={() => setActiveTab((key + 1).toString())}
              >
                {item[1].SectionName}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((item, key) => {
          return (
            <TabPane tabId={(key + 1).toString()} key={key}>
              <RD3
                id={item[1].SectionID}
                projectId={projectId}
                SpecialSystem={SpecialSystem}
                submittedReports={submittedReports}
                setSubmittedReports={setSubmittedReports}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                submittedKey={key + 1}
                tabsLength={data.length}
                allValues={allValues}
                setAllValues={setAllValues}
                setAuth={setAuth}
                userPermissions={userPermissions}
              />
            </TabPane>
          )
        })}
      </TabContent>
    </div>
  )
}

export default RD3AnswerPage
