import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Label,
  Input,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import { Formik } from "formik"
import axios from "axios"
import { Link } from "react-router-dom"
import ProjectHeader from "./ProjectHeader"
import { convertPermission } from "permissionUtils"
import Select from "react-select"

const SpecialSystem = props => {
  const [navigate, setnavigate] = useState(false)
  const data = props.location.state?.data

  const [modal, setModal] = useState(false)
  const toggle = () => {
    setModal(!modal)
  }

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  const history = useHistory()
  const id = localStorage.getItem("id")
  let projectID = props.match.params.id
  const [SpecialSystem, setSpecialSystem] = useState({})
  //
  const [dataBaseAnswers, setDataBaseAnswers] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: projectID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD0AllQAnswersView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)

        array.splice(-1)
        let answers = array.filter(answer => {
          // console.log("array",answer[1].QID );
          return answer[1].QID == "238"
        })
        setDataBaseAnswers(answers)
      })
      .catch(err => console.log(err))
  }, [])

  let SPSAnswers = [
    { label: "Piles", id: "437" },
    { label: " Post Tension", id: "438" },
    { label: "Hollowcore Slab", id: "439" },
    { label: "Precast Concrete", id: "440" },
    { label: "Steel Structure", id: "441" },
    { label: "Innovative Material", id: "442" },
    { label: "Timber", id: "443" },
    { label: "More than one code table", id: "444" },
  ]

  let SPS = []

  SPSAnswers.forEach(answerObject => {
    dataBaseAnswers[0] &&
      dataBaseAnswers[0][1].OptionIDs.includes(answerObject.id) &&
      SPS.push(answerObject.id)
  })

  // soil report lab name
  const [soilLabName, setSoilLabName] = useState([])
  const [otherLabName, setOtherLabName] = useState(false)
  const formData = {
    PerToken: localStorage.getItem("token"),
    PerUserID: localStorage.getItem("id"),
    PerRoleID: localStorage.getItem("userRoleID"),
  }
  useEffect(() => {
    axios
      .post("https://rd0.cpvarabia.com/api/SoilList.php", formData)
      .then(res => {
        let array = []
        Object.entries(res.data).forEach(item => {
          array.push(item[1])
        })
        setSoilLabName(array)
      })
      .catch(err => console.log(err))
  }, [])
  let labNameOptions = []
  soilLabName.forEach(item => {
    labNameOptions.push({ value: item.ID, label: item.NameAr })
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <ProjectHeader projectID={data.ProjectID} />
        <Formik
          enableReinitialize={true}
          initialValues={{
            SPS,
            labName: "",
            labNameEn: "",
            labNameAR: "",
          }}
          onSubmit={(values, actions) => {
            // soil lab Name
            if (values.labName || values.labNameEn || values.labNameAR) {
              let formData = {
                PerToken: localStorage.getItem("token"),
                PerUserID: localStorage.getItem("id"),
                PerRoleID: localStorage.getItem("userRoleID"),
                NameEN: values.labNameEn,
                NameAr: values.labNameAR,
                LabID: values.labName,
                ProjectID: data.ProjectID,
              }
              axios
                .post("https://rd0.cpvarabia.com/api/AddSoilC.php", formData)
                .then(res => {
                  let path = `/RDZeroRequirements/ProjectID=${projectID}`
                  history.push(path, {
                    SpecialSystem: SPSdata,
                    project: SPSdata,
                  })
                })
                .catch(err => console.log(err))
            }

            let SPSdata = {
              SpecialSystem: Object.values(values.SPS).flat(),
              ProjectID: props.match.params.id,
              UserID: id,
            }
            setSpecialSystem(SPSdata)
            if (values.SPS && values.SPS.includes("444")) {
              toggle()
            } else if (
              !values.labName &&
              !values.labNameEn &&
              !values.labNameAR
            ) {
              
              let path = `/RDZeroRequirements/ProjectID=${projectID}`
              history.push(path, { SpecialSystem: SPSdata, project: SPSdata })
            }
          }}
          validate={values => {
            otherLabName && (values.labName = "")
          }}
        >
          {props => (
            <Form onSubmit={props.handleSubmit} className="ms-5">
              <div className="ms-2">
                <h5 className=" me-5 mt-4  ">
                  Is there any special system in the data?
                </h5>
                <h6 className="text-warning mb-3">
                  *You can choose more than one choice
                </h6>
              </div>
              <div className="ms-5">
                {SPSAnswers.map((item, key) => (
                  <FormGroup key={key}>
                    <label htmlFor={"SPS"}>
                      <input
                        className="me-1"
                        onClick={e => {
                          e.target.checked
                            ? props.setFieldValue(item.label, e.target.value)
                            : props.setFieldValue(item.label, "")
                        }}
                        onChange={props.handleChange}
                        defaultChecked={
                          dataBaseAnswers[0] &&
                          dataBaseAnswers[0][1].OptionIDs.includes(item.id)
                        }
                        type="checkbox"
                        id={"SPS"}
                        name={"SPS"}
                        value={item.id}
                      />
                      {item.label}
                    </label>
                  </FormGroup>
                ))}
              </div>
              <FormGroup>
                {props.errors.SPS ? (
                  <div className="error  d-flex ms-auto col-3  justify-content-around">
                    {props.errors.SPS}
                  </div>
                ) : null}
              </FormGroup>
              {/* soil Lap Name */}
              {(!data.Soil || data.Soil === null) && (
                <div>
                  <FormGroup className="ms-2 mt-3">
                    <h5 className=" me-5 mt-4 mb-3 ">Soil Report lab name :</h5>
                    <div className="ms-5 d-flex align-items-center">
                      <Select
                        className="col-5  me-4 mb-3"
                        options={labNameOptions}
                        name="labName"
                        isDisabled={otherLabName}
                        styles={{
                          menuPortal: base => ({
                            ...base,
                            zIndex: 9999,
                            marginBottom: "10px",
                          }),
                        }}
                        onChange={e => {
                          otherLabName
                            ? props.setFieldValue("labName", "")
                            : props.setFieldValue("labName", e.value)
                        }}
                      />

                      <label htmlFor="others ">
                        <input
                          type="Checkbox"
                          id="others"
                          onClick={e => {
                            setOtherLabName(!otherLabName)
                          }}
                          checked={otherLabName}
                          className="me-1 "
                        />
                        other lab Name
                      </label>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Row id="LabName" className="ms-5">
                      <Col sm={5}>
                        <Label
                          htmlFor={`labNameEn`}
                          className="me-1 d-flex align-items-center"
                        >
                          <Col sm={3}>Lab Name EN :</Col>
                          <Input
                            onChange={props.handleChange}
                            type="textarea"
                            value={props.values["labNameEn"]}
                            id={`labNameEn`}
                            name={`labNameEn`}
                            disabled={!otherLabName}
                            className="ms-2"
                            style={{ height: "25px" }}
                          />
                        </Label>
                        <Label
                          htmlFor={`labNameAR`}
                          className="me-1 d-flex align-items-center"
                        >
                          <Col sm={3}>Lab Name AR :</Col>
                          <Input
                            onChange={props.handleChange}
                            type="textarea"
                            value={props.values["labNameAR"]}
                            id={`labNameAR`}
                            name={`labNameAR`}
                            disabled={!otherLabName}
                            className="ms-2"
                            style={{ height: "25px" }}
                          />
                        </Label>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              )}

              <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                <Button
                  type="submit"
                  className="bg-primary"
                  disabled={
                    userPermissions.R3.P !== "2" &&
                    userPermissions.R3.P !== "3" &&
                    userPermissions.R3.P !== "4"
                  }
                >
                  Submit
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader className="text-danger">
            {" "}
            <i className="bx bx-confused"></i>More than one building
          </ModalHeader>
          <ModalBody>
            <div>
              {/* <label htmlFor="sectionName">missing Data !!!</label> */}
              {
                <div className="mt-4">
                  <p>
                    <b>Unfortunately,</b>You can’t complete the RD0 report right
                    now you can do it Manualy
                    <b>
                      <i className="bx bx-confused"></i>
                    </b>
                  </p>
                </div>
              }
            </div>
          </ModalBody>
          <FormGroup className="d-flex ms-auto col-3  justify-content-around me-3">
            <Button
              onClick={() => {
                toggle()
              }}
              className="bg-secondary me-2"
            >
              Cancel
            </Button>
          </FormGroup>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default SpecialSystem
