import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

Dashboard
import Dashboard from "../pages/Dashboard/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// //Projects
import ProjectsList from "../pages/Projects/projects-list"
import ProjectsCreate from "../pages/Projects/projects-create"
import ProjectDetails from "pages/Projects/ProjectDetails/projectDetails"
import ProjectStageAnswers from "pages/Projects/ProjectDetails/ProjectStageAnswers"
import CSVProject from "pages/Projects/CSVProject"

// Project Costs
import ProjectCosts from "pages/ProjectCost/ProjectCosts"
import ProjectCostEstimation from "pages/ProjectCost/ProjectCostEstimation"

// Tickets
import Tickets from "pages/Tickets/Tickets"

// Quotations
import RD7 from "pages/Quotations/RD-7/RD-7"
import ProjectQuotations from "pages/Quotations/ProjectQuotations/ProjectQuotations"
import AdditionalVisit from "pages/Quotations/AddtionalVisit/AdditionalVisit"

// Accounting 
import Sadad from "pages/Accounting/Sadad/Sadad"

//Users
import UsersList from "../pages/Users/UserList/userslist"

// Settings
import UserRoles from "pages/Permissions/UserRoles"
import CompanyStructure from "pages/CompanyStructure/CompanyStructure"
import SpecialistStructure from "pages/SpecialistStructure/SpecialistStructure"

//System settings
import SystemSettings from "../pages/SystemSettings/System-settings"

//Stages
import Stages from "../pages/Stages/StagesList/Stageslist"
import ViewStage from "pages/Stages/SectionList/Sectionlist"
import ViewSection from "pages/Stages/QuestionList/Design/DataTable/ViewSection"
import ViewInspectionSection from "pages/Stages/QuestionList/Inspection/DataTable/ViewInspectionSection"

//RD-zero
import SpecialSystem from "pages/RD-0/specialSystem"
import RDZeroRequirements from "pages/RD-0/Requirements"
import ProjectLocation from "pages/RD-0/projectlocation/projectLocation"
import AnswerPage from "pages/RD-0/report-answer/AnswerPage"
import RD0RelatedTickets from "pages/RD-0/report-answer/tickets/RD0RelatedTickets"

// RD-6
import RD6AnswerPage from "pages/RD-6/RD6ReportAnswers/RD6AnswerPage"
import RD6RelatedTickets from "pages/reports/RD6/RD6RelatedTickets"
import SiteVisits from "pages/reports/RD6/SiteVisits/SiteVisits"
// RD-3
import RD3AnswerPage from "pages/RD-3/RD3ReportAnswers/RD3AnswerPage"
import RD3RelatedTickets from "pages/reports/RD3/RD3RelatedTickets"
import SiteVisitsRD3 from "pages/reports/RD3/SiteVisits/SiteVisits"

// RD-7
import RD7AnswerPage from "pages/RD-7/RD7ReportAnswers/RD7AnswerPage"

//RD-1
import Rd1Reasons from "pages/RD-1/RD1-reason"
import RD1AnswerPage from "pages/RD-1/report-answer/RD1-AnswerPage"

//RD-2 
import RD2AnswerPage from "pages/RD-2/report-answer/RD2-AnswerPage"
import RD2Material from "pages/RD-2/RD-2Material"

//RD-DR
import RDDRAnswerPage from "pages/RD-DR/report-answer/RDDR-AnswerPage"

//MissingData
import MissData from "pages/MissingData/Missig-data"

//Reports
import Report from "pages/reports/RD0/Reports"
import RD1Reports from "pages/reports/RD1/RD1Reports"
import RD2Reports from "pages/reports/RD2/RD2Reports"
import RDDRReports from "pages/reports/RD-DR/RD-DRReports"
import RD5Reports from "pages/reports/RD5/RD5Reports"
import InspectionReports from "pages/reports/InspectionReports/InspectionReports"
import InspectionDetails from "pages/reports/InspectionReports/InspectionDetails/InspectionDetails"
import RD6Reports from "pages/reports/RD6/RD6Reports"
import RD3Reports from "pages/reports/RD3/RD3Reports"
import RD7Reports from "pages/reports/RD7/RD7Reports"
import RD7Review from "pages/reports/RD7/RD7Review"

// Report step
import PrivacyATerms from "common/PrivacyATerms"
import AboutUs from "pages/About us/AboutUs"

const authProtectedRoutes = [
  //profile
  { path: "/profile", component: UserProfile },

  //system settings
  { path: "/system-settings", component: SystemSettings },

  //Projects
  { path: "/projects-list", component: ProjectsList },
  { path: "/projects-create", component: ProjectsCreate },
  { path: "/project-details/:ProjectID", component: ProjectDetails },
  { path: "/project-Answers/:stageID", component: ProjectStageAnswers },
  { path: "/csv-project", component: CSVProject },

  // Project Costs
  { path: "/project-costs", component: ProjectCosts },
  { path: "/cost-estimation", component: ProjectCostEstimation },

  //Tickets
  { path: "/tickets", component: Tickets },

  //Quotations
  { path: "/rd-7", component: RD7 },
  { path: "/project-quotations", component: ProjectQuotations },
  { path: "/additional-visit", component: AdditionalVisit },

  // Accounting 
  { path: "/sadad", component: Sadad },
  

  // Users
  { path: "/Users-list", component: UsersList },

  //Settings
  { path: "/roles", component: UserRoles },
  { path: "/company", component: CompanyStructure },
  { path: "/specialist", component: SpecialistStructure },

  // Stages
  { path: "/stages", component: Stages },
  { path: "/view-stage/:StageID", component: ViewStage },
  { path: "/view-stage/:StageID/:SectionID", component: ViewSection },
  {
    path: "/view-stage/:Category/:StageID/:SectionID",
    component: ViewInspectionSection,
  },

  // RD-0
  { path: "/RD-0/ProjectID=:id", component: AnswerPage },
  { path: "/SpecialSystem/ProjectID=:id", component: SpecialSystem },
  { path: "/RDZeroRequirements/ProjectID=:id", component: RDZeroRequirements },
  { path: "/Project-Location/ProjectID=:id", component: ProjectLocation },
  { path: "/RD0Tickets", component: RD0RelatedTickets },

  //about us
  { path: "/AboutUs", component: AboutUs },


  //PrivacyATerms
  { path: "/PrivacyATerms/:id", component: PrivacyATerms },

  // RD-6
  { path: "/rd-6/:ProjectID", component: RD6AnswerPage },
  { path: "/rd-6/:ProjectID/tickets", component: RD6RelatedTickets },
  { path: "/rd-6/:ProjectID/visits", component: SiteVisits },
  // RD-3
  { path: "/rd-3/:ProjectID", component: RD3AnswerPage },
  { path: "/rd-3/:ProjectID/tickets", component: RD3RelatedTickets },
  { path: "/rd-3/:ProjectID/visits", component: SiteVisitsRD3 },

  // RD-7
  { path: "/rd-7/:ProjectID/:RD7ID", component: RD7AnswerPage },

  //RD-1
  { path: "/rd1-reasons/:ProjectID", component: Rd1Reasons },
  { path: "/rd-1/:ProjectID", component: RD1AnswerPage },

  //RD-2
  { path: "/rd2-Material/:ProjectID", component: RD2Material },
  { path: "/rd-2/:ProjectID", component: RD2AnswerPage },
  //RD-DR
  { path: "/rd-DR/:ProjectID", component: RDDRAnswerPage },

  //missing Data
  { path: "/missing-data", component: MissData },

  //Reports
  { path: "/Reports", component: Report },
  { path: "/rd1-reports", component: RD1Reports },
  { path: "/rd2-reports", component: RD2Reports },
  { path: "/rd-DRreports", component: RDDRReports },
  { path: "/rd-5", component: RD5Reports },
  { path: "/inspection-reports", component: InspectionReports },
  { path: "/inspection-details/:pStageID", component: InspectionDetails },
  { path: "/rd6-reports", component: RD6Reports },
  { path: "/rd3-reports", component: RD3Reports },
  { path: "/rd7-reports", component: RD7Reports },
  { path: "/rd7-review/:stageID", component: RD7Review },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/projects-list" /> },
]

const publicRoutes = [
  { path: "/logout", component: Login },
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }
