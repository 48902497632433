import React, { useEffect, useState } from "react"
import { Col, Container, Row, Table, UncontrolledTooltip } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useHistory } from "react-router-dom"
import axios from "axios"

import AddNewRole from "./AddNewRole"
import EditRole from "./EditRole"
import ViewRole from "./ViewRole"
import DeleteRole from "./DeleteRole"
import DisallowedDelete from "./DisallowedDelete"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import PaginationComponent from "common/PaginationComponent"

const UserRoles = () => {
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const history = useHistory()

  const [roles, setRoles] = useState([])
  const [editing, setEditing] = useState(false)

  // ***************** Pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** New Role Toggle********************************//
  const [newRoleModal, setNewRoleModal] = useState(false)
  const newRoleToggle = () => {
    setNewRoleModal(!newRoleModal)
  }

  //******************************** View Role Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewRoleModal, setViewRoleModal] = useState(false)
  const viewRoleToggle = () => {
    setViewRoleModal(!viewRoleModal)
  }

  //******************************** Edit Role Toggle********************************//
  const [editRoleModal, setEditRoleModal] = useState(false)
  const editRoleToggle = () => {
    setEditRoleModal(!editRoleModal)
  }

  //******************************** Delete Role Toggle********************************//
  const [deleteRoleModal, setDeleteRoleModal] = useState(false)
  const deleteRoleToggle = () => {
    setDeleteRoleModal(!deleteRoleModal)
  }

  useEffect(() => {
    axios
      .post("https://rd0.cpvarabia.com/api/Roles/View", {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Page: page,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        setTotalPages(res.data.TotalPages)
        
        let array = Object.values(res.data)
        array.splice(-4)
        // console.log("array", array)
        setRoles(array)
      })
      .catch(err => console.log(err))
  }, [editing,page])

  // console.log("userPermissions", userPermissions)

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Permissions" breadcrumbItem="User Roles" />

          {/* ********************** Filter Section ***************************** */}

          {/* ********************** Add New Role Section ***************************** */}
          {(userPermissions.R10.P === "2" ||
            userPermissions.R10.P === "3" ||
            userPermissions.R10.P === "4") && (
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" onClick={newRoleToggle}>
                Create New Role
              </button>
            </div>
          )}

          {/* ********************** Table Section ***************************** */}
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap align-middle text-center table-borderless">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Active Users</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    {/***********table body *****************/}
                    <tbody>
                      {roles.map((item, i) => (
                        <tr key={i}>
                          <td>{item.RoleName}</td>
                          <td>{item.ActiveUsersCount}</td>
                          <td>
                            <Link
                              to="#"
                              className="text-secondary mx-1"
                              onClick={() => {
                                setSelectedItem(item)
                                viewRoleToggle()
                              }}
                            >
                              <i
                                className="mdi mdi-home font-size-18"
                                id="hometooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="hometooltip"
                              >
                                View
                              </UncontrolledTooltip>
                            </Link>
                            {(userPermissions.R10.P === "3" ||
                              userPermissions.R10.P === "4") && (
                              <Link
                                style={{
                                  cursor:
                                    item.RoleID === "1" ? "not-allowed" : "",
                                }}
                                to="#"
                                className="text-primary mx-1"
                              >
                                <i
                                  onClick={e => {
                                    if (item.RoleID !== "1") {
                                      setSelectedItem(item)
                                      editRoleToggle()
                                    }
                                  }}
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="edittooltip"
                                >
                                  Edit
                                </UncontrolledTooltip>
                              </Link>
                            )}
                            {userPermissions.R10.P === "4" && (
                              <Link
                                style={{
                                  cursor:
                                    item.RoleID === "1" ? "not-allowed" : "",
                                }}
                                to="#"
                                className="text-danger mx-1"
                                onClick={() => {
                                  if (item.RoleID !== "1") {
                                    setSelectedItem(item)
                                    deleteRoleToggle()
                                  }
                                }}
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target="deletetooltip"
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {newRoleModal && (
            <AddNewRole
              newRoleToggle={newRoleToggle}
              setEditing={setEditing}
              editing={editing}
              setAuth={setAuth}
            />
          )}
          {viewRoleModal && (
            <ViewRole
              viewRoleToggle={viewRoleToggle}
              editRoleToggle={editRoleToggle}
              selectedItem={selectedItem}
            />
          )}

          {editRoleModal && (
            <EditRole
              editRoleToggle={editRoleToggle}
              selectedItem={selectedItem}
              setEditing={setEditing}
              editing={editing}
              setAuth={setAuth}
            />
          )}

          {deleteRoleModal && selectedItem.ActiveUsersCount === 0 && (
            <DeleteRole
              deleteRoleToggle={deleteRoleToggle}
              selectedItem={selectedItem}
              setEditing={setEditing}
              editing={editing}
              setAuth={setAuth}
            />
          )}

          {deleteRoleModal && selectedItem.ActiveUsersCount > 0 && (
            <DisallowedDelete deleteRoleToggle={deleteRoleToggle} />
          )}

          {/* ******************** Pagination *************************** */}
          {roles.length > 0 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserRoles
