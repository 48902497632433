import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"

//Import Component
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { Link, useHistory } from "react-router-dom"
import ReportsTable from "./ReportsTable"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { Tab, Tabs } from "react-bootstrap"
import TicketsTable from "./TicketsTable"
import InfoTable from "./InfoTable"
import FinancialTable from "./FinancialTable"

const ProjectDetails = props => {
  const ProjectID = props.match.params?.ProjectID

  const [projectData, setProjectData] = useState()

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/PDinfo.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          // console.log("res=================", res.data)
          let array = Object.values(res.data)
          array.splice(-1)
          // console.log("array =================", array)
          setProjectData(array[0])
        }
      })
      .catch(err => console.log("error------>", err))
  }, [])

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Projects" breadcrumbItem="Project Details" />

        {projectData && (
          <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Owner</th>
                <th>Payment Status</th>
                <th>Region</th>
                <th>Last Stage</th>
                <th>Next Stage</th>
                <th>Visits No</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{projectData.ProjectName}</td>
                <td>{projectData.OwnerName}</td>
                <td>
                  {" "}
                  <span
                    className={
                      projectData.Paid === "1"
                        ? "badge bg-success"
                        : projectData.Paid === "0"
                        ? "badge bg-danger"
                        : ""
                    }
                  >
                    {projectData.Paid === "1"
                      ? "Paid"
                      : projectData.Paid === "0"
                      ? "Not Paid"
                      : ""}
                  </span>
                </td>
                <td>{projectData.CityNameEN}</td>
                <td>{projectData.LastStage}</td>
                <td>{projectData.NextStage}</td>
                <td>{projectData.VCount}</td>
              </tr>
            </tbody>
          </Table>
        )}

        {/*************************Tabs section************************************/}

        <div className="mt-5">
          <Tabs className="mb-3" justify fill>
            <Tab eventKey="reports" title="Reports">
              <ReportsTable ProjectID={ProjectID} setAuth={setAuth} />
            </Tab>
            <Tab eventKey="tickets" title="Tickets">
              <TicketsTable ProjectID={ProjectID} setAuth={setAuth} />
            </Tab>
            <Tab eventKey="info" title="Info">
              <InfoTable ProjectID={ProjectID} setAuth={setAuth} />
            </Tab>
            <Tab eventKey="financial" title="Financial">
              <FinancialTable ProjectID={ProjectID} setAuth={setAuth} />
            </Tab>
            <Tab eventKey="contact" title="Contact">
              Contact
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  )
}

export default ProjectDetails
