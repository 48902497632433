import axios from "axios"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import ReportsHistory from "./ReportsHistory"

const ReportsTable = ({ ProjectID, setAuth }) => {
  const history = useHistory()

  // **************** Reports History Modal *********************
  const [selectedReport, setSelectedReport] = useState()

  const [reportHistoryModal, setReportHistoryModal] = useState(false)
  const reportHistoryToggle = () => {
    setReportHistoryModal(!reportHistoryModal)
  }

  const [stages, setStages] = useState([])
  // console.log("stages", stages)
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: ProjectID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/ProjectPStagesView", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.entries(res.data)
        array.splice(-1)
        setStages(array.map((item, i) => item[1]))
      })
      .catch(err => console.log("error------>", err))
  }, [])
  // console.log("stages =====>", stages)

  const clickedHandler = stage => {
    history.push({
      pathname: `/project-Answers/${stage.PStageID}`,
      state: { stage },
    })
  }

  return (
    <div className="table-responsive">
      <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
        <thead>
          <tr>
            <th scope="col" style={{ width: "20px" }}>
              #
            </th>
            <th scope="col">Report</th>
            <th scope="col">Status</th>
            <th scope="col">Date</th>
            <th scope="col">Responsible</th>
            <th scope="col">Version</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          {stages.map((stage, index) => (
            <tr key={index}>
              <td>
                <input
                  // value={item.Code}
                  type="checkbox"
                  className="form-check-input  p-1 border-primary"
                />
              </td>

              <td>{stage.PStageID}</td>

              <td>{stage.Status}</td>

              <td>{stage.StageDate}</td>

              <td>
                {stage.FirstName} {stage.LastName}
              </td>

              <td>
                {stage.Rev?.trim().length === 1 ? `0${stage.Rev}` : stage.Rev}
              </td>

              <td>
                {stage.StageName === "RD5" ? (
                  <a
                    href={`https://rd0.cpvarabia.com/uploads/reports/RD5_Reports/RD5Report.php?RD5T=${stage.Token}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary btn-sm dropdown-toggle"
                  >
                    View
                  </a>
                ) : (
                  <UncontrolledDropdown className="btn-group bg-primary">
                    <button
                      onClick={() => {
                        clickedHandler(stage)
                      }}
                      className="btn btn-primary btn-sm dropdown-toggle"
                    >
                      View
                    </button>

                    <DropdownToggle
                      tag="a"
                      to="#"
                      className="card-drop"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={() => {
                          setSelectedReport(stage)
                          reportHistoryToggle()
                        }}
                      >
                        History
                      </DropdownItem>
                      <DropdownItem>
                        <a
                          href={
                            stage.Category === "Design"
                              ? `https://inspection.cpvarabia.com/wordlib/vendor/phpoffice/phpword/samples/test.php?PStageID=${stage.PStageID}`
                              : ""
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="text-dark"
                        >
                          Export
                        </a>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {reportHistoryModal && (
        <ReportsHistory
          reportHistoryToggle={reportHistoryToggle}
          selectedReport={selectedReport}
          setAuth={setAuth}
        />
      )}
    </div>
  )
}

export default ReportsTable
