import React, { useEffect, useState } from "react"
import { Form, Row, Table, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"

function LogContent({ type, toggleViewApproved, setAPID }) {
  let [approved, setApproved] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)

  useEffect(() => {
    let formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Action: 2,
      Type: type,
      Page: page,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/AutoApproveList.php", formData)
      .then(res => {
        let array = []
        let data = Object.entries(res.data)
        console.log("res.data", res.data)
        // data.splice(-1)
        console.log("log data.splice(-1)", data);
        data.forEach(item =>item[1]["Name"]&& array.push(item[1]))
        setApproved(array)
        setTotalPages(res.data.TotalPages)

      })
      .catch(err => console.log(err))
  }, [page])
  return (
    <div  className="table-responsive">
      <Table hover striped className="  text-center   border-bottom">
        <thead>
          <tr>
            <th scope="col">Name</th>

            <th scope="col">from</th>
            <th scope="col">to</th>
            <th scope="col">state</th>
          </tr>
        </thead>
        {/***********table body *****************/}
        <tbody>
        
          {approved.map((item, key) => (
            <tr key={key}>
              <td>
                <Link
                  href="#"
                  onClick={() => {
                    return toggleViewApproved(), setAPID(item.APID)
                  }}
                >
                  {item.Name}
                </Link>
              </td>

              <td>{item.ACTIVEfrom}</td>

              <td>{item.ACTIVEto}</td>
              <td>
                <i
                  className={
                    item.Deleted === "1" ? "bx bx-bulb h5" : "bx bxs-bulb h5"
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* ********************** Pagination ********************************* */}
      {approved.length > 1 && (
        <Row>
          <PaginationComponent
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
          />
        </Row>
      )}
    </div>
  )
}

export default LogContent
