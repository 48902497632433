import React, { useState, useEffect } from "react"
import { Table } from "antd"
import "antd/dist/antd.css"
import axios from "axios"
import InspectionIcons from "./InspectionIcons"
import InspectionQuestionBuilder from "../InspectionBuilder/InspectionQuestionBuilder"
import { EditQuestionhelper } from "./EditQuestionhelper"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { useHistory } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"

const ViewInspectionSection = props => {
  let [questionscollect, setQuestioncollect] = useState([])
  let [questions, setQuestions] = useState([])
  const [QuestionData, setQuestionData] = useState([])
  const id = props.match.params.SectionID

  const sectionType =
    props.location.state?.sectionType || localStorage.getItem("sectionType")

  const sectionName = props.location.state?.sectionName

  const history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      SectionID: id,
    }
    axios
      .post(
        "https://rd0.cpvarabia.com/api/inspection/InspectionQuestionList.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        console.log("res.data", res.data)

        const questions = Object.values(res.data)
        setQuestions(questions)
        // console.log("questions", questions)
        console.log("questions", questions)
        questions.forEach(item => {
          if (typeof item === "object")
            questionscollect.push({
              QID: item.QID,
              Type: item.AnswerType,
              QuestionTitle: item.QTitle,
              Help: item.QHelp,
              Hint: item.QHint,
              questionActive: item.Active === "1" ? true : false,
              questionRD6: item.RD6 === "1" ? true : false,
            })
        }) //end of push
        setQuestionData(questionscollect)
        EditQuestionhelper(questionscollect, questions)
      }) //end of foreach
  }, [])

  //-----------createQuestion-------------------//
  const [qToggle, setqToggle] = useState(false)
  const questiontoggle = () => {
    setqToggle(!qToggle)
  }
  const addQuestion = () => {
    questiontoggle()
  }

  //************************ parent Question & Answer table*******************************//

  const PQuestionData = [
    { title: "Question", dataIndex: "QuestionTitle" },
    {
      title: "Action",
      render: record => {
        return (
          <InspectionIcons
            questions={questions}
            record={record}
            QuestionData={QuestionData}
            props={props}
            sectionType={sectionType}
            userPermissions={userPermissions}
            setAuth={setAuth}
          />
        )
      },
    },
  ]
  const parentQuestion = []

  QuestionData.map((item, i) => {
    parentQuestion.push({
      key: i,
      QuestionTitle: `${i + 1}- ${item.QuestionTitle}`,
      QID: item.QID,
    })
  })

  // ----------------------------------------------------------------//

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R2.P !== "1" &&
    userPermissions.R2.P !== "2" &&
    userPermissions.R2.P !== "3" &&
    userPermissions.R2.P !== "4" &&
    userPermissions.R2.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="col-10 ">
            <Breadcrumb
              title="Sections"
              breadcrumbItem={
                sectionName ? `${sectionName} Section` : "Questions List"
              }
            />

            <div className="mt-5 ms-2 ">
              {(userPermissions.R2.P === "2" ||
                userPermissions.R2.P === "3" ||
                userPermissions.R2.P === "4") && (
                <div style={{ cursor: "pointer" }}>
                  <button
                    onClick={() => {
                      addQuestion()
                    }}
                    className="btn-primary btn  mb-3 ms-auto p-1"
                  >
                    Add Question
                  </button>
                </div>
              )}

              {/******************************************************* table *********************************************************/}
              <div className="">
                <Table
                  rowClassName={"table-row-light rounded-5"}
                  columns={PQuestionData}
                  dataSource={parentQuestion}
                ></Table>
              </div>
            </div>
          </div>
        </div>

        {/*********** popup create  Question *****/}

        {qToggle && (
          <InspectionQuestionBuilder
            questiontoggle={questiontoggle}
            props={props}
            sectionType={sectionType}
            setAuth={setAuth}
          />
        )}
      </React.Fragment>
    )
  }
}

export default ViewInspectionSection
