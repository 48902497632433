import axios from "axios"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Input, Row } from "reactstrap"
import Select from "react-select"
import MultipleValueTextInput from "react-multivalue-text-input"
import { useHistory } from "react-router-dom"

const FilterRD7 = ({
  statusOptions,
  filteredValues,
  setFilteredValues,
  setAuth,
}) => {
  //   console.log("filteredValues ------------->", filteredValues)

  const history = useHistory()

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const convertDate = value => {
    let day = value.getDate() + ""
    day = day.length === 1 ? `0${day}` : day
    let month = value.getMonth() + 1 + ""
    month = month.length === 1 ? `0${month}` : month
    let year = value.getFullYear()
    return `${year}-${month}-${day}` || null
  }
  // console.log(
  //   "startDate",
  //   startDate && new Date(startDate).toISOString().split("T")[0]
  // )
  // console.log(
  //   "endDate",
  //   endDate && new Date(endDate).toISOString().split("T")[0]
  // )
  // console.log("dateRange", dateRange)

  const [users, setUsers] = useState([])
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/MenuRD7UsersList.php", {
        ...formData,
        Type: "Inspection",
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.values(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user.UserID,
          label: `${user.FirstName} ${user.LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })

    axios
      .post("https://rd0.cpvarabia.com/api/OpenAccess/TypeList", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        let array = Object.values(res.data)
        array.splice(-1)
        // console.log("array", array)
        setCategoryList(
          array.map(item => ({ value: item.BCID, label: item.Name }))
        )
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  //   console.log("statusOptions", statusOptions)
  const [RD7StatusOptions, setRD7StatusOptions] = useState([])

  useEffect(() => {
    const statusArray = statusOptions.map(status => ({
      value: status.RD7SID,
      label: status.Name,
    }))
    setRD7StatusOptions(statusArray)
  }, [statusOptions])

  return (
    <>
      <Row className="mb-3">
        {/* ******************** Report ID  *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    reportID: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    reportID: allItems,
                  })
                }
                type="number"
                name="reportID"
                placeholder="Report ID ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        {/* ******************** Category *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={categoryList}
                name="category"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.category}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, category: e })
                }}
                classNamePrefix="select"
                placeholder="Category ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Reference No *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 form-control border-dark py-1 d-flex flex-direction-colmun align-items-center overflow-hidden">
              <i className="bx bx-search-alt search-icon" />
              <MultipleValueTextInput
                onItemAdded={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                onItemDeleted={(item, allItems) =>
                  setFilteredValues({
                    ...filteredValues,
                    referenceNo: allItems,
                  })
                }
                type="number"
                name="referenceNo"
                placeholder="Reference No ..."
                className="border-0 mt-1"
              />
            </div>
          </div>
        </Col>

        {/* ******************** Date *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Date ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Inspector *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={users}
                name="inspector"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.inspector}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, inspector: e })
                }}
                classNamePrefix="select"
                placeholder="Inspector ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** RD7 Status *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 form-control border-dark py-0">
              <i className="bx bx-search-alt search-icon my-auto" />
              <Select
                isMulti
                options={RD7StatusOptions}
                name="RD7Status"
                className="react-select-container"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? "transparent"
                      : "transparent",
                    borderColor: state.isSelected
                      ? "transparent"
                      : "transparent",
                    borderWidth: "0px",
                  }),
                }}
                value={filteredValues.RD7Status}
                onChange={e => {
                  // console.log(e)
                  setFilteredValues({ ...filteredValues, RD7Status: e })
                }}
                classNamePrefix="select"
                placeholder="RD7 Status ..."
              />
            </div>
          </div>
        </Col>

        {/* ******************** Quotation Status *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 d-flex">
              <Input
                type="select"
                name="quotationStatus"
                className="form-control border-dark"
                onChange={e => {
                  //   console.log(e.target.value)
                  setFilteredValues({
                    ...filteredValues,
                    quotationStatus: e.target.value,
                  })
                }}
              >
                <option selected value="">
                  Quotation Status ...
                </option>
                <option value="1">Paid</option>
                <option value="0">Not Paid</option>
              </Input>
              <i className="bx bx-search-alt search-icon my-auto" />
            </div>
          </div>
        </Col>

        {/* ******************** Project Payment Status *************************** */}
        <Col sm={3}>
          <div className="search-box me-3 col-12 p-0">
            <div className="position-relative mb-3 d-flex">
              <Input
                type="select"
                name="TISPaymentStatus"
                className="form-control border-dark"
                onChange={e => {
                  //   console.log(e.target.value)
                  setFilteredValues({
                    ...filteredValues,
                    TISPaymentStatus: e.target.value,
                  })
                }}
              >
                <option selected value="">
                  TIS Payment Status ...
                </option>
                <option value="1">Paid</option>
                <option value="0">Not Paid</option>
              </Input>
              <i className="bx bx-search-alt search-icon my-auto" />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default FilterRD7
