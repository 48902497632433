import React, { useState } from "react"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import axios from "axios"
import { Formik } from "formik"
import Select from "react-select"
import { useHistory } from "react-router-dom"

const EditRole = ({
  editRoleToggle,
  selectedItem,
  editing,
  setEditing,
  setAuth,
}) => {
  // console.log("selectedItem", selectedItem)

  const history = useHistory()

  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    editRoleToggle()
  }
  const onClose = () => {
    editRoleToggle()
  }

  // *********************** Submit handler ******************************** //
  const submitHandler = values => {
    // console.log("submitted values", values)
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RoleName: values.roleName,
      RoleID: selectedItem.RoleID,
      PermissionsArray: [],
    }

    selectedItem.PermissionsArray.map((item, i) => {
      formData.PermissionsArray.push({
        PermissionID: item.PermissionID,
        ResourceID: i + 1,
        Permissions:
          values[`${item.ResourceName}`.replaceAll(" ", "")]?.value || "0",
        Global:
          values[`${item.ResourceName}`.replaceAll(" ", "") + "Global"] ||
          false,
      })
    })

    // console.log("formData", formData)
    axios
      .post("https://rd0.cpvarabia.com/api/EditRole", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log("res", res)
        setEditing(!editing)
      })
      .catch(error => {
        console.log(error)
      })
    onClose()
  }

  const prepareDataValues = value => {
    if (value === "0" || !value) return { value: "0", label: "NONE" }
    if (value === "1") return { value: value, label: "VIEW" }
    if (value === "2") return { value: value, label: "VIEW + ADD" }
    if (value === "3") return { value: value, label: "VIEW + ADD + EDIT" }
    if (value === "4")
      return { value: value, label: "VIEW + ADD + EDIT + DELETE" }
  }

  const initialValues = {
    roleName: selectedItem.RoleName,
  }
  selectedItem.PermissionsArray.map((item, i) => {
    initialValues[`${item.ResourceName}`.replaceAll(" ", "")] =
      prepareDataValues(item.Permissions)
    initialValues[`${item.ResourceName}`.replaceAll(" ", "") + "Global"] =
      item.Global === "1" ? true : false
  })

  const permissionOptions = [
    { value: "0", label: "NONE" },
    { value: "1", label: "VIEW" },
    { value: "2", label: "VIEW + ADD" },
    { value: "3", label: "VIEW + ADD + EDIT" },
    { value: "4", label: "VIEW + ADD + EDIT + DELETE" },
  ]

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>
        Edit Role
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-2 text-center">
          <Formik
            enableReinitialize
            validateOnBlur
            validateOnChange
            initialValues={initialValues}
            validate={values => {
              let errors = {}
              // console.log("values ========>", values)
              if (!values.roleName) errors.roleName = "required"

              // console.log("errors", errors)
              return errors
            }}
            onSubmit={submitHandler}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Row className="mt-1 ">
                  <Col sm={3} className="d-flex justify-content-center">
                    <Label className="my-auto">Role Name</Label>
                  </Col>
                  <Col>
                    <Input
                      type="string"
                      placeholder="role name"
                      name="roleName"
                      value={props.values["roleName"]}
                      onChange={props.handleChange}
                    />
                  </Col>
                </Row>
                {props.errors.roleName ? (
                  <p className="error d-flex justify-content-end px-2 pt-0">
                    {props.errors.roleName}
                  </p>
                ) : null}

                <Table borderless className="mt-4">
                  <thead>
                    <tr>
                      <th>Resources</th>
                      <th>
                        Permissions
                        <span className="text-primary mx-1">
                          <i
                            id="permissiontooltip"
                            className="mdi mdi-information-outline font-size-16 error mx-1"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="permissiontooltip"
                          >
                            select permissions
                          </UncontrolledTooltip>
                        </span>
                      </th>
                      <th>
                        Global
                        <span className="text-primary mx-1">
                          <i
                            id="globaltooltip"
                            className="mdi mdi-information-outline font-size-16 error mx-1"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="globaltooltip"
                          >
                            admin permissions
                          </UncontrolledTooltip>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedItem.PermissionsArray.map((item, i) => (
                      <tr key={i}>
                        <td className="align-middle">{item.ResourceName}</td>

                        <td>
                          <Select
                            options={permissionOptions}
                            name={`${item.ResourceName}`.replaceAll(" ", "")}
                            className="react-select-container"
                            value={
                              props.values[
                                `${item.ResourceName}`.replaceAll(" ", "")
                              ]
                            }
                            onChange={e => {
                              props.setFieldValue(
                                `${item.ResourceName}`.replaceAll(" ", ""),
                                e
                              )
                            }}
                            classNamePrefix="select"
                            placeholder={
                              `${item.ResourceName}`.replaceAll(" ", "") +
                              "permissions ..."
                            }
                          />
                        </td>
                        <td className="align-middle">
                          <Input
                            type="checkbox"
                            name={
                              `${item.ResourceName}`.replaceAll(" ", "") +
                              "Global"
                            }
                            defaultChecked={
                              props.values[
                                `${item.ResourceName}`.replaceAll(" ", "") +
                                  "Global"
                              ]
                            }
                            value={
                              props.values[
                                `${item.ResourceName}`.replaceAll(" ", "") +
                                  "Global"
                              ]
                            }
                            onChange={props.handleChange}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                    className="bg-primary"
                  >
                    close
                  </Button>
                  <Button type="submit" className="bg-primary">
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default EditRole
