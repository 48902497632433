import React, { useState, useEffect } from "react"
import ReactDragListView from "react-drag-listview/lib/index.js"
import { useHistory } from "react-router-dom"
import {
  Button,
  Table,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  Form,
  Label,
  ModalFooter,
} from "reactstrap"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"

//meta title
document.title = "Stages | DomApp - React Admin & Dashboard Template"

const Stages = () => {
  let history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const [data, setData] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    if (
      userPermissions.R1.P !== "1" &&
      userPermissions.R1.P !== "2" &&
      userPermissions.R1.P !== "3" &&
      userPermissions.R1.P !== "4" &&
      userPermissions.R1.G !== "1"
    ) {
      formData.Category = "Inspection"
    } else if (
      userPermissions.R2.P !== "1" &&
      userPermissions.R2.P !== "2" &&
      userPermissions.R2.P !== "3" &&
      userPermissions.R2.P !== "4" &&
      userPermissions.R2.G !== "1"
    ) {
      formData.Category = "Design"
    }

    axios
      .post("https://rd0.cpvarabia.com/api/Stages/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        if (res.data.error === false) {
          let array = Object.entries(res.data)
          array.splice(-1)
          setData(array)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])
  // ----drag & drop code----//
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setData(modifiedData)
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }

  //*pop up*//
  //*****Add Stage****//
  const [modal, setmodal] = useState(false)
  const toggle = () => {
    setmodal(!modal)
  }

  const Addstage = () => {
    toggle()
  }
  const handleAddStage = e => {
    e.preventDefault()
    let stage = { StageName: e.target[0].value, Type: e.target[1].value }
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ...stage,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/AddStage", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        window.location.reload(false)
      })
      .catch(err => console.log(err))
    setData([
      ...data,
      // { StageName: e.target[0].value, Type: e.target[1].value },
    ])
    toggle()
  }
  // ********view stage*****//
  function viewstage(item) {
    let path = `view-stage/${item.StageID}`
    // let path = `view-stage/${item.Category}/${item.StageID}`
    localStorage.setItem("stageCategory", item.Category)
    history.push(path, { category: item.Category, stageName: item.StageName })
  }
  //*******Edit**********/
  const [emodal, setEmodal] = useState(false)
  const [newName, setNewName] = useState("")

  const etoggle = () => {
    setEmodal(!emodal)
  }

  const [editeItem, setEdititem] = useState([])
  const edit = item => {
    setEdititem(item)
    etoggle()
  }
  const editfunc = e => {
    e.preventDefault()
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageName: newName,
      StageID: editeItem.StageID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/EditStage", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        window.location.reload(false)
      })
      .catch(err => {
        console.log(err)
      })
  }
  //*******Delete**********/
  const [Dmodal, setDmodal] = useState(false)
  const [item, setItem] = useState()

  const Dtoggle = () => {
    setDmodal(!Dmodal)
  }

  const Delete = item => {
    setItem(item)
    Dtoggle()
  }
  const deletecell = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: item,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/DeleteStage", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        window.location.reload(false)
      })
  }

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R1.P !== "1" &&
    userPermissions.R1.P !== "2" &&
    userPermissions.R1.P !== "3" &&
    userPermissions.R1.P !== "4" &&
    userPermissions.R1.G !== "1" &&
    userPermissions.R2.P !== "1" &&
    userPermissions.R2.P !== "2" &&
    userPermissions.R2.P !== "3" &&
    userPermissions.R2.P !== "4" &&
    userPermissions.R2.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Stages" breadcrumbItem="Stages List" />

            <Row>
              <Col md={12}>
                {(userPermissions.R1.P === "2" ||
                  userPermissions.R1.P === "3" ||
                  userPermissions.R1.P === "4" ||
                  userPermissions.R2.P === "2" ||
                  userPermissions.R2.P === "3" ||
                  userPermissions.R2.P === "4") && (
                  <button
                    onClick={() => {
                      Addstage()
                    }}
                    className="btn-primary btn col-2 mb-3"
                  >
                    Add Stage
                  </button>
                )}
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <ReactDragListView {...dragProps}>
                        {/* ***** * table ******* */}
                        <Table className="table mb-0 text-center">
                          <thead>
                            <tr>
                              <th>order</th>
                              <th>Stage Name</th>
                              <th>Type</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(data || []).map((item, index) => (
                              <tr className="shadow" key={index}>
                                <th scope="row" className="drag-pointer">
                                  {index + 1}
                                </th>
                                <td>{item[1].StageName}</td>
                                <td>{item[1].Category}</td>
                                <td>
                                  <div className="d-flex justify-content-center gap-3">
                                    <div
                                      // to={`/view-stage/${item[1].StageID}`}
                                      // state={{ category: item[1].Category }}
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        viewstage(item[1])
                                      }}
                                    >
                                      <i
                                        className="mdi mdi-eye font-size-18"
                                        id="viewtooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="viewtooltip"
                                      >
                                        view
                                      </UncontrolledTooltip>
                                    </div>
                                    {((item[1].Category === "Design" &&
                                      (userPermissions.R1.P === "3" ||
                                        userPermissions.R1.P === "4")) ||
                                      (item[1].Category === "Inspection" &&
                                        (userPermissions.R2.P === "3" ||
                                          userPermissions.R2.P === "4"))) && (
                                      <Link to="#" className="text-success">
                                        <i
                                          onClick={() => {
                                            edit(item[1])
                                          }}
                                          className="mdi mdi-pencil font-size-18"
                                          id="edittooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="edittooltip"
                                        >
                                          Edit
                                        </UncontrolledTooltip>
                                      </Link>
                                    )}

                                    {((item[1].Category === "Design" &&
                                      userPermissions.R1.P === "4") ||
                                      (item[1].Category === "Inspection" &&
                                        userPermissions.R2.P === "4")) && (
                                      <Link
                                        to="#"
                                        className="text-danger"
                                        onClick={() => {
                                          Delete(item[1].StageID)
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-delete font-size-18"
                                          id="deletetooltip"
                                        />
                                        <UncontrolledTooltip
                                          placement="top"
                                          target="deletetooltip"
                                        >
                                          Delete
                                        </UncontrolledTooltip>
                                      </Link>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </ReactDragListView>
                    </div>
                  </CardBody>
                  {/****** pop up *****/}
                  {/****************** pop for Add *******************/}
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader>Add Stage</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          handleAddStage(e)
                        }}
                      >
                        <div className="d-flex ">
                          <Label htmlFor="stageName" className="col-3">
                            Stage Name
                          </Label>
                          <Input
                            type="text"
                            id="StageName"
                            className="form-control"
                          />
                        </div>

                        <Col className=" mt-3  d-flex">
                          <Label className="p-1 col-3">Type</Label>
                          <Input
                            className="p-1"
                            id="Type"
                            name="status"
                            type="select"
                          >
                            <option>--</option>
                            {(userPermissions.R2.P === "2" ||
                              userPermissions.R2.P === "3" ||
                              userPermissions.R2.P === "4") && (
                              <option>Inspection</option>
                            )}
                            {(userPermissions.R1.P === "2" ||
                              userPermissions.R1.P === "3" ||
                              userPermissions.R1.P === "4") && (
                              <option>Design</option>
                            )}
                          </Input>
                        </Col>
                        <Button to="view-data" color="primary" className="my-4">
                          Add
                        </Button>
                      </Form>
                    </ModalBody>
                  </Modal>
                  {/****************** pop for Edit *****************/}
                  <Modal isOpen={emodal} toggle={etoggle}>
                    <ModalHeader>Edit Stage</ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          handleAddStage(e)
                        }}
                      >
                        <label htmlFor="stageName">Stage Name</label>
                        {
                          <>
                            <Input
                              type="text"
                              id="stageName"
                              className="form-control"
                              defaultValue={editeItem.StageName}
                              onBlur={e => {
                                setNewName(e.target.value)
                              }}
                            />
                            <Button
                              to="view-data"
                              color="primary"
                              className="my-4"
                              type="submit"
                              onClick={e => {
                                editfunc(e)
                              }}
                            >
                              Edit
                            </Button>
                          </>
                        }
                      </Form>
                    </ModalBody>
                  </Modal>
                  {/********* delete pop up ******/}
                  <Modal isOpen={Dmodal} toggle={Dtoggle}>
                    <ModalBody>
                      <div className="modal-content">
                        <ModalBody className="px-4 py-5 text-center">
                          <button
                            type="button"
                            className="btn-close position-absolute end-0 top-0 m-3"
                          ></button>
                          <div className="avatar-sm mb-4 mx-auto">
                            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                              <i className="mdi mdi-trash-can-outline"></i>
                            </div>
                          </div>
                          <p className="text-muted font-size-16 mb-4">
                            Are you sure you want to Delete this stage.
                          </p>

                          <div className="hstack gap-2 justify-content-center mb-0">
                            <button
                              onClick={() => deletecell()}
                              type="button"
                              className="btn btn-danger"
                            >
                              Delete Now
                            </button>
                            <button
                              onClick={() => {
                                Dtoggle()
                              }}
                              type="button"
                              className="btn btn-secondary"
                            >
                              Close
                            </button>
                          </div>
                        </ModalBody>
                      </div>
                    </ModalBody>
                  </Modal>
                  {/* end pop */}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Stages
